import "../CustomMenu.css";
import { Link } from 'react-router-dom'

export default function CustomAVAXMenu() {
  return (
    <div className="RealCustomMenu">
      <div className="CustomMenuHeader">SELECT</div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomavax'>KUSTOMIZE</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomavaxbackground'>CHANGE BACKGROUND COLOR</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomavaxfinality'>FINALITY</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomMenu'>BACK</Link>
      </div>
    </div>
  );
}
