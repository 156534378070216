const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "dev-poke-infra-s3-uploads4f6eb0fd-4voqvpz1qr83",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://sa0yeomuo1.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_CvhJNS187",
    APP_CLIENT_ID: "73srsq6f4bqeiejlmg2u8dvvvb",
    IDENTITY_POOL_ID: "us-east-1:8e2a0cf6-43e2-4268-a125-b79ccfbef3dd",
  },
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "prod-poke-infra-s3-uploads4f6eb0fd-2sitv49eue4a",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ysgzldn7d5.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_zXoUsv4k7",
    APP_CLIENT_ID: "4k7glffg7bpam8qqlibnr1adab",
    IDENTITY_POOL_ID: "us-east-1:dae89225-40cb-49ad-892c-387c4a28b604",
  },
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;
