import React from "react";
import { useAppContext } from "../../libs/contextLib";
import "./CustomIndividualETH.css"
import metakays from '../../metakays';


export default function CustomIndividualETHBackground () {
  const { setBgColor, set_three_colors, setColor1, setColor2, setPeices, setKustomModal, customChooser, setTokenID, tokenID} = useAppContext();


let SetDefaultColor = async (value) => {
  setBgColor(value)
  // setKustomModal(false)
}





    let changeTokenID = async (value) => {

      let features = await metakays.methods.features(value).call()
/* global BigInt */
      let data1 = BigInt(String(features.data1)).toString(2)
      let data2 = BigInt(String(features.data2)).toString(2)
      let total_data = data1.padStart(256, '0') + data2.padStart(256, '0')
      setPeices(total_data.split(''))


      let getFeatures = await metakays.methods.getFeatures(value).call()
      let featureColors1  = getFeatures[2][0]
      let featureColors2 = getFeatures[2][1]
      let featureColors3 = getFeatures[2][2]
      let featureColors4 = getFeatures[2][3]


      let FC1 = BigInt(String(featureColors1)).toString(2)
      let FC2 = BigInt(String(featureColors2)).toString(2)
      let FC3 = BigInt(String(featureColors3)).toString(2)
      let FC4 = BigInt(String(featureColors4)).toString(2)
      let total_FC_data1 = FC1.padStart(256, '0') + FC2.padStart(256, '0')
      let total_FC_data2 = FC3.padStart(256, '0') + FC4.padStart(256, '0')

      setColor1(total_FC_data1.split(''))
      setColor2(total_FC_data2.split(''))


      let featureColorsSelectors1 = getFeatures[3][0]
      let featureColorsSelectors2 = getFeatures[3][1]
      let featureColorsSelectors3 = getFeatures[3][2]
      set_three_colors([featureColorsSelectors1, featureColorsSelectors2, featureColorsSelectors3 ])

      let backgroundColor = await metakays.methods.svgBackgroundColorSelector(value).call()
      setBgColor(backgroundColor)
      setTokenID(parseInt(value))


      setKustomModal(false)
      }






  const backOut = () => {
    setKustomModal(false)
  }

  return (
    <div>


      { (customChooser === 0) &&
      <div className="customIndividuals">
      <div>LOAD</div>
      <div><input className="digiTextBox" type="text" placeholder="ENTER TOKEN ID." onChange={e => setTokenID(e.target.value)} /></div>
      <div className="itemDropYourMETAKAYS" onClick={() => changeTokenID(parseInt(tokenID))}>ENTER</div>
      <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
      </div>
        }

      { (customChooser === 1) &&
          <div className="customIndividuals">
            <div>SELECT BACKGROUND COLOR</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/0.png" alt=''onClick={() => SetDefaultColor(0)}></img>
            <img className='customImages'src="/images/metakays_parts/base/1.png" alt=''onClick={() => SetDefaultColor(1)}></img>
            <img className='customImages'src="/images/metakays_parts/base/2.png" alt=''onClick={() => SetDefaultColor(2)}></img>
            <img className='customImages'src="/images/metakays_parts/base/3.png" alt=''onClick={() => SetDefaultColor(3)}></img>
            <img className='customImages'src="/images/metakays_parts/base/4.png" alt=''onClick={() => SetDefaultColor(4)}></img>
            <img className='customImages'src="/images/metakays_parts/base/5.png" alt=''onClick={() => SetDefaultColor(5)}></img>
            <img className='customImages'src="/images/metakays_parts/base/6.png" alt=''onClick={() => SetDefaultColor(6)}></img>
            <img className='customImages'src="/images/metakays_parts/base/7.png" alt=''onClick={() => SetDefaultColor(7)}></img>
            <img className='customImages'src="/images/metakays_parts/base/8.png" alt=''onClick={() => SetDefaultColor(8)}></img>
            <img className='customImages'src="/images/metakays_parts/base/9.png" alt=''onClick={() => SetDefaultColor(9)}></img>
            <img className='customImages'src="/images/metakays_parts/base/10.png" alt=''onClick={() => SetDefaultColor(10)}></img>
            <img className='customImages'src="/images/metakays_parts/base/11.png" alt=''onClick={() => SetDefaultColor(11)}></img>
            <img className='customImages'src="/images/metakays_parts/base/12.png" alt=''onClick={() => SetDefaultColor(12)}></img>
            <img className='customImages'src="/images/metakays_parts/base/13.png" alt=''onClick={() => SetDefaultColor(13)}></img>
            <img className='customImages'src="/images/metakays_parts/base/14.png" alt=''onClick={() => SetDefaultColor(14)}></img>
            <img className='customImages'src="/images/metakays_parts/base/15.png" alt=''onClick={() => SetDefaultColor(15)}></img>
            <img className='customImages'src="/images/metakays_parts/base/16.png" alt=''onClick={() => SetDefaultColor(16)}></img>
            <img className='customImages'src="/images/metakays_parts/base/17.png" alt=''onClick={() => SetDefaultColor(17)}></img>
            <img className='customImages'src="/images/metakays_parts/base/18.png" alt=''onClick={() => SetDefaultColor(18)}></img>
            <img className='customImages'src="/images/metakays_parts/base/19.png" alt=''onClick={() => SetDefaultColor(19)}></img>
            <img className='customImages'src="/images/metakays_parts/base/20.png" alt=''onClick={() => SetDefaultColor(20)}></img>
            <img className='customImages'src="/images/metakays_parts/base/21.png" alt=''onClick={() => SetDefaultColor(21)}></img>
            <img className='customImages'src="/images/metakays_parts/base/22.png" alt=''onClick={() => SetDefaultColor(22)}></img>
            <img className='customImages'src="/images/metakays_parts/base/23.png" alt=''onClick={() => SetDefaultColor(23)}></img>
            <img className='customImages'src="/images/metakays_parts/base/24.png" alt=''onClick={() => SetDefaultColor(24)}></img>
            <img className='customImages'src="/images/metakays_parts/base/25.png" alt=''onClick={() => SetDefaultColor(25)}></img>
            <img className='customImages'src="/images/metakays_parts/base/26.png" alt=''onClick={() => SetDefaultColor(26)}></img>
            <img className='customImages'src="/images/metakays_parts/base/27.png" alt=''onClick={() => SetDefaultColor(27)}></img>
            <img className='customImages'src="/images/metakays_parts/base/28.png" alt=''onClick={() => SetDefaultColor(28)}></img>
            <img className='customImages'src="/images/metakays_parts/base/29.png" alt=''onClick={() => SetDefaultColor(29)}></img>
            <img className='customImages'src="/images/metakays_parts/base/30.png" alt=''onClick={() => SetDefaultColor(30)}></img>
            <img className='customImages'src="/images/metakays_parts/base/31.png" alt=''onClick={() => SetDefaultColor(31)}></img>
            <img className='customImages'src="/images/metakays_parts/base/32.png" alt=''onClick={() => SetDefaultColor(32)}></img>
            <img className='customImages'src="/images/metakays_parts/base/33.png" alt=''onClick={() => SetDefaultColor(33)}></img>
            <img className='customImages'src="/images/metakays_parts/base/34.png" alt=''onClick={() => SetDefaultColor(34)}></img>
            <img className='customImages'src="/images/metakays_parts/base/35.png" alt=''onClick={() => SetDefaultColor(35)}></img>
            <img className='customImages'src="/images/metakays_parts/base/36.png" alt=''onClick={() => SetDefaultColor(36)}></img>
            <img className='customImages'src="/images/metakays_parts/base/37.png" alt=''onClick={() => SetDefaultColor(37)}></img>
            <img className='customImages'src="/images/metakays_parts/base/38.png" alt=''onClick={() => SetDefaultColor(38)}></img>
            <img className='customImages'src="/images/metakays_parts/base/39.png" alt=''onClick={() => SetDefaultColor(39)}></img>
            <img className='customImages'src="/images/metakays_parts/base/40.png" alt=''onClick={() => SetDefaultColor(40)}></img>
            <img className='customImages'src="/images/metakays_parts/base/41.png" alt=''onClick={() => SetDefaultColor(41)}></img>
            <img className='customImages'src="/images/metakays_parts/base/42.png" alt=''onClick={() => SetDefaultColor(42)}></img>
            <img className='customImages'src="/images/metakays_parts/base/43.png" alt=''onClick={() => SetDefaultColor(43)}></img>
            <img className='customImages'src="/images/metakays_parts/base/44.png" alt=''onClick={() => SetDefaultColor(44)}></img>
            <img className='customImages'src="/images/metakays_parts/base/45.png" alt=''onClick={() => SetDefaultColor(45)}></img>
            <img className='customImages'src="/images/metakays_parts/base/46.png" alt=''onClick={() => SetDefaultColor(46)}></img>
            <img className='customImages'src="/images/metakays_parts/base/47.png" alt=''onClick={() => SetDefaultColor(47)}></img>
            <img className='customImages'src="/images/metakays_parts/base/48.png" alt=''onClick={() => SetDefaultColor(48)}></img>
            <img className='customImages'src="/images/metakays_parts/base/49.png" alt=''onClick={() => SetDefaultColor(49)}></img>
            <img className='customImages'src="/images/metakays_parts/base/50.png" alt=''onClick={() => SetDefaultColor(50)}></img>
            <img className='customImages'src="/images/metakays_parts/base/51.png" alt=''onClick={() => SetDefaultColor(51)}></img>
            <img className='customImages'src="/images/metakays_parts/base/52.png" alt=''onClick={() => SetDefaultColor(52)}></img>
            <img className='customImages'src="/images/metakays_parts/base/53.png" alt=''onClick={() => SetDefaultColor(53)}></img>
            <img className='customImages'src="/images/metakays_parts/base/54.png" alt=''onClick={() => SetDefaultColor(54)}></img>
            <img className='customImages'src="/images/metakays_parts/base/55.png" alt=''onClick={() => SetDefaultColor(55)}></img>
            <img className='customImages'src="/images/metakays_parts/base/56.png" alt=''onClick={() => SetDefaultColor(56)}></img>
            <img className='customImages'src="/images/metakays_parts/base/57.png" alt=''onClick={() => SetDefaultColor(57)}></img>
            <img className='customImages'src="/images/metakays_parts/base/58.png" alt=''onClick={() => SetDefaultColor(58)}></img>
            <img className='customImages'src="/images/metakays_parts/base/59.png" alt=''onClick={() => SetDefaultColor(59)}></img>
            <img className='customImages'src="/images/metakays_parts/base/60.png" alt=''onClick={() => SetDefaultColor(60)}></img>
            <img className='customImages'src="/images/metakays_parts/base/61.png" alt=''onClick={() => SetDefaultColor(61)}></img>
            <img className='customImages'src="/images/metakays_parts/base/62.png" alt=''onClick={() => SetDefaultColor(62)}></img>
            <img className='customImages'src="/images/metakays_parts/base/63.png" alt=''onClick={() => SetDefaultColor(63)}></img>
            <img className='customImages'src="/images/metakays_parts/base/64.png" alt=''onClick={() => SetDefaultColor(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/65.png" alt=''onClick={() => SetDefaultColor(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/66.png" alt=''onClick={() => SetDefaultColor(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/67.png" alt=''onClick={() => SetDefaultColor(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/68.png" alt=''onClick={() => SetDefaultColor(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/69.png" alt=''onClick={() => SetDefaultColor(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/70.png" alt=''onClick={() => SetDefaultColor(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/71.png" alt=''onClick={() => SetDefaultColor(71)}></img>
            <img className='customImages'src="/images/metakays_parts/base/72.png" alt=''onClick={() => SetDefaultColor(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/73.png" alt=''onClick={() => SetDefaultColor(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/74.png" alt=''onClick={() => SetDefaultColor(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/75.png" alt=''onClick={() => SetDefaultColor(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/76.png" alt=''onClick={() => SetDefaultColor(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/77.png" alt=''onClick={() => SetDefaultColor(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/78.png" alt=''onClick={() => SetDefaultColor(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/79.png" alt=''onClick={() => SetDefaultColor(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/80.png" alt=''onClick={() => SetDefaultColor(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/81.png" alt=''onClick={() => SetDefaultColor(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/82.png" alt=''onClick={() => SetDefaultColor(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/83.png" alt=''onClick={() => SetDefaultColor(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/84.png" alt=''onClick={() => SetDefaultColor(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/85.png" alt=''onClick={() => SetDefaultColor(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/86.png" alt=''onClick={() => SetDefaultColor(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/87.png" alt=''onClick={() => SetDefaultColor(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/88.png" alt=''onClick={() => SetDefaultColor(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/89.png" alt=''onClick={() => SetDefaultColor(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/90.png" alt=''onClick={() => SetDefaultColor(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/91.png" alt=''onClick={() => SetDefaultColor(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/92.png" alt=''onClick={() => SetDefaultColor(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/93.png" alt=''onClick={() => SetDefaultColor(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/94.png" alt=''onClick={() => SetDefaultColor(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/95.png" alt=''onClick={() => SetDefaultColor(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/96.png" alt=''onClick={() => SetDefaultColor(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/97.png" alt=''onClick={() => SetDefaultColor(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/98.png" alt=''onClick={() => SetDefaultColor(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/99.png" alt=''onClick={() => SetDefaultColor(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/100.png" alt=''onClick={() => SetDefaultColor(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/101.png" alt=''onClick={() => SetDefaultColor(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/102.png" alt=''onClick={() => SetDefaultColor(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/103.png" alt=''onClick={() => SetDefaultColor(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/104.png" alt=''onClick={() => SetDefaultColor(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/105.png" alt=''onClick={() => SetDefaultColor(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/106.png" alt=''onClick={() => SetDefaultColor(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/107.png" alt=''onClick={() => SetDefaultColor(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/108.png" alt=''onClick={() => SetDefaultColor(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/109.png" alt=''onClick={() => SetDefaultColor(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/110.png" alt=''onClick={() => SetDefaultColor(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/111.png" alt=''onClick={() => SetDefaultColor(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/112.png" alt=''onClick={() => SetDefaultColor(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/113.png" alt=''onClick={() => SetDefaultColor(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/114.png" alt=''onClick={() => SetDefaultColor(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/115.png" alt=''onClick={() => SetDefaultColor(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/116.png" alt=''onClick={() => SetDefaultColor(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/117.png" alt=''onClick={() => SetDefaultColor(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/118.png" alt=''onClick={() => SetDefaultColor(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/119.png" alt=''onClick={() => SetDefaultColor(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/120.png" alt=''onClick={() => SetDefaultColor(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/121.png" alt=''onClick={() => SetDefaultColor(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/122.png" alt=''onClick={() => SetDefaultColor(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/123.png" alt=''onClick={() => SetDefaultColor(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/124.png" alt=''onClick={() => SetDefaultColor(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/125.png" alt=''onClick={() => SetDefaultColor(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/126.png" alt=''onClick={() => SetDefaultColor(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/127.png" alt=''onClick={() => SetDefaultColor(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/128.png" alt=''onClick={() => SetDefaultColor(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/129.png" alt=''onClick={() => SetDefaultColor(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => SetDefaultColor(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/131.png" alt=''onClick={() => SetDefaultColor(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/132.png" alt=''onClick={() => SetDefaultColor(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/133.png" alt=''onClick={() => SetDefaultColor(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/134.png" alt=''onClick={() => SetDefaultColor(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/135.png" alt=''onClick={() => SetDefaultColor(135)}></img>
            <img className='customImages'src="/images/metakays_parts/base/136.png" alt=''onClick={() => SetDefaultColor(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/137.png" alt=''onClick={() => SetDefaultColor(137)}></img>
        </div>
        }


    </div>

  );
}