import React, {useEffect, useState} from "react";
import "./CustomAVAX.css";
import parse from 'html-react-parser';
import CustomIndividualAVAX from "./CustomIndividualAVAX";
import { useAppContext } from "../../libs/contextLib";
import web3 from '../../web3';
import metakays from '../../metakays';
import { Link } from 'react-router-dom'
import AvaxApp from './AvaxApp';




export default function CustomAVAX() {

  const {bgColor, four_colors, color1, color2, peices, showKustomModal, setMyMETAKAYS, setUserAccount} = useAppContext();

  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");
  const [msg3, setMsg3] = useState("");
  const [msg4, setMsg4] = useState("");
  const [msg5, setMsg5] = useState("");
  const [msg6, setMsg6] = useState("");



  useEffect(() => {
    const getMETAKAYS = async () => {

      try {

      const accounts = await web3.eth.getAccounts();
      setUserAccount(accounts[0])
      let supplyOfOwner = await metakays.methods.balanceOf(accounts[0]).call()
      for (let i = 0; i<supplyOfOwner; i++) {
        let tempID = await metakays.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        let tempStruct = await metakays.methods.features(tempID).call()
        // let tokenURItest = await metakays.methods.tokenURI(2).call()


        setMyMETAKAYS(myMETAKAYS => ({...myMETAKAYS,
      [tempID]: Object.values(tempStruct).slice(0,13)}))

        }

      } catch (error) {
        console.log(error)

      }
      }

    getMETAKAYS();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var svgData = {}
  svgData[0] = '<rect width="50" height="50" x="0" y="0';
svgData[1] = '<rect width="50" height="50" x="50" y="0';
svgData[2] = '<rect width="50" height="50" x="100" y="0';
svgData[3] = '<rect width="50" height="50" x="150" y="0';
svgData[4] = '<rect width="50" height="50" x="200" y="0';
svgData[5] = '<rect width="50" height="50" x="250" y="0';
svgData[6] = '<rect width="50" height="50" x="300" y="0';
svgData[7] = '<rect width="50" height="50" x="350" y="0';
svgData[8] = '<rect width="50" height="50" x="400" y="0';
svgData[9] = '<rect width="50" height="50" x="450" y="0';
svgData[10] = '<rect width="50" height="50" x="500" y="0';
svgData[11] = '<rect width="50" height="50" x="550" y="0';
svgData[12] = '<rect width="50" height="50" x="600" y="0';
svgData[13] = '<rect width="50" height="50" x="650" y="0';
svgData[14] = '<rect width="50" height="50" x="700" y="0';
svgData[15] = '<rect width="50" height="50" x="750" y="0';
svgData[16] = '<rect width="50" height="50" x="800" y="0';
svgData[17] = '<rect width="50" height="50" x="850" y="0';
svgData[18] = '<rect width="50" height="50" x="900" y="0';
svgData[19] = '<rect width="50" height="50" x="950" y="0';
svgData[20] = '<rect width="50" height="50" x="1000" y="0';
svgData[21] = '<rect width="50" height="50" x="1050" y="0';
svgData[22] = '<rect width="50" height="50" x="1100" y="0';
svgData[23] = '<rect width="50" height="50" x="1150" y="0';
svgData[24] = '<rect width="50" height="50" x="1200" y="0';
svgData[25] = '<rect width="50" height="50" x="1250" y="0';
svgData[26] = '<rect width="50" height="50" x="1300" y="0';
svgData[27] = '<rect width="50" height="50" x="1350" y="0';
svgData[28] = '<rect width="50" height="50" x="1400" y="0';
svgData[29] = '<rect width="50" height="50" x="1450" y="0';
svgData[30] = '<rect width="50" height="50" x="1500" y="0';
svgData[31] = '<rect width="50" height="50" x="1550" y="0';
svgData[32] = '<rect width="50" height="50" x="0" y="50';
svgData[33] = '<rect width="50" height="50" x="50" y="50';
svgData[34] = '<rect width="50" height="50" x="100" y="50';
svgData[35] = '<rect width="50" height="50" x="150" y="50';
svgData[36] = '<rect width="50" height="50" x="200" y="50';
svgData[37] = '<rect width="50" height="50" x="250" y="50';
svgData[38] = '<rect width="50" height="50" x="300" y="50';
svgData[39] = '<rect width="50" height="50" x="350" y="50';
svgData[40] = '<rect width="50" height="50" x="400" y="50';
svgData[41] = '<rect width="50" height="50" x="450" y="50';
svgData[42] = '<rect width="50" height="50" x="500" y="50';
svgData[43] = '<rect width="50" height="50" x="550" y="50';
svgData[44] = '<rect width="50" height="50" x="600" y="50';
svgData[45] = '<rect width="50" height="50" x="650" y="50';
svgData[46] = '<rect width="50" height="50" x="700" y="50';
svgData[47] = '<rect width="50" height="50" x="750" y="50';
svgData[48] = '<rect width="50" height="50" x="800" y="50';
svgData[49] = '<rect width="50" height="50" x="850" y="50';
svgData[50] = '<rect width="50" height="50" x="900" y="50';
svgData[51] = '<rect width="50" height="50" x="950" y="50';
svgData[52] = '<rect width="50" height="50" x="1000" y="50';
svgData[53] = '<rect width="50" height="50" x="1050" y="50';
svgData[54] = '<rect width="50" height="50" x="1100" y="50';
svgData[55] = '<rect width="50" height="50" x="1150" y="50';
svgData[56] = '<rect width="50" height="50" x="1200" y="50';
svgData[57] = '<rect width="50" height="50" x="1250" y="50';
svgData[58] = '<rect width="50" height="50" x="1300" y="50';
svgData[59] = '<rect width="50" height="50" x="1350" y="50';
svgData[60] = '<rect width="50" height="50" x="1400" y="50';
svgData[61] = '<rect width="50" height="50" x="1450" y="50';
svgData[62] = '<rect width="50" height="50" x="1500" y="50';
svgData[63] = '<rect width="50" height="50" x="1550" y="50';
svgData[64] = '<rect width="50" height="50" x="0" y="100';
svgData[65] = '<rect width="50" height="50" x="50" y="100';
svgData[66] = '<rect width="50" height="50" x="100" y="100';
svgData[67] = '<rect width="50" height="50" x="150" y="100';
svgData[68] = '<rect width="50" height="50" x="200" y="100';
svgData[69] = '<rect width="50" height="50" x="250" y="100';
svgData[70] = '<rect width="50" height="50" x="300" y="100';
svgData[71] = '<rect width="50" height="50" x="350" y="100';
svgData[72] = '<rect width="50" height="50" x="400" y="100';
svgData[73] = '<rect width="50" height="50" x="450" y="100';
svgData[74] = '<rect width="50" height="50" x="500" y="100';
svgData[75] = '<rect width="50" height="50" x="550" y="100';
svgData[76] = '<rect width="50" height="50" x="600" y="100';
svgData[77] = '<rect width="50" height="50" x="650" y="100';
svgData[78] = '<rect width="50" height="50" x="700" y="100';
svgData[79] = '<rect width="50" height="50" x="750" y="100';
svgData[80] = '<rect width="50" height="50" x="800" y="100';
svgData[81] = '<rect width="50" height="50" x="850" y="100';
svgData[82] = '<rect width="50" height="50" x="900" y="100';
svgData[83] = '<rect width="50" height="50" x="950" y="100';
svgData[84] = '<rect width="50" height="50" x="1000" y="100';
svgData[85] = '<rect width="50" height="50" x="1050" y="100';
svgData[86] = '<rect width="50" height="50" x="1100" y="100';
svgData[87] = '<rect width="50" height="50" x="1150" y="100';
svgData[88] = '<rect width="50" height="50" x="1200" y="100';
svgData[89] = '<rect width="50" height="50" x="1250" y="100';
svgData[90] = '<rect width="50" height="50" x="1300" y="100';
svgData[91] = '<rect width="50" height="50" x="1350" y="100';
svgData[92] = '<rect width="50" height="50" x="1400" y="100';
svgData[93] = '<rect width="50" height="50" x="1450" y="100';
svgData[94] = '<rect width="50" height="50" x="1500" y="100';
svgData[95] = '<rect width="50" height="50" x="1550" y="100';
svgData[96] = '<rect width="50" height="50" x="0" y="150';
svgData[97] = '<rect width="50" height="50" x="50" y="150';
svgData[98] = '<rect width="50" height="50" x="100" y="150';
svgData[99] = '<rect width="50" height="50" x="150" y="150';
svgData[100] = '<rect width="50" height="50" x="200" y="150';
svgData[101] = '<rect width="50" height="50" x="250" y="150';
svgData[102] = '<rect width="50" height="50" x="300" y="150';
svgData[103] = '<rect width="50" height="50" x="350" y="150';
svgData[104] = '<rect width="50" height="50" x="400" y="150';
svgData[105] = '<rect width="50" height="50" x="450" y="150';
svgData[106] = '<rect width="50" height="50" x="500" y="150';
svgData[107] = '<rect width="50" height="50" x="550" y="150';
svgData[108] = '<rect width="50" height="50" x="600" y="150';
svgData[109] = '<rect width="50" height="50" x="650" y="150';
svgData[110] = '<rect width="50" height="50" x="700" y="150';
svgData[111] = '<rect width="50" height="50" x="750" y="150';
svgData[112] = '<rect width="50" height="50" x="800" y="150';
svgData[113] = '<rect width="50" height="50" x="850" y="150';
svgData[114] = '<rect width="50" height="50" x="900" y="150';
svgData[115] = '<rect width="50" height="50" x="950" y="150';
svgData[116] = '<rect width="50" height="50" x="1000" y="150';
svgData[117] = '<rect width="50" height="50" x="1050" y="150';
svgData[118] = '<rect width="50" height="50" x="1100" y="150';
svgData[119] = '<rect width="50" height="50" x="1150" y="150';
svgData[120] = '<rect width="50" height="50" x="1200" y="150';
svgData[121] = '<rect width="50" height="50" x="1250" y="150';
svgData[122] = '<rect width="50" height="50" x="1300" y="150';
svgData[123] = '<rect width="50" height="50" x="1350" y="150';
svgData[124] = '<rect width="50" height="50" x="1400" y="150';
svgData[125] = '<rect width="50" height="50" x="1450" y="150';
svgData[126] = '<rect width="50" height="50" x="1500" y="150';
svgData[127] = '<rect width="50" height="50" x="1550" y="150';
svgData[128] = '<rect width="50" height="50" x="0" y="200';
svgData[129] = '<rect width="50" height="50" x="50" y="200';
svgData[130] = '<rect width="50" height="50" x="100" y="200';
svgData[131] = '<rect width="50" height="50" x="150" y="200';
svgData[132] = '<rect width="50" height="50" x="200" y="200';
svgData[133] = '<rect width="50" height="50" x="250" y="200';
svgData[134] = '<rect width="50" height="50" x="300" y="200';
svgData[135] = '<rect width="50" height="50" x="350" y="200';
svgData[136] = '<rect width="50" height="50" x="400" y="200';
svgData[137] = '<rect width="50" height="50" x="450" y="200';
svgData[138] = '<rect width="50" height="50" x="500" y="200';
svgData[139] = '<rect width="50" height="50" x="550" y="200';
svgData[140] = '<rect width="50" height="50" x="600" y="200';
svgData[141] = '<rect width="50" height="50" x="650" y="200';
svgData[142] = '<rect width="50" height="50" x="700" y="200';
svgData[143] = '<rect width="50" height="50" x="750" y="200';
svgData[144] = '<rect width="50" height="50" x="800" y="200';
svgData[145] = '<rect width="50" height="50" x="850" y="200';
svgData[146] = '<rect width="50" height="50" x="900" y="200';
svgData[147] = '<rect width="50" height="50" x="950" y="200';
svgData[148] = '<rect width="50" height="50" x="1000" y="200';
svgData[149] = '<rect width="50" height="50" x="1050" y="200';
svgData[150] = '<rect width="50" height="50" x="1100" y="200';
svgData[151] = '<rect width="50" height="50" x="1150" y="200';
svgData[152] = '<rect width="50" height="50" x="1200" y="200';
svgData[153] = '<rect width="50" height="50" x="1250" y="200';
svgData[154] = '<rect width="50" height="50" x="1300" y="200';
svgData[155] = '<rect width="50" height="50" x="1350" y="200';
svgData[156] = '<rect width="50" height="50" x="1400" y="200';
svgData[157] = '<rect width="50" height="50" x="1450" y="200';
svgData[158] = '<rect width="50" height="50" x="1500" y="200';
svgData[159] = '<rect width="50" height="50" x="1550" y="200';
svgData[160] = '<rect width="50" height="50" x="0" y="250';
svgData[161] = '<rect width="50" height="50" x="50" y="250';
svgData[162] = '<rect width="50" height="50" x="100" y="250';
svgData[163] = '<rect width="50" height="50" x="150" y="250';
svgData[164] = '<rect width="50" height="50" x="200" y="250';
svgData[165] = '<rect width="50" height="50" x="250" y="250';
svgData[166] = '<rect width="50" height="50" x="300" y="250';
svgData[167] = '<rect width="50" height="50" x="350" y="250';
svgData[168] = '<rect width="50" height="50" x="400" y="250';
svgData[169] = '<rect width="50" height="50" x="450" y="250';
svgData[170] = '<rect width="50" height="50" x="500" y="250';
svgData[171] = '<rect width="50" height="50" x="550" y="250';
svgData[172] = '<rect width="50" height="50" x="600" y="250';
svgData[173] = '<rect width="50" height="50" x="650" y="250';
svgData[174] = '<rect width="50" height="50" x="700" y="250';
svgData[175] = '<rect width="50" height="50" x="750" y="250';
svgData[176] = '<rect width="50" height="50" x="800" y="250';
svgData[177] = '<rect width="50" height="50" x="850" y="250';
svgData[178] = '<rect width="50" height="50" x="900" y="250';
svgData[179] = '<rect width="50" height="50" x="950" y="250';
svgData[180] = '<rect width="50" height="50" x="1000" y="250';
svgData[181] = '<rect width="50" height="50" x="1050" y="250';
svgData[182] = '<rect width="50" height="50" x="1100" y="250';
svgData[183] = '<rect width="50" height="50" x="1150" y="250';
svgData[184] = '<rect width="50" height="50" x="1200" y="250';
svgData[185] = '<rect width="50" height="50" x="1250" y="250';
svgData[186] = '<rect width="50" height="50" x="1300" y="250';
svgData[187] = '<rect width="50" height="50" x="1350" y="250';
svgData[188] = '<rect width="50" height="50" x="1400" y="250';
svgData[189] = '<rect width="50" height="50" x="1450" y="250';
svgData[190] = '<rect width="50" height="50" x="1500" y="250';
svgData[191] = '<rect width="50" height="50" x="1550" y="250';
svgData[192] = '<rect width="50" height="50" x="0" y="300';
svgData[193] = '<rect width="50" height="50" x="50" y="300';
svgData[194] = '<rect width="50" height="50" x="100" y="300';
svgData[195] = '<rect width="50" height="50" x="150" y="300';
svgData[196] = '<rect width="50" height="50" x="200" y="300';
svgData[197] = '<rect width="50" height="50" x="250" y="300';
svgData[198] = '<rect width="50" height="50" x="300" y="300';
svgData[199] = '<rect width="50" height="50" x="350" y="300';
svgData[200] = '<rect width="50" height="50" x="400" y="300';
svgData[201] = '<rect width="50" height="50" x="450" y="300';
svgData[202] = '<rect width="50" height="50" x="500" y="300';
svgData[203] = '<rect width="50" height="50" x="550" y="300';
svgData[204] = '<rect width="50" height="50" x="600" y="300';
svgData[205] = '<rect width="50" height="50" x="650" y="300';
svgData[206] = '<rect width="50" height="50" x="700" y="300';
svgData[207] = '<rect width="50" height="50" x="750" y="300';
svgData[208] = '<rect width="50" height="50" x="800" y="300';
svgData[209] = '<rect width="50" height="50" x="850" y="300';
svgData[210] = '<rect width="50" height="50" x="900" y="300';
svgData[211] = '<rect width="50" height="50" x="950" y="300';
svgData[212] = '<rect width="50" height="50" x="1000" y="300';
svgData[213] = '<rect width="50" height="50" x="1050" y="300';
svgData[214] = '<rect width="50" height="50" x="1100" y="300';
svgData[215] = '<rect width="50" height="50" x="1150" y="300';
svgData[216] = '<rect width="50" height="50" x="1200" y="300';
svgData[217] = '<rect width="50" height="50" x="1250" y="300';
svgData[218] = '<rect width="50" height="50" x="1300" y="300';
svgData[219] = '<rect width="50" height="50" x="1350" y="300';
svgData[220] = '<rect width="50" height="50" x="1400" y="300';
svgData[221] = '<rect width="50" height="50" x="1450" y="300';
svgData[222] = '<rect width="50" height="50" x="1500" y="300';
svgData[223] = '<rect width="50" height="50" x="1550" y="300';
svgData[224] = '<rect width="50" height="50" x="0" y="350';
svgData[225] = '<rect width="50" height="50" x="50" y="350';
svgData[226] = '<rect width="50" height="50" x="100" y="350';
svgData[227] = '<rect width="50" height="50" x="150" y="350';
svgData[228] = '<rect width="50" height="50" x="200" y="350';
svgData[229] = '<rect width="50" height="50" x="250" y="350';
svgData[230] = '<rect width="50" height="50" x="300" y="350';
svgData[231] = '<rect width="50" height="50" x="350" y="350';
svgData[232] = '<rect width="50" height="50" x="400" y="350';
svgData[233] = '<rect width="50" height="50" x="450" y="350';
svgData[234] = '<rect width="50" height="50" x="500" y="350';
svgData[235] = '<rect width="50" height="50" x="550" y="350';
svgData[236] = '<rect width="50" height="50" x="600" y="350';
svgData[237] = '<rect width="50" height="50" x="650" y="350';
svgData[238] = '<rect width="50" height="50" x="700" y="350';
svgData[239] = '<rect width="50" height="50" x="750" y="350';
svgData[240] = '<rect width="50" height="50" x="800" y="350';
svgData[241] = '<rect width="50" height="50" x="850" y="350';
svgData[242] = '<rect width="50" height="50" x="900" y="350';
svgData[243] = '<rect width="50" height="50" x="950" y="350';
svgData[244] = '<rect width="50" height="50" x="1000" y="350';
svgData[245] = '<rect width="50" height="50" x="1050" y="350';
svgData[246] = '<rect width="50" height="50" x="1100" y="350';
svgData[247] = '<rect width="50" height="50" x="1150" y="350';
svgData[248] = '<rect width="50" height="50" x="1200" y="350';
svgData[249] = '<rect width="50" height="50" x="1250" y="350';
svgData[250] = '<rect width="50" height="50" x="1300" y="350';
svgData[251] = '<rect width="50" height="50" x="1350" y="350';
svgData[252] = '<rect width="50" height="50" x="1400" y="350';
svgData[253] = '<rect width="50" height="50" x="1450" y="350';
svgData[254] = '<rect width="50" height="50" x="1500" y="350';
svgData[255] = '<rect width="50" height="50" x="1550" y="350';
svgData[256] = '<rect width="50" height="50" x="0" y="400';
svgData[257] = '<rect width="50" height="50" x="50" y="400';
svgData[258] = '<rect width="50" height="50" x="100" y="400';
svgData[259] = '<rect width="50" height="50" x="150" y="400';
svgData[260] = '<rect width="50" height="50" x="200" y="400';
svgData[261] = '<rect width="50" height="50" x="250" y="400';
svgData[262] = '<rect width="50" height="50" x="300" y="400';
svgData[263] = '<rect width="50" height="50" x="350" y="400';
svgData[264] = '<rect width="50" height="50" x="400" y="400';
svgData[265] = '<rect width="50" height="50" x="450" y="400';
svgData[266] = '<rect width="50" height="50" x="500" y="400';
svgData[267] = '<rect width="50" height="50" x="550" y="400';
svgData[268] = '<rect width="50" height="50" x="600" y="400';
svgData[269] = '<rect width="50" height="50" x="650" y="400';
svgData[270] = '<rect width="50" height="50" x="700" y="400';
svgData[271] = '<rect width="50" height="50" x="750" y="400';
svgData[272] = '<rect width="50" height="50" x="800" y="400';
svgData[273] = '<rect width="50" height="50" x="850" y="400';
svgData[274] = '<rect width="50" height="50" x="900" y="400';
svgData[275] = '<rect width="50" height="50" x="950" y="400';
svgData[276] = '<rect width="50" height="50" x="1000" y="400';
svgData[277] = '<rect width="50" height="50" x="1050" y="400';
svgData[278] = '<rect width="50" height="50" x="1100" y="400';
svgData[279] = '<rect width="50" height="50" x="1150" y="400';
svgData[280] = '<rect width="50" height="50" x="1200" y="400';
svgData[281] = '<rect width="50" height="50" x="1250" y="400';
svgData[282] = '<rect width="50" height="50" x="1300" y="400';
svgData[283] = '<rect width="50" height="50" x="1350" y="400';
svgData[284] = '<rect width="50" height="50" x="1400" y="400';
svgData[285] = '<rect width="50" height="50" x="1450" y="400';
svgData[286] = '<rect width="50" height="50" x="1500" y="400';
svgData[287] = '<rect width="50" height="50" x="1550" y="400';
svgData[288] = '<rect width="50" height="50" x="0" y="450';
svgData[289] = '<rect width="50" height="50" x="50" y="450';
svgData[290] = '<rect width="50" height="50" x="100" y="450';
svgData[291] = '<rect width="50" height="50" x="150" y="450';
svgData[292] = '<rect width="50" height="50" x="200" y="450';
svgData[293] = '<rect width="50" height="50" x="250" y="450';
svgData[294] = '<rect width="50" height="50" x="300" y="450';
svgData[295] = '<rect width="50" height="50" x="350" y="450';
svgData[296] = '<rect width="50" height="50" x="400" y="450';
svgData[297] = '<rect width="50" height="50" x="450" y="450';
svgData[298] = '<rect width="50" height="50" x="500" y="450';
svgData[299] = '<rect width="50" height="50" x="550" y="450';
svgData[300] = '<rect width="50" height="50" x="600" y="450';
svgData[301] = '<rect width="50" height="50" x="650" y="450';
svgData[302] = '<rect width="50" height="50" x="700" y="450';
svgData[303] = '<rect width="50" height="50" x="750" y="450';
svgData[304] = '<rect width="50" height="50" x="800" y="450';
svgData[305] = '<rect width="50" height="50" x="850" y="450';
svgData[306] = '<rect width="50" height="50" x="900" y="450';
svgData[307] = '<rect width="50" height="50" x="950" y="450';
svgData[308] = '<rect width="50" height="50" x="1000" y="450';
svgData[309] = '<rect width="50" height="50" x="1050" y="450';
svgData[310] = '<rect width="50" height="50" x="1100" y="450';
svgData[311] = '<rect width="50" height="50" x="1150" y="450';
svgData[312] = '<rect width="50" height="50" x="1200" y="450';
svgData[313] = '<rect width="50" height="50" x="1250" y="450';
svgData[314] = '<rect width="50" height="50" x="1300" y="450';
svgData[315] = '<rect width="50" height="50" x="1350" y="450';
svgData[316] = '<rect width="50" height="50" x="1400" y="450';
svgData[317] = '<rect width="50" height="50" x="1450" y="450';
svgData[318] = '<rect width="50" height="50" x="1500" y="450';
svgData[319] = '<rect width="50" height="50" x="1550" y="450';
svgData[320] = '<rect width="50" height="50" x="0" y="500';
svgData[321] = '<rect width="50" height="50" x="50" y="500';
svgData[322] = '<rect width="50" height="50" x="100" y="500';
svgData[323] = '<rect width="50" height="50" x="150" y="500';
svgData[324] = '<rect width="50" height="50" x="200" y="500';
svgData[325] = '<rect width="50" height="50" x="250" y="500';
svgData[326] = '<rect width="50" height="50" x="300" y="500';
svgData[327] = '<rect width="50" height="50" x="350" y="500';
svgData[328] = '<rect width="50" height="50" x="400" y="500';
svgData[329] = '<rect width="50" height="50" x="450" y="500';
svgData[330] = '<rect width="50" height="50" x="500" y="500';
svgData[331] = '<rect width="50" height="50" x="550" y="500';
svgData[332] = '<rect width="50" height="50" x="600" y="500';
svgData[333] = '<rect width="50" height="50" x="650" y="500';
svgData[334] = '<rect width="50" height="50" x="700" y="500';
svgData[335] = '<rect width="50" height="50" x="750" y="500';
svgData[336] = '<rect width="50" height="50" x="800" y="500';
svgData[337] = '<rect width="50" height="50" x="850" y="500';
svgData[338] = '<rect width="50" height="50" x="900" y="500';
svgData[339] = '<rect width="50" height="50" x="950" y="500';
svgData[340] = '<rect width="50" height="50" x="1000" y="500';
svgData[341] = '<rect width="50" height="50" x="1050" y="500';
svgData[342] = '<rect width="50" height="50" x="1100" y="500';
svgData[343] = '<rect width="50" height="50" x="1150" y="500';
svgData[344] = '<rect width="50" height="50" x="1200" y="500';
svgData[345] = '<rect width="50" height="50" x="1250" y="500';
svgData[346] = '<rect width="50" height="50" x="1300" y="500';
svgData[347] = '<rect width="50" height="50" x="1350" y="500';
svgData[348] = '<rect width="50" height="50" x="1400" y="500';
svgData[349] = '<rect width="50" height="50" x="1450" y="500';
svgData[350] = '<rect width="50" height="50" x="1500" y="500';
svgData[351] = '<rect width="50" height="50" x="1550" y="500';
svgData[352] = '<rect width="50" height="50" x="0" y="550';
svgData[353] = '<rect width="50" height="50" x="50" y="550';
svgData[354] = '<rect width="50" height="50" x="100" y="550';
svgData[355] = '<rect width="50" height="50" x="150" y="550';
svgData[356] = '<rect width="50" height="50" x="200" y="550';
svgData[357] = '<rect width="50" height="50" x="250" y="550';
svgData[358] = '<rect width="50" height="50" x="300" y="550';
svgData[359] = '<rect width="50" height="50" x="350" y="550';
svgData[360] = '<rect width="50" height="50" x="400" y="550';
svgData[361] = '<rect width="50" height="50" x="450" y="550';
svgData[362] = '<rect width="50" height="50" x="500" y="550';
svgData[363] = '<rect width="50" height="50" x="550" y="550';
svgData[364] = '<rect width="50" height="50" x="600" y="550';
svgData[365] = '<rect width="50" height="50" x="650" y="550';
svgData[366] = '<rect width="50" height="50" x="700" y="550';
svgData[367] = '<rect width="50" height="50" x="750" y="550';
svgData[368] = '<rect width="50" height="50" x="800" y="550';
svgData[369] = '<rect width="50" height="50" x="850" y="550';
svgData[370] = '<rect width="50" height="50" x="900" y="550';
svgData[371] = '<rect width="50" height="50" x="950" y="550';
svgData[372] = '<rect width="50" height="50" x="1000" y="550';
svgData[373] = '<rect width="50" height="50" x="1050" y="550';
svgData[374] = '<rect width="50" height="50" x="1100" y="550';
svgData[375] = '<rect width="50" height="50" x="1150" y="550';
svgData[376] = '<rect width="50" height="50" x="1200" y="550';
svgData[377] = '<rect width="50" height="50" x="1250" y="550';
svgData[378] = '<rect width="50" height="50" x="1300" y="550';
svgData[379] = '<rect width="50" height="50" x="1350" y="550';
svgData[380] = '<rect width="50" height="50" x="1400" y="550';
svgData[381] = '<rect width="50" height="50" x="1450" y="550';
svgData[382] = '<rect width="50" height="50" x="1500" y="550';
svgData[383] = '<rect width="50" height="50" x="1550" y="550';
svgData[384] = '<rect width="50" height="50" x="0" y="600';
svgData[385] = '<rect width="50" height="50" x="50" y="600';
svgData[386] = '<rect width="50" height="50" x="100" y="600';
svgData[387] = '<rect width="50" height="50" x="150" y="600';
svgData[388] = '<rect width="50" height="50" x="200" y="600';
svgData[389] = '<rect width="50" height="50" x="250" y="600';
svgData[390] = '<rect width="50" height="50" x="300" y="600';
svgData[391] = '<rect width="50" height="50" x="350" y="600';
svgData[392] = '<rect width="50" height="50" x="400" y="600';
svgData[393] = '<rect width="50" height="50" x="450" y="600';
svgData[394] = '<rect width="50" height="50" x="500" y="600';
svgData[395] = '<rect width="50" height="50" x="550" y="600';
svgData[396] = '<rect width="50" height="50" x="600" y="600';
svgData[397] = '<rect width="50" height="50" x="650" y="600';
svgData[398] = '<rect width="50" height="50" x="700" y="600';
svgData[399] = '<rect width="50" height="50" x="750" y="600';
svgData[400] = '<rect width="50" height="50" x="800" y="600';
svgData[401] = '<rect width="50" height="50" x="850" y="600';
svgData[402] = '<rect width="50" height="50" x="900" y="600';
svgData[403] = '<rect width="50" height="50" x="950" y="600';
svgData[404] = '<rect width="50" height="50" x="1000" y="600';
svgData[405] = '<rect width="50" height="50" x="1050" y="600';
svgData[406] = '<rect width="50" height="50" x="1100" y="600';
svgData[407] = '<rect width="50" height="50" x="1150" y="600';
svgData[408] = '<rect width="50" height="50" x="1200" y="600';
svgData[409] = '<rect width="50" height="50" x="1250" y="600';
svgData[410] = '<rect width="50" height="50" x="1300" y="600';
svgData[411] = '<rect width="50" height="50" x="1350" y="600';
svgData[412] = '<rect width="50" height="50" x="1400" y="600';
svgData[413] = '<rect width="50" height="50" x="1450" y="600';
svgData[414] = '<rect width="50" height="50" x="1500" y="600';
svgData[415] = '<rect width="50" height="50" x="1550" y="600';
svgData[416] = '<rect width="50" height="50" x="0" y="650';
svgData[417] = '<rect width="50" height="50" x="50" y="650';
svgData[418] = '<rect width="50" height="50" x="100" y="650';
svgData[419] = '<rect width="50" height="50" x="150" y="650';
svgData[420] = '<rect width="50" height="50" x="200" y="650';
svgData[421] = '<rect width="50" height="50" x="250" y="650';
svgData[422] = '<rect width="50" height="50" x="300" y="650';
svgData[423] = '<rect width="50" height="50" x="350" y="650';
svgData[424] = '<rect width="50" height="50" x="400" y="650';
svgData[425] = '<rect width="50" height="50" x="450" y="650';
svgData[426] = '<rect width="50" height="50" x="500" y="650';
svgData[427] = '<rect width="50" height="50" x="550" y="650';
svgData[428] = '<rect width="50" height="50" x="600" y="650';
svgData[429] = '<rect width="50" height="50" x="650" y="650';
svgData[430] = '<rect width="50" height="50" x="700" y="650';
svgData[431] = '<rect width="50" height="50" x="750" y="650';
svgData[432] = '<rect width="50" height="50" x="800" y="650';
svgData[433] = '<rect width="50" height="50" x="850" y="650';
svgData[434] = '<rect width="50" height="50" x="900" y="650';
svgData[435] = '<rect width="50" height="50" x="950" y="650';
svgData[436] = '<rect width="50" height="50" x="1000" y="650';
svgData[437] = '<rect width="50" height="50" x="1050" y="650';
svgData[438] = '<rect width="50" height="50" x="1100" y="650';
svgData[439] = '<rect width="50" height="50" x="1150" y="650';
svgData[440] = '<rect width="50" height="50" x="1200" y="650';
svgData[441] = '<rect width="50" height="50" x="1250" y="650';
svgData[442] = '<rect width="50" height="50" x="1300" y="650';
svgData[443] = '<rect width="50" height="50" x="1350" y="650';
svgData[444] = '<rect width="50" height="50" x="1400" y="650';
svgData[445] = '<rect width="50" height="50" x="1450" y="650';
svgData[446] = '<rect width="50" height="50" x="1500" y="650';
svgData[447] = '<rect width="50" height="50" x="1550" y="650';
svgData[448] = '<rect width="50" height="50" x="0" y="700';
svgData[449] = '<rect width="50" height="50" x="50" y="700';
svgData[450] = '<rect width="50" height="50" x="100" y="700';
svgData[451] = '<rect width="50" height="50" x="150" y="700';
svgData[452] = '<rect width="50" height="50" x="200" y="700';
svgData[453] = '<rect width="50" height="50" x="250" y="700';
svgData[454] = '<rect width="50" height="50" x="300" y="700';
svgData[455] = '<rect width="50" height="50" x="350" y="700';
svgData[456] = '<rect width="50" height="50" x="400" y="700';
svgData[457] = '<rect width="50" height="50" x="450" y="700';
svgData[458] = '<rect width="50" height="50" x="500" y="700';
svgData[459] = '<rect width="50" height="50" x="550" y="700';
svgData[460] = '<rect width="50" height="50" x="600" y="700';
svgData[461] = '<rect width="50" height="50" x="650" y="700';
svgData[462] = '<rect width="50" height="50" x="700" y="700';
svgData[463] = '<rect width="50" height="50" x="750" y="700';
svgData[464] = '<rect width="50" height="50" x="800" y="700';
svgData[465] = '<rect width="50" height="50" x="850" y="700';
svgData[466] = '<rect width="50" height="50" x="900" y="700';
svgData[467] = '<rect width="50" height="50" x="950" y="700';
svgData[468] = '<rect width="50" height="50" x="1000" y="700';
svgData[469] = '<rect width="50" height="50" x="1050" y="700';
svgData[470] = '<rect width="50" height="50" x="1100" y="700';
svgData[471] = '<rect width="50" height="50" x="1150" y="700';
svgData[472] = '<rect width="50" height="50" x="1200" y="700';
svgData[473] = '<rect width="50" height="50" x="1250" y="700';
svgData[474] = '<rect width="50" height="50" x="1300" y="700';
svgData[475] = '<rect width="50" height="50" x="1350" y="700';
svgData[476] = '<rect width="50" height="50" x="1400" y="700';
svgData[477] = '<rect width="50" height="50" x="1450" y="700';
svgData[478] = '<rect width="50" height="50" x="1500" y="700';
svgData[479] = '<rect width="50" height="50" x="1550" y="700';
svgData[480] = '<rect width="50" height="50" x="0" y="750';
svgData[481] = '<rect width="50" height="50" x="50" y="750';
svgData[482] = '<rect width="50" height="50" x="100" y="750';
svgData[483] = '<rect width="50" height="50" x="150" y="750';
svgData[484] = '<rect width="50" height="50" x="200" y="750';
svgData[485] = '<rect width="50" height="50" x="250" y="750';
svgData[486] = '<rect width="50" height="50" x="300" y="750';
svgData[487] = '<rect width="50" height="50" x="350" y="750';
svgData[488] = '<rect width="50" height="50" x="400" y="750';
svgData[489] = '<rect width="50" height="50" x="450" y="750';
svgData[490] = '<rect width="50" height="50" x="500" y="750';
svgData[491] = '<rect width="50" height="50" x="550" y="750';
svgData[492] = '<rect width="50" height="50" x="600" y="750';
svgData[493] = '<rect width="50" height="50" x="650" y="750';
svgData[494] = '<rect width="50" height="50" x="700" y="750';
svgData[495] = '<rect width="50" height="50" x="750" y="750';
svgData[496] = '<rect width="50" height="50" x="800" y="750';
svgData[497] = '<rect width="50" height="50" x="850" y="750';
svgData[498] = '<rect width="50" height="50" x="900" y="750';
svgData[499] = '<rect width="50" height="50" x="950" y="750';
svgData[500] = '<rect width="50" height="50" x="1000" y="750';
svgData[501] = '<rect width="50" height="50" x="1050" y="750';
svgData[502] = '<rect width="50" height="50" x="1100" y="750';
svgData[503] = '<rect width="50" height="50" x="1150" y="750';
svgData[504] = '<rect width="50" height="50" x="1200" y="750';
svgData[505] = '<rect width="50" height="50" x="1250" y="750';
svgData[506] = '<rect width="50" height="50" x="1300" y="750';
svgData[507] = '<rect width="50" height="50" x="1350" y="750';
svgData[508] = '<rect width="50" height="50" x="1400" y="750';
svgData[509] = '<rect width="50" height="50" x="1450" y="750';
svgData[510] = '<rect width="50" height="50" x="1500" y="750';
svgData[511] = '<rect width="50" height="50" x="1550" y="750';






  var svgBackgroundColor = {};
  svgBackgroundColor[0] = '#800000"/>';
  svgBackgroundColor[1] = '#8B0000"/>';
  svgBackgroundColor[2] = '#A52A2A"/>';
  svgBackgroundColor[3] = '#B22222"/>';
  svgBackgroundColor[4] = '#DC143C"/>';
  svgBackgroundColor[5] = '#FF0000"/>';
  svgBackgroundColor[6] = '#FF6347"/>';
  svgBackgroundColor[7] = '#FF7F50"/>';
  svgBackgroundColor[8] = '#CD5C5C"/>';
  svgBackgroundColor[9] = '#F08080"/>';
  svgBackgroundColor[10] = '#E9967A"/>';
  svgBackgroundColor[11] = '#FA8072"/>';
  svgBackgroundColor[12] = '#FFA07A"/>';
  svgBackgroundColor[13] = '#FF4500"/>';
  svgBackgroundColor[14] = '#FF8C00"/>';
  svgBackgroundColor[15] = '#FFA500"/>';
  svgBackgroundColor[16] = '#FFD700"/>';
  svgBackgroundColor[17] = '#B8860B"/>';
  svgBackgroundColor[18] = '#DAA520"/>';
  svgBackgroundColor[19] = '#EEE8AA"/>';
  svgBackgroundColor[20] = '#BDB76B"/>';
  svgBackgroundColor[21] = '#F0E68C"/>';
  svgBackgroundColor[22] = '#808000"/>';
  svgBackgroundColor[23] = '#FFFF00"/>';
  svgBackgroundColor[24] = '#9ACD32"/>';
  svgBackgroundColor[25] = '#556B2F"/>';
  svgBackgroundColor[26] = '#6B8E23"/>';
  svgBackgroundColor[27] = '#7CFC00"/>';
  svgBackgroundColor[28] = '#7FFF00"/>';
  svgBackgroundColor[29] = '#ADFF2F"/>';
  svgBackgroundColor[30] = '#006400"/>';
  svgBackgroundColor[31] = '#008000"/>';
  svgBackgroundColor[32] = '#228B22"/>';
  svgBackgroundColor[33] = '#00FF00"/>';
  svgBackgroundColor[34] = '#32CD32"/>';
  svgBackgroundColor[35] = '#90EE90"/>';
  svgBackgroundColor[36] = '#98FB98"/>';
  svgBackgroundColor[37] = '#8FBC8F"/>';
  svgBackgroundColor[38] = '#00FA9A"/>';
  svgBackgroundColor[39] = '#00FF7F"/>';
  svgBackgroundColor[40] = '#2E8B57"/>';
  svgBackgroundColor[41] = '#66CDAA"/>';
  svgBackgroundColor[42] = '#3CB371"/>';
  svgBackgroundColor[43] = '#20B2AA"/>';
  svgBackgroundColor[44] = '#2F4F4F"/>';
  svgBackgroundColor[45] = '#008080"/>';
  svgBackgroundColor[46] = '#008B8B"/>';
  svgBackgroundColor[47] = '#00FFFF"/>';
  svgBackgroundColor[48] = '#00FFFF"/>';
  svgBackgroundColor[49] = '#E0FFFF"/>';
  svgBackgroundColor[50] = '#00CED1"/>';
  svgBackgroundColor[51] = '#40E0D0"/>';
  svgBackgroundColor[52] = '#48D1CC"/>';
  svgBackgroundColor[53] = '#AFEEEE"/>';
  svgBackgroundColor[54] = '#7FFFD4"/>';
  svgBackgroundColor[55] = '#B0E0E6"/>';
  svgBackgroundColor[56] = '#5F9EA0"/>';
  svgBackgroundColor[57] = '#4682B4"/>';
  svgBackgroundColor[58] = '#6495ED"/>';
  svgBackgroundColor[59] = '#00BFFF"/>';
  svgBackgroundColor[60] = '#1E90FF"/>';
  svgBackgroundColor[61] = '#ADD8E6"/>';
  svgBackgroundColor[62] = '#87CEEB"/>';
  svgBackgroundColor[63] = '#87CEFA"/>';
  svgBackgroundColor[64] = '#191970"/>';
  svgBackgroundColor[65] = '#000080"/>';
  svgBackgroundColor[66] = '#00008B"/>';
  svgBackgroundColor[67] = '#0000CD"/>';
  svgBackgroundColor[68] = '#0000FF"/>';
  svgBackgroundColor[69] = '#4169E1"/>';
  svgBackgroundColor[70] = '#8A2BE2"/>';
  svgBackgroundColor[71] = '#4B0082"/>';
  svgBackgroundColor[72] = '#483D8B"/>';
  svgBackgroundColor[73] = '#6A5ACD"/>';
  svgBackgroundColor[74] = '#7B68EE"/>';
  svgBackgroundColor[75] = '#9370DB"/>';
  svgBackgroundColor[76] = '#8B008B"/>';
  svgBackgroundColor[77] = '#9400D3"/>';
  svgBackgroundColor[78] = '#9932CC"/>';
  svgBackgroundColor[79] = '#BA55D3"/>';
  svgBackgroundColor[80] = '#800080"/>';
  svgBackgroundColor[81] = '#D8BFD8"/>';
  svgBackgroundColor[82] = '#DDA0DD"/>';
  svgBackgroundColor[83] = '#EE82EE"/>';
  svgBackgroundColor[84] = '#FF00FF"/>';
  svgBackgroundColor[85] = '#DA70D6"/>';
  svgBackgroundColor[86] = '#C71585"/>';
  svgBackgroundColor[87] = '#DB7093"/>';
  svgBackgroundColor[88] = '#FF1493"/>';
  svgBackgroundColor[89] = '#FF69B4"/>';
  svgBackgroundColor[90] = '#FFB6C1"/>';
  svgBackgroundColor[91] = '#FFC0CB"/>';
  svgBackgroundColor[92] = '#FAEBD7"/>';
  svgBackgroundColor[93] = '#F5F5DC"/>';
  svgBackgroundColor[94] = '#FFE4C4"/>';
  svgBackgroundColor[95] = '#FFEBCD"/>';
  svgBackgroundColor[96] = '#F5DEB3"/>';
  svgBackgroundColor[97] = '#FFF8DC"/>';
  svgBackgroundColor[98] = '#FFFACD"/>';
  svgBackgroundColor[99] = '#FAFAD2"/>';
  svgBackgroundColor[100] = '#FFFFE0"/>';
  svgBackgroundColor[101] = '#8B4513"/>';
  svgBackgroundColor[102] = '#A0522D"/>';
  svgBackgroundColor[103] = '#D2691E"/>';
  svgBackgroundColor[104] = '#CD853F"/>';
  svgBackgroundColor[105] = '#F4A460"/>';
  svgBackgroundColor[106] = '#DEB887"/>';
  svgBackgroundColor[107] = '#D2B48C"/>';
  svgBackgroundColor[108] = '#BC8F8F"/>';
  svgBackgroundColor[109] = '#FFE4B5"/>';
  svgBackgroundColor[110] = '#FFDEAD"/>';
  svgBackgroundColor[111] = '#FFDAB9"/>';
  svgBackgroundColor[112] = '#FFE4E1"/>';
  svgBackgroundColor[113] = '#FFF0F5"/>';
  svgBackgroundColor[114] = '#FAF0E6"/>';
  svgBackgroundColor[115] = '#FDF5E6"/>';
  svgBackgroundColor[116] = '#FFEFD5"/>';
  svgBackgroundColor[117] = '#FFF5EE"/>';
  svgBackgroundColor[118] = '#F5FFFA"/>';
  svgBackgroundColor[119] = '#708090"/>';
  svgBackgroundColor[120] = '#778899"/>';
  svgBackgroundColor[121] = '#B0C4DE"/>';
  svgBackgroundColor[122] = '#E6E6FA"/>';
  svgBackgroundColor[123] = '#FFFAF0"/>';
  svgBackgroundColor[124] = '#F0F8FF"/>';
  svgBackgroundColor[125] = '#F8F8FF"/>';
  svgBackgroundColor[126] = '#F0FFF0"/>';
  svgBackgroundColor[127] = '#FFFFF0"/>';
  svgBackgroundColor[128] = '#F0FFFF"/>';
  svgBackgroundColor[129] = '#FFFAFA"/>';
  svgBackgroundColor[130] = '#000000"/>';
  svgBackgroundColor[131] = '#696969"/>';
  svgBackgroundColor[132] = '#808080"/>';
  svgBackgroundColor[133] = '#A9A9A9"/>';
  svgBackgroundColor[134] = '#C0C0C0"/>';
  svgBackgroundColor[135] = '#D3D3D3"/>';
  svgBackgroundColor[136] = '#DCDCDC"/>';
  svgBackgroundColor[137] = '#FFFFFF"/>';


  let submitCustom = async (_tokenID) => {
    /* global BigInt */


    let tempPeices1 = peices.slice(0,256)
    let tempPeices2 = peices.slice(256, 512)

    let data1 = BigInt('0b'+tempPeices1.join("").toString())
    let data2 = BigInt('0b'+tempPeices2.join("").toString())

    let tempColorOne1 = color1.slice(0,256)
    let tempColorOne2 = color1.slice(256, 512)
    let colorOne1 = BigInt('0b'+tempColorOne1.join("").toString())
    let colorOne2 = BigInt('0b'+tempColorOne2.join("").toString())

    let tempColorTwo1 = color2.slice(0,256)
    let tempColorTwo2 = color2.slice(256, 512)

    let colorTwo1 = BigInt('0b'+tempColorTwo1.join("").toString())
    let colorTwo2 = BigInt('0b'+tempColorTwo2.join("").toString())

    // await metakays.methods.kustomize()
        // await metakays.methods.kustomize(data1, data2, [colorOne1, colorOne2, colorTwo1, colorTwo2], four_colors, tokenID).send({
        //   from: userAccount,

        //   })
        setMsg1("Head to https://snowtrace.io/address/0x151c384c9d8aece2dfb50e61890217128f90c321#writeContract and connect your AVAX wallet. Scroll down to the *kustomize* function and input these values:")
        setMsg2("_data1: "+String(data1))
        setMsg3("_data2: "+String(data2))
        setMsg4("_colors: "+String(colorOne1)+','+String(colorOne2)+','+String(colorTwo1)+','+String(colorTwo2))
        setMsg5("_colorSelectors: "+String(four_colors[0])+','+String(four_colors[1])+','+String(four_colors[2])+','+String(four_colors[3]))
        setMsg6("_itemID: YOUR_TOKEN_ID")
        }


  // const handleShow = async (_value) => {
  //   setCustomChooser(_value)
  //   if (showKustomModal) {
  //     setKustomModal(false)
  //   } else {
  //     setKustomModal(true)
  //   }
  // };

  return (
        <div className="Custom">
        {/* backgroundColor */}
        {showKustomModal ?
          <div className="CustomSVGRightSide">
            <div className='over'><CustomIndividualAVAX/></div>
            <div className="CustomHeader">MENU</div>
            <Link to='/kustomavaxmenu' className="itemDropKustomETH">BACK</Link>
            <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, KUSTOMIZE ON AVAX BY HEADING TO https://snowtrace.io/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER SNOWTRACE AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: KUSTOMIZE ON HERE, THEN COPY AND PASTE THE DATA BELOW INTO THE "KUSTOMIZE" FUNCTION ON SNOWTRACE AND SUBMIT.</div>
        <div className="instructions">YOU MAY CHOOSE 4 COLORS (DEFAULT COLOR 1, COLOR #2, COLOR #3, COLOR #4). </div>
        {/* <div className="itemDropKustomETH" onClick={() => handleShow(24)}>LOAD</div> */}
            {/* <div className="itemDropKustomETH" onClick={() => handleShow(29)}>RESET (BLANK)</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT DEFAULT COLOR</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR #4</div>


            <div className="itemDropKustomETH" onClick={() => handleShow(2)}>DRAW WITH COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(1)}>DRAW WITH COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(0)}>DRAW WITH COLOR #4</div> */}
            <AvaxApp/>
            <div className="itemDropKustomETH" onClick={() => submitCustom()}>SUBMIT</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>
            <div className="customMessage">{msg3}</div>
            <div className="customMessage">{msg4}</div>
            <div className="customMessage">{msg5}</div>
            <div className="customMessage">{msg6}</div>


          </div>
        :
        <div className="CustomSVGRightSide">
          <div className="CustomHeader">MENU</div>
          <Link to='/kustomavaxmenu' className="itemDropKustomETH">BACK</Link>
          <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, KUSTOMIZE ON AVAX BY HEADING TO https://snowtrace.io/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER SNOWTRACE AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: KUSTOMIZE ON HERE, THEN COPY AND PASTE THE DATA BELOW INTO THE "KUSTOMIZE" FUNCTION ON SNOWTRACE AND SUBMIT.</div>
        <div><br></br></div>
        <div className="instructions">YOU MAY CHOOSE 4 COLORS (DEFAULT COLOR 1, COLOR 2, COLOR 3, COLOR 4). PLEASE CHOOSE YOUR 4 COLORS PRIOR TO DRAWING. CHANGING COLORS *AFTER* DRAWING WILL ERASE THE WHOLE DRAWING.</div>
        <div></div>
        <div className="instructions">CLICK 'UPDATE' TO REFRESH AND TO SEE YOUR CHANGES. </div>

        {/* <div className="itemDropKustomETH" onClick={() => handleShow(24)}>LOAD</div> */}
            {/* <div className="itemDropKustomETH" onClick={() => handleShow(29)}>RESET (BLANK)</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT DEFAULT COLOR</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR #4</div>


            <div className="itemDropKustomETH" onClick={() => handleShow(2)}>DRAW WITH COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(1)}>DRAW WITH COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(0)}>DRAW WITH COLOR #4</div> */}
            <AvaxApp/>
            <div className="itemDropKustomETH" onClick={() => submitCustom()}>SUBMIT</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>
            <div className="customMessage">{msg3}</div>
            <div className="customMessage">{msg4}</div>
            <div className="customMessage">{msg5}</div>
            <div className="customMessage">{msg6}</div>



        </div>}
        <div className="CustomSVGRightSide">
            {/* background  */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="{w}pt" height="{h}pt" viewBox="0 0 1600.000000 800.000000" preserveAspectRatio="xMidYMid meet" xmlnsXlink="http://www.w3.org/1999/xlink">
            {parse('<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">')}
            {parse('<path d="M0 4000 l0 -4000 8000 0 8000 0 0 4000 0 4000 -8000 0 -8000 0 0 -4000z" fill="' + String(svgBackgroundColor[bgColor]) + '</g>')}



            {/* {parse('<use xlinkHref="#cube" x="487" y="540" fill="#000000"/>')} */}

            {/* {peices.split("").forEach(character => {if (character == "0") {return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')}})} */}

            {peices.map((value, index) => {

              let color;

              if (parseInt(value) === 1) {
                color = svgBackgroundColor[four_colors[0]]
              } else {
                if (parseInt(color1[index]) === 1){
                  color = svgBackgroundColor[four_colors[1]]
                } else {
                  if (parseInt(color2[index]) === 1){
                    color = svgBackgroundColor[four_colors[2]]
                  } else {
                    color = svgBackgroundColor[four_colors[3]]
                  }
                }
              }
                // let color = color1[index] == 1 ? svgBackgroundColor[three_colors[0]] : color2[index] == 1 ? svgBackgroundColor[three_colors[1]] : svgBackgroundColor[three_colors[2]]
                let temp = svgData[index] + '" fill="' + color;
                return parse(temp)
                // return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')

         })}

            {parse('<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)" fill="#F5F5F5" stroke="none"> <path d="M14720 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z M15440 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z"/></g></svg>')}
            </svg>
          </div>
      </div>
  );
}






