import "../CustomMenu.css";
import { Link } from 'react-router-dom'

export default function CustomOPTMenu() {
  return (
    <div className="RealCustomMenu">
      <div className="CustomMenuHeader">SELECT</div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomopt'>KUSTOMIZE</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomoptbackground'>CHANGE BACKGROUND COLOR</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomoptfinality'>FINALITY</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomMenu'>BACK</Link>
      </div>
    </div>
  );
}
