import React from "react";
import { useAppContext } from "../../libs/contextLib";
import "./CustomIndividualETH.css"
import metakays from '../../metakays';


export default function CustomIndividualETH () {
  const { setBgColor, set_three_colors, color1, setColor1, color2, setColor2, setPeices, setKustomModal, customChooser, setTokenID, tokenID} = useAppContext();

  let changeLAYER = async (value) => {
    setPeices( prevState => {
      const peices = [...prevState]
      parseInt(peices[value]) === 1 ? peices[value] = 0 : peices[value] = 1
      return peices
    })
  }


  let changeCOLOR1 = async (value) => {
    if (parseInt(color1[value]) === 1) {
      setColor1( prevState => {
        const color1 = [...prevState]
        color1[value] = 0
        return color1
      })

    } else {

      setColor1( prevState => {
        const color1 = [...prevState]
        color1[value] = 1
        return color1
      })

    setColor2( prevState => {
      const color2 = [...prevState]
      color2[value] = 0
      return color2
    })
  }
}

let changeCOLOR2 = async (value) => {
  if (parseInt(color2[value]) === 1) {
    setColor2( prevState => {
      const color2 = [...prevState]
      color2[value] = 0
      return color2
    })

  } else {

    setColor2( prevState => {
      const color2 = [...prevState]
      color2[value] = 1
      return color2
    })

  setColor1( prevState => {
    const color1 = [...prevState]
    color1[value] = 0
    return color1
  })
}

}

let SetDefaultColor = async (value) => {
  set_three_colors( prevState => {
    const three_colors = [...prevState]
    three_colors[0] = value
    return three_colors
  })
  setKustomModal(false)
}

let SetColor1 = async (value) => {
  set_three_colors( prevState => {
    const three_colors = [...prevState]
    three_colors[1] = value
    return three_colors
  })
  setKustomModal(false)
}

let SetColor2 = async (value) => {
  set_three_colors( prevState => {
    const three_colors = [...prevState]
    three_colors[2] = value
    return three_colors
  })
  setKustomModal(false)
}



    let changeTokenID = async (value) => {

      let features = await metakays.methods.features(value).call()
/* global BigInt */
      let data1 = BigInt(String(features.data1)).toString(2)
      let data2 = BigInt(String(features.data2)).toString(2)
      let total_data = data1.padStart(256, '0') + data2.padStart(256, '0')
      setPeices(total_data.split(''))


      let getFeatures = await metakays.methods.getFeatures(value).call()
      let featureColors1  = getFeatures[2][0]
      let featureColors2 = getFeatures[2][1]
      let featureColors3 = getFeatures[2][2]
      let featureColors4 = getFeatures[2][3]

      let FC1 = BigInt(String(featureColors1)).toString(2)
      let FC2 = BigInt(String(featureColors2)).toString(2)
      let FC3 = BigInt(String(featureColors3)).toString(2)
      let FC4 = BigInt(String(featureColors4)).toString(2)
      let total_FC_data1 = FC1.padStart(256, '0') + FC2.padStart(256, '0')
      let total_FC_data2 = FC3.padStart(256, '0') + FC4.padStart(256, '0')

      setColor1(total_FC_data1.split(''))
      setColor2(total_FC_data2.split(''))


      let featureColorsSelectors1 = getFeatures[3][0]
      let featureColorsSelectors2 = getFeatures[3][1]
      let featureColorsSelectors3 = getFeatures[3][2]
      set_three_colors([featureColorsSelectors1, featureColorsSelectors2, featureColorsSelectors3 ])

      let backgroundColor = await metakays.methods.svgBackgroundColorSelector(value).call()
      setBgColor(backgroundColor)

      setTokenID(parseInt(value))

      setKustomModal(false)
      }



      let resetBlank = async (value) => {

        let data1 = BigInt("0").toString(2)
        let data2 = BigInt("0").toString(2)
        let total_data = data1.padStart(256, '0') + data2.padStart(256, '0')
        setPeices(total_data.split(''))


        let FC1 = BigInt("0").toString(2)
        let FC2 = BigInt("0").toString(2)
        let FC3 = BigInt("0").toString(2)
        let FC4 = BigInt("0").toString(2)
        let total_FC_data1 = FC1.padStart(256, '0') + FC2.padStart(256, '0')
        let total_FC_data2 = FC3.padStart(256, '0') + FC4.padStart(256, '0')

        setColor1(total_FC_data1.split(''))
        setColor2(total_FC_data2.split(''))

        set_three_colors([0,0,0])


        setKustomModal(false)
        }


        let resetFull = async (value) => {

          let data1 = BigInt("1").toString(2)
          let data2 = BigInt("1").toString(2)
          let total_data = data1.padStart(256, '1') + data2.padStart(256, '1')
          setPeices(total_data.split(''))


          let FC1 = BigInt("0").toString(2)
          let FC2 = BigInt("0").toString(2)
          let FC3 = BigInt("0").toString(2)
          let FC4 = BigInt("0").toString(2)
          let total_FC_data1 = FC1.padStart(256, '0') + FC2.padStart(256, '0')
          let total_FC_data2 = FC3.padStart(256, '0') + FC4.padStart(256, '0')

          setColor1(total_FC_data1.split(''))
          setColor2(total_FC_data2.split(''))

          set_three_colors([0,0,0])


          setKustomModal(false)
          }


  const backOut = () => {
    setKustomModal(false)
  }

  return (
    <div>
        { (customChooser === 0) &&
          <div className="customIndividuals">
            <div>LAYER EIGHT</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(56)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(57)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(58)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(59)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(60)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(61)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(62)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(63)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(48)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(49)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(50)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(51)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(52)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(53)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(54)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(55)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(40)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(41)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(42)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(43)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(44)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(45)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(46)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(47)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(32)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(33)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(34)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(35)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(36)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(37)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(38)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(39)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(24)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(25)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(26)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(27)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(28)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(29)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(30)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(31)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(16)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(17)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(18)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(19)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(20)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(21)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(22)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(23)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(8)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(9)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(10)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(11)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(12)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(13)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(14)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(15)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(0)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(1)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(2)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(3)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(4)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(5)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(6)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(7)}></img>
          </div>
        }



        { (customChooser === 2) &&
          <div className="customIndividuals">
          <div>LAYER EIGHT COLOR #3</div>
          <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(56)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(57)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(58)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(59)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(60)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(61)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(62)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(63)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(48)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(49)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(50)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(51)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(52)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(53)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(54)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(55)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(40)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(41)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(42)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(43)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(44)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(45)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(46)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(47)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(32)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(33)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(34)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(35)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(36)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(37)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(38)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(39)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(24)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(25)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(26)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(27)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(28)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(29)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(30)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(31)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(16)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(17)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(18)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(19)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(20)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(21)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(22)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(23)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(8)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(9)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(10)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(11)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(12)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(13)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(14)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(15)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(0)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(1)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(2)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(3)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(4)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(5)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(6)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(7)}></img>
        </div>
        }

        { (customChooser === 1) &&
          <div className="customIndividuals">
            <div>LAYER EIGHT COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(56)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(57)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(58)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(59)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(60)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(61)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(62)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(63)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(48)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(49)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(50)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(51)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(52)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(53)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(54)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(55)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(40)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(41)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(42)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(43)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(44)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(45)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(46)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(47)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(32)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(33)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(34)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(35)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(36)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(37)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(38)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(39)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(24)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(25)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(26)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(27)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(28)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(29)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(30)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(31)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(16)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(17)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(18)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(19)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(20)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(21)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(22)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(23)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(8)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(9)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(10)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(11)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(12)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(13)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(14)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(15)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(0)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(1)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(2)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(3)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(4)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(5)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(6)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(7)}></img>
        </div>
        }





        { (customChooser === 3) &&
          <div className="customIndividuals">
            <div>LAYER SEVEN</div>
             <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
             <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(71)}></img>
        </div>
        }


        { (customChooser === 5) &&
          <div className="customIndividuals">
            <div>LAYER SEVEN COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(71)}></img>
        </div>
        }


        { (customChooser === 4) &&
          <div className="customIndividuals">
            <div>LAYER SEVEN COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(71)}></img>
        </div>
        }

        { (customChooser === 6) &&
          <div className="customIndividuals">
            <div>LAYER SIX</div>
             <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
             <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(184)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(185)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(186)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(187)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(188)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(189)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(190)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(191)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(176)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(177)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(178)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(179)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(180)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(181)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(182)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(183)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(168)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(169)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(170)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(171)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(172)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(173)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(174)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(175)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(160)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(161)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(162)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(163)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(164)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(165)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(166)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(167)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(152)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(153)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(154)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(155)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(156)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(157)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(158)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(159)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(144)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(145)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(146)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(147)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(148)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(149)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(150)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(151)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(137)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(138)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(139)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(140)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(141)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(142)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(143)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(135)}></img>
        </div>
        }

        { (customChooser === 8) &&
          <div className="customIndividuals">
            <div>LAYER SIX COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(184)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(185)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(186)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(187)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(188)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(189)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(190)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(191)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(176)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(177)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(178)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(179)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(180)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(181)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(182)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(183)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(168)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(169)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(170)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(171)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(172)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(173)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(174)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(175)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(160)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(161)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(162)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(163)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(164)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(165)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(166)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(167)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(152)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(153)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(154)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(155)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(156)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(157)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(158)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(159)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(144)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(145)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(146)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(147)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(148)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(149)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(150)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(151)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(137)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(138)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(139)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(140)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(141)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(142)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(143)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(135)}></img>
        </div>
        }


        { (customChooser === 7) &&
          <div className="customIndividuals">
            <div>LAYER SIX COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(184)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(185)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(186)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(187)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(188)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(189)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(190)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(191)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(176)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(177)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(178)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(179)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(180)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(181)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(182)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(183)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(168)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(169)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(170)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(171)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(172)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(173)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(174)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(175)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(160)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(161)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(162)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(163)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(164)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(165)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(166)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(167)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(152)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(153)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(154)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(155)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(156)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(157)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(158)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(159)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(144)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(145)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(146)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(147)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(148)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(149)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(150)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(151)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(137)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(138)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(139)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(140)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(141)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(142)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(143)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(135)}></img>
        </div>
        }


        { (customChooser === 9) &&
          <div className="customIndividuals">
          <div>LAYER FIVE</div>
          <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(248)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(249)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(250)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(251)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(252)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(253)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(254)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(255)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(240)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(241)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(242)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(243)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(244)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(245)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(246)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(247)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(232)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(233)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(234)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(235)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(236)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(237)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(238)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(239)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(224)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(225)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(226)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(227)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(228)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(229)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(230)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(231)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(216)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(217)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(218)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(219)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(220)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(221)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(222)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(223)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(208)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(209)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(210)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(211)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(212)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(213)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(214)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(215)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(200)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(201)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(202)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(203)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(204)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(205)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(206)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(207)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(192)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(193)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(194)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(195)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(196)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(197)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(198)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(199)}></img>
        </div>
        }


        { (customChooser === 11) &&
          <div className="customIndividuals">
          <div>LAYER FIVE COLOR #3</div>
          <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(248)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(249)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(250)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(251)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(252)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(253)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(254)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(255)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(240)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(241)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(242)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(243)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(244)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(245)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(246)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(247)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(232)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(233)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(234)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(235)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(236)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(237)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(238)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(239)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(224)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(225)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(226)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(227)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(228)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(229)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(230)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(231)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(216)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(217)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(218)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(219)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(220)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(221)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(222)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(223)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(208)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(209)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(210)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(211)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(212)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(213)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(214)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(215)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(200)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(201)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(202)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(203)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(204)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(205)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(206)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(207)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(192)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(193)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(194)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(195)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(196)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(197)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(198)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(199)}></img>
        </div>
        }

        { (customChooser === 10) &&
          <div className="customIndividuals">
          <div>LAYER FIVE COLOR #2</div>
          <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(248)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(249)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(250)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(251)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(252)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(253)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(254)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(255)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(240)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(241)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(242)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(243)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(244)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(245)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(246)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(247)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(232)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(233)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(234)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(235)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(236)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(237)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(238)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(239)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(224)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(225)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(226)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(227)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(228)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(229)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(230)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(231)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(216)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(217)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(218)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(219)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(220)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(221)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(222)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(223)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(208)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(209)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(210)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(211)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(212)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(213)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(214)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(215)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(200)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(201)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(202)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(203)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(204)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(205)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(206)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(207)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(192)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(193)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(194)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(195)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(196)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(197)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(198)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(199)}></img>

        </div>
        }

        { (customChooser === 12) &&
          <div className="customIndividuals">
            <div>LAYER FOUR</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(312)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(313)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(314)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(315)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(316)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(317)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(318)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(319)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(304)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(305)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(306)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(307)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(308)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(309)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(310)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(311)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(296)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(297)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(298)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(299)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(300)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(301)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(302)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(303)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(288)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(289)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(290)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(291)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(292)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(293)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(294)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(295)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(280)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(281)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(282)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(283)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(284)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(285)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(286)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(287)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(272)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(273)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(274)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(275)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(276)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(277)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(278)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(279)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(264)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(265)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(266)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(267)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(268)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(269)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(270)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(271)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(256)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(257)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(258)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(259)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(260)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(261)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(262)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(263)}></img>

        </div>
        }

        { (customChooser === 14) &&
          <div className="customIndividuals">
            <div>LAYER FOUR COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(312)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(313)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(314)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(315)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(316)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(317)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(318)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(319)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(304)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(305)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(306)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(307)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(308)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(309)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(310)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(311)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(296)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(297)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(298)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(299)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(300)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(301)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(302)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(303)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(288)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(289)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(290)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(291)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(292)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(293)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(294)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(295)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(280)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(281)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(282)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(283)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(284)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(285)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(286)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(287)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(272)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(273)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(274)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(275)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(276)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(277)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(278)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(279)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(264)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(265)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(266)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(267)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(268)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(269)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(270)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(271)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(256)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(257)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(258)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(259)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(260)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(261)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(262)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(263)}></img>
        </div>
        }


        { (customChooser === 13) &&
          <div className="customIndividuals">
            <div>LAYER FOUR COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(312)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(313)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(314)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(315)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(316)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(317)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(318)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(319)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(304)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(305)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(306)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(307)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(308)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(309)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(310)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(311)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(296)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(297)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(298)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(299)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(300)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(301)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(302)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(303)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(288)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(289)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(290)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(291)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(292)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(293)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(294)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(295)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(280)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(281)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(282)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(283)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(284)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(285)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(286)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(287)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(272)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(273)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(274)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(275)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(276)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(277)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(278)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(279)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(264)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(265)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(266)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(267)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(268)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(269)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(270)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(271)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(256)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(257)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(258)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(259)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(260)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(261)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(262)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(263)}></img>
        </div>
        }


        { (customChooser === 15) &&
          <div className="customIndividuals">
          <div>LAYER THREE</div>
          <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(376)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(377)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(378)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(379)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(380)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(381)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(382)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(383)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(368)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(369)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(370)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(371)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(372)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(373)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(374)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(375)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(360)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(361)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(362)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(363)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(364)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(365)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(366)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(367)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(352)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(353)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(354)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(355)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(356)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(357)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(358)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(359)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(344)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(345)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(346)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(347)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(348)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(349)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(350)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(351)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(336)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(337)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(338)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(339)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(340)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(341)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(342)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(343)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(328)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(329)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(330)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(331)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(332)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(333)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(334)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(335)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(320)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(321)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(322)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(323)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(324)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(325)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(326)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(327)}></img>
        </div>
        }


        { (customChooser === 17) &&
          <div className="customIndividuals">
            <div>LAYER THREE COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(376)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(377)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(378)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(379)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(380)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(381)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(382)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(383)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(368)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(369)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(370)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(371)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(372)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(373)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(374)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(375)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(360)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(361)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(362)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(363)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(364)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(365)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(366)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(367)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(352)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(353)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(354)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(355)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(356)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(357)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(358)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(359)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(344)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(345)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(346)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(347)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(348)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(349)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(350)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(351)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(336)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(337)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(338)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(339)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(340)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(341)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(342)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(343)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(328)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(329)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(330)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(331)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(332)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(333)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(334)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(335)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(320)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(321)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(322)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(323)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(324)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(325)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(326)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(327)}></img>

        </div>
        }

        { (customChooser === 16) &&
          <div className="customIndividuals">
            <div>LAYER THREE COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(376)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(377)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(378)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(379)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(380)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(381)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(382)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(383)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(368)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(369)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(370)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(371)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(372)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(373)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(374)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(375)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(360)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(361)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(362)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(363)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(364)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(365)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(366)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(367)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(352)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(353)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(354)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(355)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(356)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(357)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(358)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(359)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(344)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(345)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(346)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(347)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(348)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(349)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(350)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(351)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(336)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(337)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(338)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(339)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(340)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(341)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(342)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(343)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(328)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(329)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(330)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(331)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(332)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(333)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(334)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(335)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(320)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(321)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(322)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(323)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(324)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(325)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(326)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(327)}></img>

        </div>
        }

        { (customChooser === 18) &&
          <div className="customIndividuals">
          <div>LAYER TWO</div>
          <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(440)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(441)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(442)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(443)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(444)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(445)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(446)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(447)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(432)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(433)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(434)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(435)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(436)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(437)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(438)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(439)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(424)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(425)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(426)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(427)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(428)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(429)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(430)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(431)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(416)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(417)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(418)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(419)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(420)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(421)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(422)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(423)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(408)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(409)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(410)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(411)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(412)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(413)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(414)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(415)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(400)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(401)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(402)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(403)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(404)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(405)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(406)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(407)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(392)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(393)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(394)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(395)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(396)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(397)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(398)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(399)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(384)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(385)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(386)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(387)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(388)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(389)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(390)}></img>
          <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(391)}></img>

        </div>
        }

        { (customChooser === 20) &&
          <div className="customIndividuals">
            <div>LAYER TWO COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(440)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(441)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(442)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(443)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(444)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(445)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(446)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(447)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(432)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(433)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(434)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(435)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(436)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(437)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(438)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(439)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(424)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(425)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(426)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(427)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(428)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(429)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(430)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(431)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(416)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(417)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(418)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(419)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(420)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(421)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(422)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(423)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(408)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(409)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(410)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(411)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(412)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(413)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(414)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(415)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(400)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(401)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(402)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(403)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(404)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(405)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(406)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(407)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(392)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(393)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(394)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(395)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(396)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(397)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(398)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(399)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(384)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(385)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(386)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(387)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(388)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(389)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(390)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(391)}></img>
        </div>
        }


        { (customChooser === 19) &&
          <div className="customIndividuals">
            <div>LAYER TWO COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(440)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(441)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(442)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(443)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(444)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(445)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(446)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(447)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(432)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(433)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(434)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(435)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(436)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(437)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(438)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(439)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(424)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(425)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(426)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(427)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(428)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(429)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(430)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(431)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(416)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(417)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(418)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(419)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(420)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(421)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(422)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(423)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(408)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(409)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(410)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(411)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(412)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(413)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(414)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(415)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(400)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(401)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(402)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(403)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(404)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(405)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(406)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(407)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(392)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(393)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(394)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(395)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(396)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(397)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(398)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(399)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(384)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(385)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(386)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(387)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(388)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(389)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(390)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(391)}></img>
        </div>
        }

        { (customChooser === 21) &&
          <div className="customIndividuals">
            <div>LAYER ONE</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(504)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(505)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(506)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(507)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(508)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(509)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(510)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(511)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(496)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(497)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(498)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(499)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(500)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(501)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(502)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(503)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(488)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(489)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(490)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(491)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(492)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(493)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(494)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(495)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(480)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(481)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(482)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(483)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(484)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(485)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(486)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(487)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(472)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(473)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(474)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(475)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(476)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(477)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(478)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(479)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(464)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(465)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(466)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(467)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(468)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(469)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(470)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(471)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(456)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(457)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(458)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(459)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(460)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(461)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(462)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(463)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(448)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(449)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(450)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(451)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(452)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(453)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(454)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeLAYER(455)}></img>
        </div>
        }

        { (customChooser === 23) &&
          <div className="customIndividuals">
            <div>LAYER ONE COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(504)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(505)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(506)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(507)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(508)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(509)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(510)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(511)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(496)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(497)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(498)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(499)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(500)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(501)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(502)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(503)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(488)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(489)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(490)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(491)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(492)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(493)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(494)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(495)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(480)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(481)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(482)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(483)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(484)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(485)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(486)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(487)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(472)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(473)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(474)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(475)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(476)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(477)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(478)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(479)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(464)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(465)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(466)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(467)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(468)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(469)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(470)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(471)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(456)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(457)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(458)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(459)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(460)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(461)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(462)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(463)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(448)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(449)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(450)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(451)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(452)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(453)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(454)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR1(455)}></img>
        </div>
        }

        { (customChooser === 22) &&
          <div className="customIndividuals">
            <div>LAYER ONE COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(504)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(505)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(506)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(507)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(508)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(509)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(510)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(511)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(496)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(497)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(498)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(499)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(500)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(501)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(502)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(503)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(488)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(489)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(490)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(491)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(492)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(493)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(494)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(495)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(480)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(481)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(482)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(483)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(484)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(485)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(486)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(487)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(472)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(473)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(474)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(475)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(476)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(477)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(478)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(479)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(464)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(465)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(466)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(467)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(468)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(469)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(470)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(471)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(456)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(457)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(458)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(459)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(460)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(461)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(462)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(463)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(448)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(449)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(450)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(451)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(452)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(453)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(454)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => changeCOLOR2(455)}></img>
        </div>
        }

      { (customChooser === 24) &&
      <div className="customIndividuals">
      <div>LOAD</div>
      <div><input className="digiTextBox" type="text" placeholder="ENTER TOKEN ID." onChange={e => setTokenID(e.target.value)} /></div>
      <div className="itemDropYourMETAKAYS" onClick={() => changeTokenID(parseInt(tokenID))}>ENTER</div>
      <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
      </div>
        }

      { (customChooser === 25) &&
          <div className="customIndividuals">
            <div>SELECT COLOR #3</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/0.png" alt=''onClick={() => SetDefaultColor(0)}></img>
            <img className='customImages'src="/images/metakays_parts/base/1.png" alt=''onClick={() => SetDefaultColor(1)}></img>
            <img className='customImages'src="/images/metakays_parts/base/2.png" alt=''onClick={() => SetDefaultColor(2)}></img>
            <img className='customImages'src="/images/metakays_parts/base/3.png" alt=''onClick={() => SetDefaultColor(3)}></img>
            <img className='customImages'src="/images/metakays_parts/base/4.png" alt=''onClick={() => SetDefaultColor(4)}></img>
            <img className='customImages'src="/images/metakays_parts/base/5.png" alt=''onClick={() => SetDefaultColor(5)}></img>
            <img className='customImages'src="/images/metakays_parts/base/6.png" alt=''onClick={() => SetDefaultColor(6)}></img>
            <img className='customImages'src="/images/metakays_parts/base/7.png" alt=''onClick={() => SetDefaultColor(7)}></img>
            <img className='customImages'src="/images/metakays_parts/base/8.png" alt=''onClick={() => SetDefaultColor(8)}></img>
            <img className='customImages'src="/images/metakays_parts/base/9.png" alt=''onClick={() => SetDefaultColor(9)}></img>
            <img className='customImages'src="/images/metakays_parts/base/10.png" alt=''onClick={() => SetDefaultColor(10)}></img>
            <img className='customImages'src="/images/metakays_parts/base/11.png" alt=''onClick={() => SetDefaultColor(11)}></img>
            <img className='customImages'src="/images/metakays_parts/base/12.png" alt=''onClick={() => SetDefaultColor(12)}></img>
            <img className='customImages'src="/images/metakays_parts/base/13.png" alt=''onClick={() => SetDefaultColor(13)}></img>
            <img className='customImages'src="/images/metakays_parts/base/14.png" alt=''onClick={() => SetDefaultColor(14)}></img>
            <img className='customImages'src="/images/metakays_parts/base/15.png" alt=''onClick={() => SetDefaultColor(15)}></img>
            <img className='customImages'src="/images/metakays_parts/base/16.png" alt=''onClick={() => SetDefaultColor(16)}></img>
            <img className='customImages'src="/images/metakays_parts/base/17.png" alt=''onClick={() => SetDefaultColor(17)}></img>
            <img className='customImages'src="/images/metakays_parts/base/18.png" alt=''onClick={() => SetDefaultColor(18)}></img>
            <img className='customImages'src="/images/metakays_parts/base/19.png" alt=''onClick={() => SetDefaultColor(19)}></img>
            <img className='customImages'src="/images/metakays_parts/base/20.png" alt=''onClick={() => SetDefaultColor(20)}></img>
            <img className='customImages'src="/images/metakays_parts/base/21.png" alt=''onClick={() => SetDefaultColor(21)}></img>
            <img className='customImages'src="/images/metakays_parts/base/22.png" alt=''onClick={() => SetDefaultColor(22)}></img>
            <img className='customImages'src="/images/metakays_parts/base/23.png" alt=''onClick={() => SetDefaultColor(23)}></img>
            <img className='customImages'src="/images/metakays_parts/base/24.png" alt=''onClick={() => SetDefaultColor(24)}></img>
            <img className='customImages'src="/images/metakays_parts/base/25.png" alt=''onClick={() => SetDefaultColor(25)}></img>
            <img className='customImages'src="/images/metakays_parts/base/26.png" alt=''onClick={() => SetDefaultColor(26)}></img>
            <img className='customImages'src="/images/metakays_parts/base/27.png" alt=''onClick={() => SetDefaultColor(27)}></img>
            <img className='customImages'src="/images/metakays_parts/base/28.png" alt=''onClick={() => SetDefaultColor(28)}></img>
            <img className='customImages'src="/images/metakays_parts/base/29.png" alt=''onClick={() => SetDefaultColor(29)}></img>
            <img className='customImages'src="/images/metakays_parts/base/30.png" alt=''onClick={() => SetDefaultColor(30)}></img>
            <img className='customImages'src="/images/metakays_parts/base/31.png" alt=''onClick={() => SetDefaultColor(31)}></img>
            <img className='customImages'src="/images/metakays_parts/base/32.png" alt=''onClick={() => SetDefaultColor(32)}></img>
            <img className='customImages'src="/images/metakays_parts/base/33.png" alt=''onClick={() => SetDefaultColor(33)}></img>
            <img className='customImages'src="/images/metakays_parts/base/34.png" alt=''onClick={() => SetDefaultColor(34)}></img>
            <img className='customImages'src="/images/metakays_parts/base/35.png" alt=''onClick={() => SetDefaultColor(35)}></img>
            <img className='customImages'src="/images/metakays_parts/base/36.png" alt=''onClick={() => SetDefaultColor(36)}></img>
            <img className='customImages'src="/images/metakays_parts/base/37.png" alt=''onClick={() => SetDefaultColor(37)}></img>
            <img className='customImages'src="/images/metakays_parts/base/38.png" alt=''onClick={() => SetDefaultColor(38)}></img>
            <img className='customImages'src="/images/metakays_parts/base/39.png" alt=''onClick={() => SetDefaultColor(39)}></img>
            <img className='customImages'src="/images/metakays_parts/base/40.png" alt=''onClick={() => SetDefaultColor(40)}></img>
            <img className='customImages'src="/images/metakays_parts/base/41.png" alt=''onClick={() => SetDefaultColor(41)}></img>
            <img className='customImages'src="/images/metakays_parts/base/42.png" alt=''onClick={() => SetDefaultColor(42)}></img>
            <img className='customImages'src="/images/metakays_parts/base/43.png" alt=''onClick={() => SetDefaultColor(43)}></img>
            <img className='customImages'src="/images/metakays_parts/base/44.png" alt=''onClick={() => SetDefaultColor(44)}></img>
            <img className='customImages'src="/images/metakays_parts/base/45.png" alt=''onClick={() => SetDefaultColor(45)}></img>
            <img className='customImages'src="/images/metakays_parts/base/46.png" alt=''onClick={() => SetDefaultColor(46)}></img>
            <img className='customImages'src="/images/metakays_parts/base/47.png" alt=''onClick={() => SetDefaultColor(47)}></img>
            <img className='customImages'src="/images/metakays_parts/base/48.png" alt=''onClick={() => SetDefaultColor(48)}></img>
            <img className='customImages'src="/images/metakays_parts/base/49.png" alt=''onClick={() => SetDefaultColor(49)}></img>
            <img className='customImages'src="/images/metakays_parts/base/50.png" alt=''onClick={() => SetDefaultColor(50)}></img>
            <img className='customImages'src="/images/metakays_parts/base/51.png" alt=''onClick={() => SetDefaultColor(51)}></img>
            <img className='customImages'src="/images/metakays_parts/base/52.png" alt=''onClick={() => SetDefaultColor(52)}></img>
            <img className='customImages'src="/images/metakays_parts/base/53.png" alt=''onClick={() => SetDefaultColor(53)}></img>
            <img className='customImages'src="/images/metakays_parts/base/54.png" alt=''onClick={() => SetDefaultColor(54)}></img>
            <img className='customImages'src="/images/metakays_parts/base/55.png" alt=''onClick={() => SetDefaultColor(55)}></img>
            <img className='customImages'src="/images/metakays_parts/base/56.png" alt=''onClick={() => SetDefaultColor(56)}></img>
            <img className='customImages'src="/images/metakays_parts/base/57.png" alt=''onClick={() => SetDefaultColor(57)}></img>
            <img className='customImages'src="/images/metakays_parts/base/58.png" alt=''onClick={() => SetDefaultColor(58)}></img>
            <img className='customImages'src="/images/metakays_parts/base/59.png" alt=''onClick={() => SetDefaultColor(59)}></img>
            <img className='customImages'src="/images/metakays_parts/base/60.png" alt=''onClick={() => SetDefaultColor(60)}></img>
            <img className='customImages'src="/images/metakays_parts/base/61.png" alt=''onClick={() => SetDefaultColor(61)}></img>
            <img className='customImages'src="/images/metakays_parts/base/62.png" alt=''onClick={() => SetDefaultColor(62)}></img>
            <img className='customImages'src="/images/metakays_parts/base/63.png" alt=''onClick={() => SetDefaultColor(63)}></img>
            <img className='customImages'src="/images/metakays_parts/base/64.png" alt=''onClick={() => SetDefaultColor(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/65.png" alt=''onClick={() => SetDefaultColor(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/66.png" alt=''onClick={() => SetDefaultColor(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/67.png" alt=''onClick={() => SetDefaultColor(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/68.png" alt=''onClick={() => SetDefaultColor(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/69.png" alt=''onClick={() => SetDefaultColor(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/70.png" alt=''onClick={() => SetDefaultColor(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/71.png" alt=''onClick={() => SetDefaultColor(71)}></img>
            <img className='customImages'src="/images/metakays_parts/base/72.png" alt=''onClick={() => SetDefaultColor(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/73.png" alt=''onClick={() => SetDefaultColor(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/74.png" alt=''onClick={() => SetDefaultColor(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/75.png" alt=''onClick={() => SetDefaultColor(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/76.png" alt=''onClick={() => SetDefaultColor(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/77.png" alt=''onClick={() => SetDefaultColor(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/78.png" alt=''onClick={() => SetDefaultColor(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/79.png" alt=''onClick={() => SetDefaultColor(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/80.png" alt=''onClick={() => SetDefaultColor(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/81.png" alt=''onClick={() => SetDefaultColor(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/82.png" alt=''onClick={() => SetDefaultColor(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/83.png" alt=''onClick={() => SetDefaultColor(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/84.png" alt=''onClick={() => SetDefaultColor(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/85.png" alt=''onClick={() => SetDefaultColor(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/86.png" alt=''onClick={() => SetDefaultColor(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/87.png" alt=''onClick={() => SetDefaultColor(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/88.png" alt=''onClick={() => SetDefaultColor(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/89.png" alt=''onClick={() => SetDefaultColor(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/90.png" alt=''onClick={() => SetDefaultColor(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/91.png" alt=''onClick={() => SetDefaultColor(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/92.png" alt=''onClick={() => SetDefaultColor(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/93.png" alt=''onClick={() => SetDefaultColor(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/94.png" alt=''onClick={() => SetDefaultColor(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/95.png" alt=''onClick={() => SetDefaultColor(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/96.png" alt=''onClick={() => SetDefaultColor(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/97.png" alt=''onClick={() => SetDefaultColor(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/98.png" alt=''onClick={() => SetDefaultColor(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/99.png" alt=''onClick={() => SetDefaultColor(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/100.png" alt=''onClick={() => SetDefaultColor(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/101.png" alt=''onClick={() => SetDefaultColor(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/102.png" alt=''onClick={() => SetDefaultColor(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/103.png" alt=''onClick={() => SetDefaultColor(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/104.png" alt=''onClick={() => SetDefaultColor(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/105.png" alt=''onClick={() => SetDefaultColor(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/106.png" alt=''onClick={() => SetDefaultColor(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/107.png" alt=''onClick={() => SetDefaultColor(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/108.png" alt=''onClick={() => SetDefaultColor(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/109.png" alt=''onClick={() => SetDefaultColor(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/110.png" alt=''onClick={() => SetDefaultColor(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/111.png" alt=''onClick={() => SetDefaultColor(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/112.png" alt=''onClick={() => SetDefaultColor(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/113.png" alt=''onClick={() => SetDefaultColor(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/114.png" alt=''onClick={() => SetDefaultColor(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/115.png" alt=''onClick={() => SetDefaultColor(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/116.png" alt=''onClick={() => SetDefaultColor(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/117.png" alt=''onClick={() => SetDefaultColor(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/118.png" alt=''onClick={() => SetDefaultColor(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/119.png" alt=''onClick={() => SetDefaultColor(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/120.png" alt=''onClick={() => SetDefaultColor(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/121.png" alt=''onClick={() => SetDefaultColor(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/122.png" alt=''onClick={() => SetDefaultColor(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/123.png" alt=''onClick={() => SetDefaultColor(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/124.png" alt=''onClick={() => SetDefaultColor(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/125.png" alt=''onClick={() => SetDefaultColor(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/126.png" alt=''onClick={() => SetDefaultColor(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/127.png" alt=''onClick={() => SetDefaultColor(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/128.png" alt=''onClick={() => SetDefaultColor(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/129.png" alt=''onClick={() => SetDefaultColor(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => SetDefaultColor(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/131.png" alt=''onClick={() => SetDefaultColor(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/132.png" alt=''onClick={() => SetDefaultColor(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/133.png" alt=''onClick={() => SetDefaultColor(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/134.png" alt=''onClick={() => SetDefaultColor(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/135.png" alt=''onClick={() => SetDefaultColor(135)}></img>
            <img className='customImages'src="/images/metakays_parts/base/136.png" alt=''onClick={() => SetDefaultColor(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/137.png" alt=''onClick={() => SetDefaultColor(137)}></img>
        </div>
        }


      { (customChooser === 26) &&
          <div className="customIndividuals">
            <div>SELECT COLOR #2</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/0.png" alt=''onClick={() => SetColor1(0)}></img>
            <img className='customImages'src="/images/metakays_parts/base/1.png" alt=''onClick={() => SetColor1(1)}></img>
            <img className='customImages'src="/images/metakays_parts/base/2.png" alt=''onClick={() => SetColor1(2)}></img>
            <img className='customImages'src="/images/metakays_parts/base/3.png" alt=''onClick={() => SetColor1(3)}></img>
            <img className='customImages'src="/images/metakays_parts/base/4.png" alt=''onClick={() => SetColor1(4)}></img>
            <img className='customImages'src="/images/metakays_parts/base/5.png" alt=''onClick={() => SetColor1(5)}></img>
            <img className='customImages'src="/images/metakays_parts/base/6.png" alt=''onClick={() => SetColor1(6)}></img>
            <img className='customImages'src="/images/metakays_parts/base/7.png" alt=''onClick={() => SetColor1(7)}></img>
            <img className='customImages'src="/images/metakays_parts/base/8.png" alt=''onClick={() => SetColor1(8)}></img>
            <img className='customImages'src="/images/metakays_parts/base/9.png" alt=''onClick={() => SetColor1(9)}></img>
            <img className='customImages'src="/images/metakays_parts/base/10.png" alt=''onClick={() => SetColor1(10)}></img>
            <img className='customImages'src="/images/metakays_parts/base/11.png" alt=''onClick={() => SetColor1(11)}></img>
            <img className='customImages'src="/images/metakays_parts/base/12.png" alt=''onClick={() => SetColor1(12)}></img>
            <img className='customImages'src="/images/metakays_parts/base/13.png" alt=''onClick={() => SetColor1(13)}></img>
            <img className='customImages'src="/images/metakays_parts/base/14.png" alt=''onClick={() => SetColor1(14)}></img>
            <img className='customImages'src="/images/metakays_parts/base/15.png" alt=''onClick={() => SetColor1(15)}></img>
            <img className='customImages'src="/images/metakays_parts/base/16.png" alt=''onClick={() => SetColor1(16)}></img>
            <img className='customImages'src="/images/metakays_parts/base/17.png" alt=''onClick={() => SetColor1(17)}></img>
            <img className='customImages'src="/images/metakays_parts/base/18.png" alt=''onClick={() => SetColor1(18)}></img>
            <img className='customImages'src="/images/metakays_parts/base/19.png" alt=''onClick={() => SetColor1(19)}></img>
            <img className='customImages'src="/images/metakays_parts/base/20.png" alt=''onClick={() => SetColor1(20)}></img>
            <img className='customImages'src="/images/metakays_parts/base/21.png" alt=''onClick={() => SetColor1(21)}></img>
            <img className='customImages'src="/images/metakays_parts/base/22.png" alt=''onClick={() => SetColor1(22)}></img>
            <img className='customImages'src="/images/metakays_parts/base/23.png" alt=''onClick={() => SetColor1(23)}></img>
            <img className='customImages'src="/images/metakays_parts/base/24.png" alt=''onClick={() => SetColor1(24)}></img>
            <img className='customImages'src="/images/metakays_parts/base/25.png" alt=''onClick={() => SetColor1(25)}></img>
            <img className='customImages'src="/images/metakays_parts/base/26.png" alt=''onClick={() => SetColor1(26)}></img>
            <img className='customImages'src="/images/metakays_parts/base/27.png" alt=''onClick={() => SetColor1(27)}></img>
            <img className='customImages'src="/images/metakays_parts/base/28.png" alt=''onClick={() => SetColor1(28)}></img>
            <img className='customImages'src="/images/metakays_parts/base/29.png" alt=''onClick={() => SetColor1(29)}></img>
            <img className='customImages'src="/images/metakays_parts/base/30.png" alt=''onClick={() => SetColor1(30)}></img>
            <img className='customImages'src="/images/metakays_parts/base/31.png" alt=''onClick={() => SetColor1(31)}></img>
            <img className='customImages'src="/images/metakays_parts/base/32.png" alt=''onClick={() => SetColor1(32)}></img>
            <img className='customImages'src="/images/metakays_parts/base/33.png" alt=''onClick={() => SetColor1(33)}></img>
            <img className='customImages'src="/images/metakays_parts/base/34.png" alt=''onClick={() => SetColor1(34)}></img>
            <img className='customImages'src="/images/metakays_parts/base/35.png" alt=''onClick={() => SetColor1(35)}></img>
            <img className='customImages'src="/images/metakays_parts/base/36.png" alt=''onClick={() => SetColor1(36)}></img>
            <img className='customImages'src="/images/metakays_parts/base/37.png" alt=''onClick={() => SetColor1(37)}></img>
            <img className='customImages'src="/images/metakays_parts/base/38.png" alt=''onClick={() => SetColor1(38)}></img>
            <img className='customImages'src="/images/metakays_parts/base/39.png" alt=''onClick={() => SetColor1(39)}></img>
            <img className='customImages'src="/images/metakays_parts/base/40.png" alt=''onClick={() => SetColor1(40)}></img>
            <img className='customImages'src="/images/metakays_parts/base/41.png" alt=''onClick={() => SetColor1(41)}></img>
            <img className='customImages'src="/images/metakays_parts/base/42.png" alt=''onClick={() => SetColor1(42)}></img>
            <img className='customImages'src="/images/metakays_parts/base/43.png" alt=''onClick={() => SetColor1(43)}></img>
            <img className='customImages'src="/images/metakays_parts/base/44.png" alt=''onClick={() => SetColor1(44)}></img>
            <img className='customImages'src="/images/metakays_parts/base/45.png" alt=''onClick={() => SetColor1(45)}></img>
            <img className='customImages'src="/images/metakays_parts/base/46.png" alt=''onClick={() => SetColor1(46)}></img>
            <img className='customImages'src="/images/metakays_parts/base/47.png" alt=''onClick={() => SetColor1(47)}></img>
            <img className='customImages'src="/images/metakays_parts/base/48.png" alt=''onClick={() => SetColor1(48)}></img>
            <img className='customImages'src="/images/metakays_parts/base/49.png" alt=''onClick={() => SetColor1(49)}></img>
            <img className='customImages'src="/images/metakays_parts/base/50.png" alt=''onClick={() => SetColor1(50)}></img>
            <img className='customImages'src="/images/metakays_parts/base/51.png" alt=''onClick={() => SetColor1(51)}></img>
            <img className='customImages'src="/images/metakays_parts/base/52.png" alt=''onClick={() => SetColor1(52)}></img>
            <img className='customImages'src="/images/metakays_parts/base/53.png" alt=''onClick={() => SetColor1(53)}></img>
            <img className='customImages'src="/images/metakays_parts/base/54.png" alt=''onClick={() => SetColor1(54)}></img>
            <img className='customImages'src="/images/metakays_parts/base/55.png" alt=''onClick={() => SetColor1(55)}></img>
            <img className='customImages'src="/images/metakays_parts/base/56.png" alt=''onClick={() => SetColor1(56)}></img>
            <img className='customImages'src="/images/metakays_parts/base/57.png" alt=''onClick={() => SetColor1(57)}></img>
            <img className='customImages'src="/images/metakays_parts/base/58.png" alt=''onClick={() => SetColor1(58)}></img>
            <img className='customImages'src="/images/metakays_parts/base/59.png" alt=''onClick={() => SetColor1(59)}></img>
            <img className='customImages'src="/images/metakays_parts/base/60.png" alt=''onClick={() => SetColor1(60)}></img>
            <img className='customImages'src="/images/metakays_parts/base/61.png" alt=''onClick={() => SetColor1(61)}></img>
            <img className='customImages'src="/images/metakays_parts/base/62.png" alt=''onClick={() => SetColor1(62)}></img>
            <img className='customImages'src="/images/metakays_parts/base/63.png" alt=''onClick={() => SetColor1(63)}></img>
            <img className='customImages'src="/images/metakays_parts/base/64.png" alt=''onClick={() => SetColor1(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/65.png" alt=''onClick={() => SetColor1(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/66.png" alt=''onClick={() => SetColor1(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/67.png" alt=''onClick={() => SetColor1(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/68.png" alt=''onClick={() => SetColor1(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/69.png" alt=''onClick={() => SetColor1(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/70.png" alt=''onClick={() => SetColor1(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/71.png" alt=''onClick={() => SetColor1(71)}></img>
            <img className='customImages'src="/images/metakays_parts/base/72.png" alt=''onClick={() => SetColor1(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/73.png" alt=''onClick={() => SetColor1(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/74.png" alt=''onClick={() => SetColor1(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/75.png" alt=''onClick={() => SetColor1(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/76.png" alt=''onClick={() => SetColor1(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/77.png" alt=''onClick={() => SetColor1(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/78.png" alt=''onClick={() => SetColor1(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/79.png" alt=''onClick={() => SetColor1(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/80.png" alt=''onClick={() => SetColor1(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/81.png" alt=''onClick={() => SetColor1(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/82.png" alt=''onClick={() => SetColor1(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/83.png" alt=''onClick={() => SetColor1(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/84.png" alt=''onClick={() => SetColor1(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/85.png" alt=''onClick={() => SetColor1(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/86.png" alt=''onClick={() => SetColor1(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/87.png" alt=''onClick={() => SetColor1(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/88.png" alt=''onClick={() => SetColor1(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/89.png" alt=''onClick={() => SetColor1(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/90.png" alt=''onClick={() => SetColor1(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/91.png" alt=''onClick={() => SetColor1(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/92.png" alt=''onClick={() => SetColor1(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/93.png" alt=''onClick={() => SetColor1(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/94.png" alt=''onClick={() => SetColor1(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/95.png" alt=''onClick={() => SetColor1(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/96.png" alt=''onClick={() => SetColor1(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/97.png" alt=''onClick={() => SetColor1(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/98.png" alt=''onClick={() => SetColor1(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/99.png" alt=''onClick={() => SetColor1(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/100.png" alt=''onClick={() => SetColor1(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/101.png" alt=''onClick={() => SetColor1(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/102.png" alt=''onClick={() => SetColor1(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/103.png" alt=''onClick={() => SetColor1(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/104.png" alt=''onClick={() => SetColor1(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/105.png" alt=''onClick={() => SetColor1(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/106.png" alt=''onClick={() => SetColor1(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/107.png" alt=''onClick={() => SetColor1(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/108.png" alt=''onClick={() => SetColor1(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/109.png" alt=''onClick={() => SetColor1(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/110.png" alt=''onClick={() => SetColor1(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/111.png" alt=''onClick={() => SetColor1(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/112.png" alt=''onClick={() => SetColor1(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/113.png" alt=''onClick={() => SetColor1(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/114.png" alt=''onClick={() => SetColor1(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/115.png" alt=''onClick={() => SetColor1(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/116.png" alt=''onClick={() => SetColor1(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/117.png" alt=''onClick={() => SetColor1(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/118.png" alt=''onClick={() => SetColor1(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/119.png" alt=''onClick={() => SetColor1(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/120.png" alt=''onClick={() => SetColor1(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/121.png" alt=''onClick={() => SetColor1(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/122.png" alt=''onClick={() => SetColor1(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/123.png" alt=''onClick={() => SetColor1(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/124.png" alt=''onClick={() => SetColor1(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/125.png" alt=''onClick={() => SetColor1(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/126.png" alt=''onClick={() => SetColor1(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/127.png" alt=''onClick={() => SetColor1(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/128.png" alt=''onClick={() => SetColor1(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/129.png" alt=''onClick={() => SetColor1(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => SetColor1(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/131.png" alt=''onClick={() => SetColor1(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/132.png" alt=''onClick={() => SetColor1(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/133.png" alt=''onClick={() => SetColor1(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/134.png" alt=''onClick={() => SetColor1(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/135.png" alt=''onClick={() => SetColor1(135)}></img>
            <img className='customImages'src="/images/metakays_parts/base/136.png" alt=''onClick={() => SetColor1(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/137.png" alt=''onClick={() => SetColor1(137)}></img>
        </div>
        }

      { (customChooser === 27) &&
          <div className="customIndividuals">
            <div>SELECT DEFAULT COLOR</div>
            <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
            <img className='customImages'src="/images/metakays_parts/base/0.png" alt=''onClick={() => SetColor2(0)}></img>
            <img className='customImages'src="/images/metakays_parts/base/1.png" alt=''onClick={() => SetColor2(1)}></img>
            <img className='customImages'src="/images/metakays_parts/base/2.png" alt=''onClick={() => SetColor2(2)}></img>
            <img className='customImages'src="/images/metakays_parts/base/3.png" alt=''onClick={() => SetColor2(3)}></img>
            <img className='customImages'src="/images/metakays_parts/base/4.png" alt=''onClick={() => SetColor2(4)}></img>
            <img className='customImages'src="/images/metakays_parts/base/5.png" alt=''onClick={() => SetColor2(5)}></img>
            <img className='customImages'src="/images/metakays_parts/base/6.png" alt=''onClick={() => SetColor2(6)}></img>
            <img className='customImages'src="/images/metakays_parts/base/7.png" alt=''onClick={() => SetColor2(7)}></img>
            <img className='customImages'src="/images/metakays_parts/base/8.png" alt=''onClick={() => SetColor2(8)}></img>
            <img className='customImages'src="/images/metakays_parts/base/9.png" alt=''onClick={() => SetColor2(9)}></img>
            <img className='customImages'src="/images/metakays_parts/base/10.png" alt=''onClick={() => SetColor2(10)}></img>
            <img className='customImages'src="/images/metakays_parts/base/11.png" alt=''onClick={() => SetColor2(11)}></img>
            <img className='customImages'src="/images/metakays_parts/base/12.png" alt=''onClick={() => SetColor2(12)}></img>
            <img className='customImages'src="/images/metakays_parts/base/13.png" alt=''onClick={() => SetColor2(13)}></img>
            <img className='customImages'src="/images/metakays_parts/base/14.png" alt=''onClick={() => SetColor2(14)}></img>
            <img className='customImages'src="/images/metakays_parts/base/15.png" alt=''onClick={() => SetColor2(15)}></img>
            <img className='customImages'src="/images/metakays_parts/base/16.png" alt=''onClick={() => SetColor2(16)}></img>
            <img className='customImages'src="/images/metakays_parts/base/17.png" alt=''onClick={() => SetColor2(17)}></img>
            <img className='customImages'src="/images/metakays_parts/base/18.png" alt=''onClick={() => SetColor2(18)}></img>
            <img className='customImages'src="/images/metakays_parts/base/19.png" alt=''onClick={() => SetColor2(19)}></img>
            <img className='customImages'src="/images/metakays_parts/base/20.png" alt=''onClick={() => SetColor2(20)}></img>
            <img className='customImages'src="/images/metakays_parts/base/21.png" alt=''onClick={() => SetColor2(21)}></img>
            <img className='customImages'src="/images/metakays_parts/base/22.png" alt=''onClick={() => SetColor2(22)}></img>
            <img className='customImages'src="/images/metakays_parts/base/23.png" alt=''onClick={() => SetColor2(23)}></img>
            <img className='customImages'src="/images/metakays_parts/base/24.png" alt=''onClick={() => SetColor2(24)}></img>
            <img className='customImages'src="/images/metakays_parts/base/25.png" alt=''onClick={() => SetColor2(25)}></img>
            <img className='customImages'src="/images/metakays_parts/base/26.png" alt=''onClick={() => SetColor2(26)}></img>
            <img className='customImages'src="/images/metakays_parts/base/27.png" alt=''onClick={() => SetColor2(27)}></img>
            <img className='customImages'src="/images/metakays_parts/base/28.png" alt=''onClick={() => SetColor2(28)}></img>
            <img className='customImages'src="/images/metakays_parts/base/29.png" alt=''onClick={() => SetColor2(29)}></img>
            <img className='customImages'src="/images/metakays_parts/base/30.png" alt=''onClick={() => SetColor2(30)}></img>
            <img className='customImages'src="/images/metakays_parts/base/31.png" alt=''onClick={() => SetColor2(31)}></img>
            <img className='customImages'src="/images/metakays_parts/base/32.png" alt=''onClick={() => SetColor2(32)}></img>
            <img className='customImages'src="/images/metakays_parts/base/33.png" alt=''onClick={() => SetColor2(33)}></img>
            <img className='customImages'src="/images/metakays_parts/base/34.png" alt=''onClick={() => SetColor2(34)}></img>
            <img className='customImages'src="/images/metakays_parts/base/35.png" alt=''onClick={() => SetColor2(35)}></img>
            <img className='customImages'src="/images/metakays_parts/base/36.png" alt=''onClick={() => SetColor2(36)}></img>
            <img className='customImages'src="/images/metakays_parts/base/37.png" alt=''onClick={() => SetColor2(37)}></img>
            <img className='customImages'src="/images/metakays_parts/base/38.png" alt=''onClick={() => SetColor2(38)}></img>
            <img className='customImages'src="/images/metakays_parts/base/39.png" alt=''onClick={() => SetColor2(39)}></img>
            <img className='customImages'src="/images/metakays_parts/base/40.png" alt=''onClick={() => SetColor2(40)}></img>
            <img className='customImages'src="/images/metakays_parts/base/41.png" alt=''onClick={() => SetColor2(41)}></img>
            <img className='customImages'src="/images/metakays_parts/base/42.png" alt=''onClick={() => SetColor2(42)}></img>
            <img className='customImages'src="/images/metakays_parts/base/43.png" alt=''onClick={() => SetColor2(43)}></img>
            <img className='customImages'src="/images/metakays_parts/base/44.png" alt=''onClick={() => SetColor2(44)}></img>
            <img className='customImages'src="/images/metakays_parts/base/45.png" alt=''onClick={() => SetColor2(45)}></img>
            <img className='customImages'src="/images/metakays_parts/base/46.png" alt=''onClick={() => SetColor2(46)}></img>
            <img className='customImages'src="/images/metakays_parts/base/47.png" alt=''onClick={() => SetColor2(47)}></img>
            <img className='customImages'src="/images/metakays_parts/base/48.png" alt=''onClick={() => SetColor2(48)}></img>
            <img className='customImages'src="/images/metakays_parts/base/49.png" alt=''onClick={() => SetColor2(49)}></img>
            <img className='customImages'src="/images/metakays_parts/base/50.png" alt=''onClick={() => SetColor2(50)}></img>
            <img className='customImages'src="/images/metakays_parts/base/51.png" alt=''onClick={() => SetColor2(51)}></img>
            <img className='customImages'src="/images/metakays_parts/base/52.png" alt=''onClick={() => SetColor2(52)}></img>
            <img className='customImages'src="/images/metakays_parts/base/53.png" alt=''onClick={() => SetColor2(53)}></img>
            <img className='customImages'src="/images/metakays_parts/base/54.png" alt=''onClick={() => SetColor2(54)}></img>
            <img className='customImages'src="/images/metakays_parts/base/55.png" alt=''onClick={() => SetColor2(55)}></img>
            <img className='customImages'src="/images/metakays_parts/base/56.png" alt=''onClick={() => SetColor2(56)}></img>
            <img className='customImages'src="/images/metakays_parts/base/57.png" alt=''onClick={() => SetColor2(57)}></img>
            <img className='customImages'src="/images/metakays_parts/base/58.png" alt=''onClick={() => SetColor2(58)}></img>
            <img className='customImages'src="/images/metakays_parts/base/59.png" alt=''onClick={() => SetColor2(59)}></img>
            <img className='customImages'src="/images/metakays_parts/base/60.png" alt=''onClick={() => SetColor2(60)}></img>
            <img className='customImages'src="/images/metakays_parts/base/61.png" alt=''onClick={() => SetColor2(61)}></img>
            <img className='customImages'src="/images/metakays_parts/base/62.png" alt=''onClick={() => SetColor2(62)}></img>
            <img className='customImages'src="/images/metakays_parts/base/63.png" alt=''onClick={() => SetColor2(63)}></img>
            <img className='customImages'src="/images/metakays_parts/base/64.png" alt=''onClick={() => SetColor2(64)}></img>
            <img className='customImages'src="/images/metakays_parts/base/65.png" alt=''onClick={() => SetColor2(65)}></img>
            <img className='customImages'src="/images/metakays_parts/base/66.png" alt=''onClick={() => SetColor2(66)}></img>
            <img className='customImages'src="/images/metakays_parts/base/67.png" alt=''onClick={() => SetColor2(67)}></img>
            <img className='customImages'src="/images/metakays_parts/base/68.png" alt=''onClick={() => SetColor2(68)}></img>
            <img className='customImages'src="/images/metakays_parts/base/69.png" alt=''onClick={() => SetColor2(69)}></img>
            <img className='customImages'src="/images/metakays_parts/base/70.png" alt=''onClick={() => SetColor2(70)}></img>
            <img className='customImages'src="/images/metakays_parts/base/71.png" alt=''onClick={() => SetColor2(71)}></img>
            <img className='customImages'src="/images/metakays_parts/base/72.png" alt=''onClick={() => SetColor2(72)}></img>
            <img className='customImages'src="/images/metakays_parts/base/73.png" alt=''onClick={() => SetColor2(73)}></img>
            <img className='customImages'src="/images/metakays_parts/base/74.png" alt=''onClick={() => SetColor2(74)}></img>
            <img className='customImages'src="/images/metakays_parts/base/75.png" alt=''onClick={() => SetColor2(75)}></img>
            <img className='customImages'src="/images/metakays_parts/base/76.png" alt=''onClick={() => SetColor2(76)}></img>
            <img className='customImages'src="/images/metakays_parts/base/77.png" alt=''onClick={() => SetColor2(77)}></img>
            <img className='customImages'src="/images/metakays_parts/base/78.png" alt=''onClick={() => SetColor2(78)}></img>
            <img className='customImages'src="/images/metakays_parts/base/79.png" alt=''onClick={() => SetColor2(79)}></img>
            <img className='customImages'src="/images/metakays_parts/base/80.png" alt=''onClick={() => SetColor2(80)}></img>
            <img className='customImages'src="/images/metakays_parts/base/81.png" alt=''onClick={() => SetColor2(81)}></img>
            <img className='customImages'src="/images/metakays_parts/base/82.png" alt=''onClick={() => SetColor2(82)}></img>
            <img className='customImages'src="/images/metakays_parts/base/83.png" alt=''onClick={() => SetColor2(83)}></img>
            <img className='customImages'src="/images/metakays_parts/base/84.png" alt=''onClick={() => SetColor2(84)}></img>
            <img className='customImages'src="/images/metakays_parts/base/85.png" alt=''onClick={() => SetColor2(85)}></img>
            <img className='customImages'src="/images/metakays_parts/base/86.png" alt=''onClick={() => SetColor2(86)}></img>
            <img className='customImages'src="/images/metakays_parts/base/87.png" alt=''onClick={() => SetColor2(87)}></img>
            <img className='customImages'src="/images/metakays_parts/base/88.png" alt=''onClick={() => SetColor2(88)}></img>
            <img className='customImages'src="/images/metakays_parts/base/89.png" alt=''onClick={() => SetColor2(89)}></img>
            <img className='customImages'src="/images/metakays_parts/base/90.png" alt=''onClick={() => SetColor2(90)}></img>
            <img className='customImages'src="/images/metakays_parts/base/91.png" alt=''onClick={() => SetColor2(91)}></img>
            <img className='customImages'src="/images/metakays_parts/base/92.png" alt=''onClick={() => SetColor2(92)}></img>
            <img className='customImages'src="/images/metakays_parts/base/93.png" alt=''onClick={() => SetColor2(93)}></img>
            <img className='customImages'src="/images/metakays_parts/base/94.png" alt=''onClick={() => SetColor2(94)}></img>
            <img className='customImages'src="/images/metakays_parts/base/95.png" alt=''onClick={() => SetColor2(95)}></img>
            <img className='customImages'src="/images/metakays_parts/base/96.png" alt=''onClick={() => SetColor2(96)}></img>
            <img className='customImages'src="/images/metakays_parts/base/97.png" alt=''onClick={() => SetColor2(97)}></img>
            <img className='customImages'src="/images/metakays_parts/base/98.png" alt=''onClick={() => SetColor2(98)}></img>
            <img className='customImages'src="/images/metakays_parts/base/99.png" alt=''onClick={() => SetColor2(99)}></img>
            <img className='customImages'src="/images/metakays_parts/base/100.png" alt=''onClick={() => SetColor2(100)}></img>
            <img className='customImages'src="/images/metakays_parts/base/101.png" alt=''onClick={() => SetColor2(101)}></img>
            <img className='customImages'src="/images/metakays_parts/base/102.png" alt=''onClick={() => SetColor2(102)}></img>
            <img className='customImages'src="/images/metakays_parts/base/103.png" alt=''onClick={() => SetColor2(103)}></img>
            <img className='customImages'src="/images/metakays_parts/base/104.png" alt=''onClick={() => SetColor2(104)}></img>
            <img className='customImages'src="/images/metakays_parts/base/105.png" alt=''onClick={() => SetColor2(105)}></img>
            <img className='customImages'src="/images/metakays_parts/base/106.png" alt=''onClick={() => SetColor2(106)}></img>
            <img className='customImages'src="/images/metakays_parts/base/107.png" alt=''onClick={() => SetColor2(107)}></img>
            <img className='customImages'src="/images/metakays_parts/base/108.png" alt=''onClick={() => SetColor2(108)}></img>
            <img className='customImages'src="/images/metakays_parts/base/109.png" alt=''onClick={() => SetColor2(109)}></img>
            <img className='customImages'src="/images/metakays_parts/base/110.png" alt=''onClick={() => SetColor2(110)}></img>
            <img className='customImages'src="/images/metakays_parts/base/111.png" alt=''onClick={() => SetColor2(111)}></img>
            <img className='customImages'src="/images/metakays_parts/base/112.png" alt=''onClick={() => SetColor2(112)}></img>
            <img className='customImages'src="/images/metakays_parts/base/113.png" alt=''onClick={() => SetColor2(113)}></img>
            <img className='customImages'src="/images/metakays_parts/base/114.png" alt=''onClick={() => SetColor2(114)}></img>
            <img className='customImages'src="/images/metakays_parts/base/115.png" alt=''onClick={() => SetColor2(115)}></img>
            <img className='customImages'src="/images/metakays_parts/base/116.png" alt=''onClick={() => SetColor2(116)}></img>
            <img className='customImages'src="/images/metakays_parts/base/117.png" alt=''onClick={() => SetColor2(117)}></img>
            <img className='customImages'src="/images/metakays_parts/base/118.png" alt=''onClick={() => SetColor2(118)}></img>
            <img className='customImages'src="/images/metakays_parts/base/119.png" alt=''onClick={() => SetColor2(119)}></img>
            <img className='customImages'src="/images/metakays_parts/base/120.png" alt=''onClick={() => SetColor2(120)}></img>
            <img className='customImages'src="/images/metakays_parts/base/121.png" alt=''onClick={() => SetColor2(121)}></img>
            <img className='customImages'src="/images/metakays_parts/base/122.png" alt=''onClick={() => SetColor2(122)}></img>
            <img className='customImages'src="/images/metakays_parts/base/123.png" alt=''onClick={() => SetColor2(123)}></img>
            <img className='customImages'src="/images/metakays_parts/base/124.png" alt=''onClick={() => SetColor2(124)}></img>
            <img className='customImages'src="/images/metakays_parts/base/125.png" alt=''onClick={() => SetColor2(125)}></img>
            <img className='customImages'src="/images/metakays_parts/base/126.png" alt=''onClick={() => SetColor2(126)}></img>
            <img className='customImages'src="/images/metakays_parts/base/127.png" alt=''onClick={() => SetColor2(127)}></img>
            <img className='customImages'src="/images/metakays_parts/base/128.png" alt=''onClick={() => SetColor2(128)}></img>
            <img className='customImages'src="/images/metakays_parts/base/129.png" alt=''onClick={() => SetColor2(129)}></img>
            <img className='customImages'src="/images/metakays_parts/base/130.png" alt=''onClick={() => SetColor2(130)}></img>
            <img className='customImages'src="/images/metakays_parts/base/131.png" alt=''onClick={() => SetColor2(131)}></img>
            <img className='customImages'src="/images/metakays_parts/base/132.png" alt=''onClick={() => SetColor2(132)}></img>
            <img className='customImages'src="/images/metakays_parts/base/133.png" alt=''onClick={() => SetColor2(133)}></img>
            <img className='customImages'src="/images/metakays_parts/base/134.png" alt=''onClick={() => SetColor2(134)}></img>
            <img className='customImages'src="/images/metakays_parts/base/135.png" alt=''onClick={() => SetColor2(135)}></img>
            <img className='customImages'src="/images/metakays_parts/base/136.png" alt=''onClick={() => SetColor2(136)}></img>
            <img className='customImages'src="/images/metakays_parts/base/137.png" alt=''onClick={() => SetColor2(137)}></img>
        </div>
        }

{ (customChooser === 28) &&
      <div className="customIndividuals">
      <div>RESET (BLANK)</div>
      <div className="itemDropYourMETAKAYS" onClick={() => resetBlank()}>RESET</div>
      <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
      </div>
        }
{ (customChooser === 29) &&
      <div className="customIndividuals">
      <div>RESET (FULL)</div>
      <div className="itemDropYourMETAKAYS" onClick={() => resetFull()}>RESET</div>
      <div style={{ backgroundColor: "#dc143c", color: "#FFFFFF" }} onClick={backOut}>BACK</div>
      </div>
        }

    </div>

  );
}