import "./TAC.css";
import { Link } from 'react-router-dom'

export default function TAC() {

  return (

    <div className="TAC">
  <div>
      <div className="CustomHeader">Terms and Conditions</div>
      {/* <div>DISCLAIMER</div> */}
      <div>
      <Link className="itemDrop" to='/'>BACK</Link>
● You agree that you are entirely responsible for any tax liability which may arise from minting or reselling your METAKAYS.
</div>
      <div>● This website is only an interface built to help users interact with an Ethereum smart contract. No METAKAYS ownership is managed by this website or by Multimetaverse LLC. Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions generated by the website before approving them. Use of this website is at the user’s own risk and Multimetaverse LLC is not liable for any incorrect or failed transactions. Furthermore, as the contract runs on Ethereum, Multimetaverse LLC has no power to undo, reverse, or restore any transactions.
In order to use this website, and any related tools it may provide, you agree to hold Multimetaverse LLC harmless for any and all problems, errors, and failures no matter the cause or source of those errors and accept sole responsibility for all transactions.
      </div>
<div>
● You agree that your purchase of a METAKAYS from our initial project is all you are guaranteed to receive with your initial purchase. Any future potential airdops, community gatherings, or other benefits are ancillary to this purchase and not to be taken into consideration with your initial purchase. You agree that you are not relying on any future commitments by METAKAYS community members or Multimetaverse LLC in using this site and participating in our NFT mint/launch.
</div>
<div>
● We make absolutely no promise or guarantee that these NFTs will be worth anything. You understand that they have no inherent monetary value and are not intended as investments.
</div>
<div>
● You agree to waive any class action status, and any legal dispute around the METAKAYS project and Multimetaverse LLC which you may choose to bring can only be done on an individual basis.
</div>
<div>
● METAKAYS are released as CC0 public domain. You are free to use your METAKAYS non-comercially AND commercially.
</div>
</div>

    </div>



  )
}
