import React, {useEffect } from "react";
import "./Finality.css";
import parse from 'html-react-parser';
import CustomIndividual from "./CustomIndividual";
import { useAppContext } from "../libs/contextLib";
import web3 from '../web3';
import metakays8888 from '../metakays8888';
import { Link } from 'react-router-dom';


export default function Finality() {

  const { showKustomModal, setKustomModal, backgroundColor, hairColor, eyesColor, mouthColor, bodyColor, skinColor, shirtText, misc1Style, misc2Style ,hairStyle ,mouthStyle, setCustomChooser , userAccount,  setMyMETAKAYS, setUserAccount, tokenID, misc1Color,  misc2Color} = useAppContext();

  useEffect(() => {
    const getMETAKAYS = async () => {
      try {

      const accounts = await web3.eth.getAccounts();
      setUserAccount(accounts[0])
      let supplyOfOwner = await metakays8888.methods.balanceOf(accounts[0]).call()
      for (let i = 0; i<supplyOfOwner; i++) {
        let tempID = await metakays8888.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        let tempStruct = await metakays8888.methods.features(tempID).call()

        setMyMETAKAYS(myMETAKAYS => ({...myMETAKAYS,
      [tempID]: Object.values(tempStruct).slice(0,13)}))

        }

      } catch (error) {
        console.log(error)

      }

      }

    getMETAKAYS();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps




  let submitFinality = async (_tokenID) => {
    alert("FINALITY IS IRREVERSIBLE. \nUNDERSTAND THAT YOU WILL NEVER BE ABLE TO KUSTOMIZE THIS METAKAYS EVER AGAIN. \nPROCEED WITH THE TRANSACTION IF YOU UNDERSTAND.")

        try {
          await metakays8888.methods.setFinality(_tokenID).send({
            from: userAccount
          })
        } catch (e) {
          alert("No METAKAYS selected!")
        }
        }

  const multiParse = (_value) => {
    var temp = _value.split("<path")
    for (var i=temp.length; i< 5; i++){
      temp[i] = ""
    }
    var temp0 = (temp[0].length > 1)? "<path"+temp[0] : ""
    var temp1 = (temp[1].length > 1) ? ("<path"+temp[1]) : ""
    var temp2 = (temp[2].length > 1) ? ("<path"+temp[2]) : ""
    var temp3 = (temp[3].length > 1) ? "<path"+temp[3] : ""
    var temp4 = (temp[4].length > 1) ? "<path"+temp[4] : ""

    return [parse(temp0),parse(temp1),parse(temp2),parse(temp3),parse(temp4)]
  }

  const handleShow = async (_value) => {
    setCustomChooser(_value)
    if (showKustomModal) {
      setKustomModal(false)
    } else {
      setKustomModal(true)
    }
  };

  return (
        <div className="Custom">
        {/* backgroundColor */}
        {showKustomModal ?
          <div className="CustomSVGRightSide">
            <div className='over'><CustomIndividual/></div>
            <div className="CustomFinalityHeader">MENU</div>
            <Link className="itemDrop" to='/kustom8888menu'>BACK</Link>
            <div className="itemDrop" onClick={() => handleShow(13)}>LOAD</div>
            <div className="itemDrop" onClick={() => submitFinality(tokenID)}>FINALITY</div>


          </div>
        :
        <div className="CustomSVGRightSide">
          <div className="CustomFinalityHeader">MENU</div>
            <Link className="itemDrop" to='/kustom8888menu'>BACK</Link>
          <div className="itemDrop" onClick={() => handleShow(13)}>LOAD</div>
            <div className="itemDrop" onClick={() => submitFinality(tokenID)}>FINALITY</div>



        </div>}

        <div className="CustomSVGRightSide">
            {/* background  */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="{w}pt" height="{h} pt" viewBox="0 0 888.000000 888.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,888.000000) scale(0.100000,-0.100000)">
            {parse('<path d="M0 4440 l0 -4440 4440 0 4440 0 0 4440 0 4440 -4440 0 -4440 0 0 -4440z" fill="'+backgroundColor)}
            {/* body */}
            {multiParse('<path d="M3530 3270 l0 -130 -395 0 -395 0 0 -130 0 -130 -515 0 -515 0 0 -130 0 -130 -130 0 -130 0 0 -1310 0 -1310 130 0 130 0 0 1310 0 1310 515 0 515 0 0 130 0 130 395 0 395 0 0 130 0 130 910 0 910 0 0 -130 0 -130 395 0 395 0 0 -130 0 -130 515 0 515 0 0 -1310 0 -1310 130 0 130 0 0 1310 0 1310 -130 0 -130 0 0 130 0 130 -515 0 -515 0 0 130 0 130 -395 0 -395 0 0 130 0 130 -910 0 -910 0 0 -130z M2610 660 l0 -660 130 0 130 0 0 660 0 660 -130 0 -130 0 0 -660z M6010 660 l0 -660 130 0 130 0 0 660 0 660 -130 0 -130 0 0 -660z"/> <path d="M3530 3010 l0 -130 -395 0 -395 0 0 -130 0 -130 -515 0 -515 0 0 -1310 0 -1310 450 0 450 0 0 660 0 660 130 0 130 0 0 -660 0 -660 1570 0 1570 0 0 660 0 660 130 0 130 0 0 -660 0 -660 450 0 450 0 0 1310 0 1310 -515 0 -515 0 0 130 0 130 -395 0 -395 0 0 130 0 130 -910 0 -910 0 0 -130z" fill="'+bodyColor)}
            {/* custom text */}
            {/* <text x="44188" y="-5500" font-size="4000px" */}
            {parse('<g transform="translate(0.000000,888.000000) scale(0.100000,-0.100000)"><text x="44488" y="-8800" font-size="4800px" font-family="impact" font-weight="bold" dominant-baseline="middle" text-anchor="middle">'+shirtText+'</text></g>')}
            {/* shoulder number */}
            {parse('</text></g><g transform="translate(0.000000,888.000000) scale(0.100000,-0.100000)"><text x="71188" y="-14488" font-size="2888px" font-family="impact"  font-weight="bold" dominant-baseline="center" text-anchor="end">'+tokenID+'</text></g>')}
            {/* face */}
            {multiParse('<path d="M3130 5540 l0 -1600 100 0 100 0 0 -185 0 -185 195 0 195 0 0 -85 0 -85 130 0 130 0 0 215 0 215 -195 0 -195 0 0 185 0 185 -100 0 -100 0 0 1340 0 1340 1050 0 1050 0 0 -1340 0 -1340 -100 0 -100 0 0 -185 0 -185 -195 0 -195 0 0 -215 0 -215 130 0 130 0 0 85 0 85 195 0 195 0 0 185 0 185 100 0 100 0 0 1600 0 1600 -1310 0 -1310 0 0 -1600z"/> <path d="M3390 5540 l0 -1340 100 0 100 0 0 -185 0 -185 195 0 195 0 0 -215 0 -215 460 0 460 0 0 215 0 215 195 0 195 0 0 185 0 185 100 0 100 0 0 1340 0 1340 -1050 0 -1050 0 0 -1340z" fill="'+skinColor)}
            {/* eyes */}
            {parse('<path d="M3730 5790 l0 -120 -120 0 -120 0 0 -140 0 -140 120 0 120 0 0 -240 0 -240 140 0 140 0 0 240 0 240 120 0 120 0 0 140 0 140 -120 0 -120 0 0 120 0 120 -140 0 -140 0 0 -120z m250 -30 l0 -120 120 0 120 0 0 -110 0 -110 -120 0 -120 0 0 -240 0 -240 -110 0 -110 0 0 240 0 240 -120 0 -120 0 0 110 0 110 120 0 120 0 0 120 0 120 110 0 110 0 0 -120z M3790 5730 l0 -120 -120 0 -120 0 0 -80 0 -80 120 0 120 0 0 -240 0 -240 80 0 80 0 0 240 0 240 120 0 120 0 0 80 0 80 -120 0 -120 0 0 120 0 120 -80 0 -80 0 0 -120z m130 -30 l0 -120 120 0 120 0 0 -50 0 -50 -120 0 -120 0 0 -240 0 -240 -50 0 -50 0 0 240 0 240 -120 0 -120 0 0 50 0 50 120 0 120 0 0 120 0 120 50 0 50 0 0 -120z M3850 5670 l0 -120 -120 0 c-113 0 -120 -1 -120 -20 0 -19 7 -20 120 -20 l120 0 0 -240 c0 -233 1 -240 20 -240 19 0 20 7 20 240 l0 240 120 0 c113 0 120 1 120 20 0 19 -7 20 -120 20 l-120 0 0 120 c0 113 -1 120 -20 120 -19 0 -20 -7 -20 -120z M4870 5790 l0 -120 -120 0 -120 0 0 -140 0 -140 120 0 120 0 0 -240 0 -240 140 0 140 0 0 240 0 240 120 0 120 0 0 140 0 140 -120 0 -120 0 0 120 0 120 -140 0 -140 0 0 -120z m250 -30 l0 -120 120 0 120 0 0 -110 0 -110 -120 0 -120 0 0 -240 0 -240 -110 0 -110 0 0 240 0 240 -120 0 -120 0 0 110 0 110 120 0 120 0 0 120 0 120 110 0 110 0 0 -120z M4930 5730 l0 -120 -120 0 -120 0 0 -80 0 -80 120 0 120 0 0 -240 0 -240 80 0 80 0 0 240 0 240 120 0 120 0 0 80 0 80 -120 0 -120 0 0 120 0 120 -80 0 -80 0 0 -120z m130 -30 l0 -120 120 0 120 0 0 -50 0 -50 -120 0 -120 0 0 -240 0 -240 -50 0 -50 0 0 240 0 240 -120 0 -120 0 0 50 0 50 120 0 120 0 0 120 0 120 50 0 50 0 0 -120z M4990 5670 l0 -120 -120 0 c-113 0 -120 -1 -120 -20 0 -19 7 -20 120 -20 l120 0 0 -240 c0 -233 1 -240 20 -240 19 0 20 7 20 240 l0 240 120 0 c113 0 120 1 120 20 0 19 -7 20 -120 20 l-120 0 0 120 c0 113 -1 120 -20 120 -19 0 -20 -7 -20 -120z" fill="'+eyesColor)}
            {/* mouth */}
            {multiParse(mouthStyle+mouthColor)}
            {multiParse(misc1Style+misc1Color)}
            {multiParse(misc2Style+misc2Color)}
            {/* hair */}
            {multiParse(hairStyle+hairColor)}
            </g></svg>
          </div>

      </div>

  );
}






