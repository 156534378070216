/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';
import { useAppContext } from "../../libs/contextLib";
import Grid from './drawer/Grid';
// import ColorPicker from './drawer/ColorPicker';
import useStyles from './OPTApp.styles';
import "./CustomOPT.css";


const offCell = {
  on: false,
  color: '#000000',
};
const initialCells = Array.from({ length: 512 }, () => offCell);

function OPTApp() {
  const {setPeices, setColor1, setColor2,four_colors, showKustomModal, setKustomModal, setCustomChooser} = useAppContext();

  const [cells, setCells] = useState(initialCells);
  // const [currentColor, setCurrentColor] = useState('#56BC58');
  // const [colorSelector, setColorSelector] = useState(0);

  const [isRadio, setIsRadio] = useState(0);

  // HANDLE THE ONCHANGE HERE

  const handleChange = (e) => {
    // string passed in
    // a string returned by default
    console.log(e.currentTarget.value);
    // add + to the event to make the value a number
    setIsRadio(+e.currentTarget.value);
  };

  const classes = useStyles();
  // const colorSwatch = useMemo(
  //   () => [
  //     ...new Set(cells.filter((cell) => cell.on).map((cell) => cell.color)),
  //   ],
  //   [cells]
  // );
  const chatString = useMemo(
    () => cells.map((cell) => cell.color.slice(1)).join(','),
    [cells]
  );

  const handleShow = async (_value) => {
    setCustomChooser(_value)
    if (showKustomModal) {
      setKustomModal(false)
    } else {
      setKustomModal(true)
    }
  };

  const submitWork = async () => {
    //       setColor1(total_FC_data1.split(''))
    let color1_array = []
    let color2_array = []
    let peices_array = []


    chatString.split(',').map((value, index) => {

      if ('#'+value === svgBackgroundColor[four_colors[0]]){
        console.log('#'+value)
        peices_array.push("1")
      } else {
        peices_array.push("0")
      }
      return null;
    })
    chatString.split(',').map((value, index) => {
      if ('#'+value === svgBackgroundColor[four_colors[1]]){
        console.log('#'+value)

        color1_array.push("1")
      } else {
        color1_array.push("0")
      }
      return null;
    })
    chatString.split(',').map((value, index) => {

      if ('#'+value === svgBackgroundColor[four_colors[2]]){
        console.log('#'+value)

        color2_array.push("1")
      } else {
        color2_array.push("0")
      }
      return null;
    })
    setColor1(color1_array);
    setColor2(color2_array);
    setPeices(peices_array);
  };

  var svgBackgroundColor = {};
  svgBackgroundColor[0] = '#800000';
  svgBackgroundColor[1] = '#8B0000';
  svgBackgroundColor[2] = '#A52A2A';
  svgBackgroundColor[3] = '#B22222';
  svgBackgroundColor[4] = '#DC143C';
  svgBackgroundColor[5] = '#FF0000';
  svgBackgroundColor[6] = '#FF6347';
  svgBackgroundColor[7] = '#FF7F50';
  svgBackgroundColor[8] = '#CD5C5C';
  svgBackgroundColor[9] = '#F08080';
  svgBackgroundColor[10] = '#E9967A';
  svgBackgroundColor[11] = '#FA8072';
  svgBackgroundColor[12] = '#FFA07A';
  svgBackgroundColor[13] = '#FF4500';
  svgBackgroundColor[14] = '#FF8C00';
  svgBackgroundColor[15] = '#FFA500';
  svgBackgroundColor[16] = '#FFD700';
  svgBackgroundColor[17] = '#B8860B';
  svgBackgroundColor[18] = '#DAA520';
  svgBackgroundColor[19] = '#EEE8AA';
  svgBackgroundColor[20] = '#BDB76B';
  svgBackgroundColor[21] = '#F0E68C';
  svgBackgroundColor[22] = '#808000';
  svgBackgroundColor[23] = '#FFFF00';
  svgBackgroundColor[24] = '#9ACD32';
  svgBackgroundColor[25] = '#556B2F';
  svgBackgroundColor[26] = '#6B8E23';
  svgBackgroundColor[27] = '#7CFC00';
  svgBackgroundColor[28] = '#7FFF00';
  svgBackgroundColor[29] = '#ADFF2F';
  svgBackgroundColor[30] = '#006400';
  svgBackgroundColor[31] = '#008000';
  svgBackgroundColor[32] = '#228B22';
  svgBackgroundColor[33] = '#00FF00';
  svgBackgroundColor[34] = '#32CD32';
  svgBackgroundColor[35] = '#90EE90';
  svgBackgroundColor[36] = '#98FB98';
  svgBackgroundColor[37] = '#8FBC8F';
  svgBackgroundColor[38] = '#00FA9A';
  svgBackgroundColor[39] = '#00FF7F';
  svgBackgroundColor[40] = '#2E8B57';
  svgBackgroundColor[41] = '#66CDAA';
  svgBackgroundColor[42] = '#3CB371';
  svgBackgroundColor[43] = '#20B2AA';
  svgBackgroundColor[44] = '#2F4F4F';
  svgBackgroundColor[45] = '#008080';
  svgBackgroundColor[46] = '#008B8B';
  svgBackgroundColor[47] = '#00FFFF';
  svgBackgroundColor[48] = '#00FFFF';
  svgBackgroundColor[49] = '#E0FFFF';
  svgBackgroundColor[50] = '#00CED1';
  svgBackgroundColor[51] = '#40E0D0';
  svgBackgroundColor[52] = '#48D1CC';
  svgBackgroundColor[53] = '#AFEEEE';
  svgBackgroundColor[54] = '#7FFFD4';
  svgBackgroundColor[55] = '#B0E0E6';
  svgBackgroundColor[56] = '#5F9EA0';
  svgBackgroundColor[57] = '#4682B4';
  svgBackgroundColor[58] = '#6495ED';
  svgBackgroundColor[59] = '#00BFFF';
  svgBackgroundColor[60] = '#1E90FF';
  svgBackgroundColor[61] = '#ADD8E6';
  svgBackgroundColor[62] = '#87CEEB';
  svgBackgroundColor[63] = '#87CEFA';
  svgBackgroundColor[64] = '#191970';
  svgBackgroundColor[65] = '#000080';
  svgBackgroundColor[66] = '#00008B';
  svgBackgroundColor[67] = '#0000CD';
  svgBackgroundColor[68] = '#0000FF';
  svgBackgroundColor[69] = '#4169E1';
  svgBackgroundColor[70] = '#8A2BE2';
  svgBackgroundColor[71] = '#4B0082';
  svgBackgroundColor[72] = '#483D8B';
  svgBackgroundColor[73] = '#6A5ACD';
  svgBackgroundColor[74] = '#7B68EE';
  svgBackgroundColor[75] = '#9370DB';
  svgBackgroundColor[76] = '#8B008B';
  svgBackgroundColor[77] = '#9400D3';
  svgBackgroundColor[78] = '#9932CC';
  svgBackgroundColor[79] = '#BA55D3';
  svgBackgroundColor[80] = '#800080';
  svgBackgroundColor[81] = '#D8BFD8';
  svgBackgroundColor[82] = '#DDA0DD';
  svgBackgroundColor[83] = '#EE82EE';
  svgBackgroundColor[84] = '#FF00FF';
  svgBackgroundColor[85] = '#DA70D6';
  svgBackgroundColor[86] = '#C71585';
  svgBackgroundColor[87] = '#DB7093';
  svgBackgroundColor[88] = '#FF1493';
  svgBackgroundColor[89] = '#FF69B4';
  svgBackgroundColor[90] = '#FFB6C1';
  svgBackgroundColor[91] = '#FFC0CB';
  svgBackgroundColor[92] = '#FAEBD7';
  svgBackgroundColor[93] = '#F5F5DC';
  svgBackgroundColor[94] = '#FFE4C4';
  svgBackgroundColor[95] = '#FFEBCD';
  svgBackgroundColor[96] = '#F5DEB3';
  svgBackgroundColor[97] = '#FFF8DC';
  svgBackgroundColor[98] = '#FFFACD';
  svgBackgroundColor[99] = '#FAFAD2';
  svgBackgroundColor[100] = '#FFFFE0';
  svgBackgroundColor[101] = '#8B4513';
  svgBackgroundColor[102] = '#A0522D';
  svgBackgroundColor[103] = '#D2691E';
  svgBackgroundColor[104] = '#CD853F';
  svgBackgroundColor[105] = '#F4A460';
  svgBackgroundColor[106] = '#DEB887';
  svgBackgroundColor[107] = '#D2B48C';
  svgBackgroundColor[108] = '#BC8F8F';
  svgBackgroundColor[109] = '#FFE4B5';
  svgBackgroundColor[110] = '#FFDEAD';
  svgBackgroundColor[111] = '#FFDAB9';
  svgBackgroundColor[112] = '#FFE4E1';
  svgBackgroundColor[113] = '#FFF0F5';
  svgBackgroundColor[114] = '#FAF0E6';
  svgBackgroundColor[115] = '#FDF5E6';
  svgBackgroundColor[116] = '#FFEFD5';
  svgBackgroundColor[117] = '#FFF5EE';
  svgBackgroundColor[118] = '#F5FFFA';
  svgBackgroundColor[119] = '#708090';
  svgBackgroundColor[120] = '#778899';
  svgBackgroundColor[121] = '#B0C4DE';
  svgBackgroundColor[122] = '#E6E6FA';
  svgBackgroundColor[123] = '#FFFAF0';
  svgBackgroundColor[124] = '#F0F8FF';
  svgBackgroundColor[125] = '#F8F8FF';
  svgBackgroundColor[126] = '#F0FFF0';
  svgBackgroundColor[127] = '#FFFFF0';
  svgBackgroundColor[128] = '#F0FFFF';
  svgBackgroundColor[129] = '#FFFAFA';
  svgBackgroundColor[130] = '#000000';
  svgBackgroundColor[131] = '#696969';
  svgBackgroundColor[132] = '#808080';
  svgBackgroundColor[133] = '#A9A9A9';
  svgBackgroundColor[134] = '#C0C0C0';
  svgBackgroundColor[135] = '#D3D3D3';
  svgBackgroundColor[136] = '#DCDCDC';
  svgBackgroundColor[137] = '#FFFFFF';

  return (
    <div>
      {/* <ColorPicker currentColor={currentColor} onSetColor={setCurrentColor} /> */}
      <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT COLOR 1 (DEFAULT COLOR)</div>
      <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR 2</div>
      <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR 3</div>
      <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR 4</div>

      {/* <div className={classes.colorSwatchContainer}>
        {colorSwatch.map((color) => (
          <div
            key={color}
            onClick={() => setCurrentColor(color)}
            className={classes.colorSwatch}
            style={{ background: color }}
          />
        ))}
      </div> */}
      <ul className='itemDropKustomETHRADIO'>

      <div>
        <input
            type='radio'
            id='radio1'
            value='0'
            onChange={handleChange}
            checked={isRadio === 0}
          />
          <label htmlFor='num1'>Draw w/ Color 2 </label>
          </div>
          <div>
          <input
            type='radio'
            id='radio2'
            value='1'
            onChange={handleChange}
            checked={isRadio === 1}
          />
          <label htmlFor='num2'>Draw w/ Color 3 </label>
          </div>
          <div>
          <input
            type='radio'
            id='radio3'
            value='2'
            onChange={handleChange}
            checked={isRadio === 2}
          />
          <label htmlFor='num3'>Draw w/ Color 4 </label>
          </div>
          <div>
          <input
            type='radio'
            id='radio4'
            value='3'
            onChange={handleChange}
            checked={isRadio === 3}
          />
          <label htmlFor='num4'>Draw w/ Color 1 (Default) </label>
          </div>
      </ul>
      <div className="itemDropKustomETH" onClick={() => submitWork()}>UPDATE</div>

      <Grid cells={cells} setCells={setCells} currentColor={svgBackgroundColor[four_colors[isRadio]]} />
      {/* <Grid cells={cells} setCells={setCells} currentColor={svgBackgroundColor[four_colors[isRadio]]} /> */}

      <p className={classes.chatString}>
        {/* eslint-disable-next-line */}
        {/* !rgb
        {' '} */}
        {/* {chatString} */}
      </p>

    </div>
  );
}

export default OPTApp;


// let data1 = BigInt(String(features.data1)).toString(2)
//       let data2 = BigInt(String(features.data2)).toString(2)
//       let total_data = data1.padStart(256, '0') + data2.padStart(256, '0')
//       setPeices(total_data.split(''))


//       let featureColors1 = await metakays.methods.getFeatureColors(value, 0).call()
//       let featureColors2 = await metakays.methods.getFeatureColors(value, 1).call()
//       let featureColors3 = await metakays.methods.getFeatureColors(value, 2).call()
//       let featureColors4 = await metakays.methods.getFeatureColors(value, 3).call()


//       let FC1 = BigInt(String(featureColors1)).toString(2)
//       let FC2 = BigInt(String(featureColors2)).toString(2)
//       let FC3 = BigInt(String(featureColors3)).toString(2)
//       let FC4 = BigInt(String(featureColors4)).toString(2)
//       let total_FC_data1 = FC1.padStart(256, '0') + FC2.padStart(256, '0')
//       let total_FC_data2 = FC3.padStart(256, '0') + FC4.padStart(256, '0')

//       setColor1(total_FC_data1.split(''))
//       setColor2(total_FC_data2.split(''))