import React, {useEffect, useState} from "react";
import "./CustomOPT.css";
import parse from 'html-react-parser';
import CustomIndividualOPT from "./CustomIndividualOPT";
import { useAppContext } from "../../libs/contextLib";
import web3 from '../../web3';
import metakays from '../../metakays';
import { Link } from 'react-router-dom'
import OPTApp from './OPTApp';



export default function CustomOPT() {

  const {bgColor, four_colors, color1, color2, peices, showKustomModal, setMyMETAKAYS, setUserAccount} = useAppContext();

  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");
  const [msg3, setMsg3] = useState("");
  const [msg4, setMsg4] = useState("");
  const [msg5, setMsg5] = useState("");
  const [msg6, setMsg6] = useState("");



  useEffect(() => {
    const getMETAKAYS = async () => {

      try {

      const accounts = await web3.eth.getAccounts();
      setUserAccount(accounts[0])
      let supplyOfOwner = await metakays.methods.balanceOf(accounts[0]).call()
      for (let i = 0; i<supplyOfOwner; i++) {
        let tempID = await metakays.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        let tempStruct = await metakays.methods.features(tempID).call()
        // let tokenURItest = await metakays.methods.tokenURI(2).call()


        setMyMETAKAYS(myMETAKAYS => ({...myMETAKAYS,
      [tempID]: Object.values(tempStruct).slice(0,13)}))

        }

      } catch (error) {
        console.log(error)

      }
      }

    getMETAKAYS();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var svgData = {}
  svgData[0] = '<use xlink:href="#tri" x="0" y="0';
  svgData[1] = '<use xlink:href="#tri2" x="50" y="0';
  svgData[2] = '<use xlink:href="#tri" x="100" y="0';
  svgData[3] = '<use xlink:href="#tri2" x="150" y="0';
  svgData[4] = '<use xlink:href="#tri" x="200" y="0';
  svgData[5] = '<use xlink:href="#tri2" x="250" y="0';
  svgData[6] = '<use xlink:href="#tri" x="300" y="0';
  svgData[7] = '<use xlink:href="#tri2" x="350" y="0';
  svgData[8] = '<use xlink:href="#tri" x="400" y="0';
  svgData[9] = '<use xlink:href="#tri2" x="450" y="0';
  svgData[10] = '<use xlink:href="#tri" x="500" y="0';
  svgData[11] = '<use xlink:href="#tri2" x="550" y="0';
  svgData[12] = '<use xlink:href="#tri" x="600" y="0';
  svgData[13] = '<use xlink:href="#tri2" x="650" y="0';
  svgData[14] = '<use xlink:href="#tri" x="700" y="0';
  svgData[15] = '<use xlink:href="#tri2" x="750" y="0';
  svgData[16] = '<use xlink:href="#tri" x="0" y="50';
  svgData[17] = '<use xlink:href="#tri2" x="50" y="50';
  svgData[18] = '<use xlink:href="#tri" x="100" y="50';
  svgData[19] = '<use xlink:href="#tri2" x="150" y="50';
  svgData[20] = '<use xlink:href="#tri" x="200" y="50';
  svgData[21] = '<use xlink:href="#tri2" x="250" y="50';
  svgData[22] = '<use xlink:href="#tri" x="300" y="50';
  svgData[23] = '<use xlink:href="#tri2" x="350" y="50';
  svgData[24] = '<use xlink:href="#tri" x="400" y="50';
  svgData[25] = '<use xlink:href="#tri2" x="450" y="50';
  svgData[26] = '<use xlink:href="#tri" x="500" y="50';
  svgData[27] = '<use xlink:href="#tri2" x="550" y="50';
  svgData[28] = '<use xlink:href="#tri" x="600" y="50';
  svgData[29] = '<use xlink:href="#tri2" x="650" y="50';
  svgData[30] = '<use xlink:href="#tri" x="700" y="50';
  svgData[31] = '<use xlink:href="#tri2" x="750" y="50';
  svgData[32] = '<use xlink:href="#tri" x="0" y="100';
  svgData[33] = '<use xlink:href="#tri2" x="50" y="100';
  svgData[34] = '<use xlink:href="#tri" x="100" y="100';
  svgData[35] = '<use xlink:href="#tri2" x="150" y="100';
  svgData[36] = '<use xlink:href="#tri" x="200" y="100';
  svgData[37] = '<use xlink:href="#tri2" x="250" y="100';
  svgData[38] = '<use xlink:href="#tri" x="300" y="100';
  svgData[39] = '<use xlink:href="#tri2" x="350" y="100';
  svgData[40] = '<use xlink:href="#tri" x="400" y="100';
  svgData[41] = '<use xlink:href="#tri2" x="450" y="100';
  svgData[42] = '<use xlink:href="#tri" x="500" y="100';
  svgData[43] = '<use xlink:href="#tri2" x="550" y="100';
  svgData[44] = '<use xlink:href="#tri" x="600" y="100';
  svgData[45] = '<use xlink:href="#tri2" x="650" y="100';
  svgData[46] = '<use xlink:href="#tri" x="700" y="100';
  svgData[47] = '<use xlink:href="#tri2" x="750" y="100';
  svgData[48] = '<use xlink:href="#tri" x="0" y="150';
  svgData[49] = '<use xlink:href="#tri2" x="50" y="150';
  svgData[50] = '<use xlink:href="#tri" x="100" y="150';
  svgData[51] = '<use xlink:href="#tri2" x="150" y="150';
  svgData[52] = '<use xlink:href="#tri" x="200" y="150';
  svgData[53] = '<use xlink:href="#tri2" x="250" y="150';
  svgData[54] = '<use xlink:href="#tri" x="300" y="150';
  svgData[55] = '<use xlink:href="#tri2" x="350" y="150';
  svgData[56] = '<use xlink:href="#tri" x="400" y="150';
  svgData[57] = '<use xlink:href="#tri2" x="450" y="150';
  svgData[58] = '<use xlink:href="#tri" x="500" y="150';
  svgData[59] = '<use xlink:href="#tri2" x="550" y="150';
  svgData[60] = '<use xlink:href="#tri" x="600" y="150';
  svgData[61] = '<use xlink:href="#tri2" x="650" y="150';
  svgData[62] = '<use xlink:href="#tri" x="700" y="150';
  svgData[63] = '<use xlink:href="#tri2" x="750" y="150';
  svgData[64] = '<use xlink:href="#tri" x="0" y="200';
  svgData[65] = '<use xlink:href="#tri2" x="50" y="200';
  svgData[66] = '<use xlink:href="#tri" x="100" y="200';
  svgData[67] = '<use xlink:href="#tri2" x="150" y="200';
  svgData[68] = '<use xlink:href="#tri" x="200" y="200';
  svgData[69] = '<use xlink:href="#tri2" x="250" y="200';
  svgData[70] = '<use xlink:href="#tri" x="300" y="200';
  svgData[71] = '<use xlink:href="#tri2" x="350" y="200';
  svgData[72] = '<use xlink:href="#tri" x="400" y="200';
  svgData[73] = '<use xlink:href="#tri2" x="450" y="200';
  svgData[74] = '<use xlink:href="#tri" x="500" y="200';
  svgData[75] = '<use xlink:href="#tri2" x="550" y="200';
  svgData[76] = '<use xlink:href="#tri" x="600" y="200';
  svgData[77] = '<use xlink:href="#tri2" x="650" y="200';
  svgData[78] = '<use xlink:href="#tri" x="700" y="200';
  svgData[79] = '<use xlink:href="#tri2" x="750" y="200';
  svgData[80] = '<use xlink:href="#tri" x="0" y="250';
  svgData[81] = '<use xlink:href="#tri2" x="50" y="250';
  svgData[82] = '<use xlink:href="#tri" x="100" y="250';
  svgData[83] = '<use xlink:href="#tri2" x="150" y="250';
  svgData[84] = '<use xlink:href="#tri" x="200" y="250';
  svgData[85] = '<use xlink:href="#tri2" x="250" y="250';
  svgData[86] = '<use xlink:href="#tri" x="300" y="250';
  svgData[87] = '<use xlink:href="#tri2" x="350" y="250';
  svgData[88] = '<use xlink:href="#tri" x="400" y="250';
  svgData[89] = '<use xlink:href="#tri2" x="450" y="250';
  svgData[90] = '<use xlink:href="#tri" x="500" y="250';
  svgData[91] = '<use xlink:href="#tri2" x="550" y="250';
  svgData[92] = '<use xlink:href="#tri" x="600" y="250';
  svgData[93] = '<use xlink:href="#tri2" x="650" y="250';
  svgData[94] = '<use xlink:href="#tri" x="700" y="250';
  svgData[95] = '<use xlink:href="#tri2" x="750" y="250';
  svgData[96] = '<use xlink:href="#tri" x="0" y="300';
  svgData[97] = '<use xlink:href="#tri2" x="50" y="300';
  svgData[98] = '<use xlink:href="#tri" x="100" y="300';
  svgData[99] = '<use xlink:href="#tri2" x="150" y="300';
  svgData[100] = '<use xlink:href="#tri" x="200" y="300';
  svgData[101] = '<use xlink:href="#tri2" x="250" y="300';
  svgData[102] = '<use xlink:href="#tri" x="300" y="300';
  svgData[103] = '<use xlink:href="#tri2" x="350" y="300';
  svgData[104] = '<use xlink:href="#tri" x="400" y="300';
  svgData[105] = '<use xlink:href="#tri2" x="450" y="300';
  svgData[106] = '<use xlink:href="#tri" x="500" y="300';
  svgData[107] = '<use xlink:href="#tri2" x="550" y="300';
  svgData[108] = '<use xlink:href="#tri" x="600" y="300';
  svgData[109] = '<use xlink:href="#tri2" x="650" y="300';
  svgData[110] = '<use xlink:href="#tri" x="700" y="300';
  svgData[111] = '<use xlink:href="#tri2" x="750" y="300';
  svgData[112] = '<use xlink:href="#tri" x="0" y="350';
  svgData[113] = '<use xlink:href="#tri2" x="50" y="350';
  svgData[114] = '<use xlink:href="#tri" x="100" y="350';
  svgData[115] = '<use xlink:href="#tri2" x="150" y="350';
  svgData[116] = '<use xlink:href="#tri" x="200" y="350';
  svgData[117] = '<use xlink:href="#tri2" x="250" y="350';
  svgData[118] = '<use xlink:href="#tri" x="300" y="350';
  svgData[119] = '<use xlink:href="#tri2" x="350" y="350';
  svgData[120] = '<use xlink:href="#tri" x="400" y="350';
  svgData[121] = '<use xlink:href="#tri2" x="450" y="350';
  svgData[122] = '<use xlink:href="#tri" x="500" y="350';
  svgData[123] = '<use xlink:href="#tri2" x="550" y="350';
  svgData[124] = '<use xlink:href="#tri" x="600" y="350';
  svgData[125] = '<use xlink:href="#tri2" x="650" y="350';
  svgData[126] = '<use xlink:href="#tri" x="700" y="350';
  svgData[127] = '<use xlink:href="#tri2" x="750" y="350';
  svgData[128] = '<use xlink:href="#tri" x="0" y="400';
  svgData[129] = '<use xlink:href="#tri2" x="50" y="400';
  svgData[130] = '<use xlink:href="#tri" x="100" y="400';
  svgData[131] = '<use xlink:href="#tri2" x="150" y="400';
  svgData[132] = '<use xlink:href="#tri" x="200" y="400';
  svgData[133] = '<use xlink:href="#tri2" x="250" y="400';
  svgData[134] = '<use xlink:href="#tri" x="300" y="400';
  svgData[135] = '<use xlink:href="#tri2" x="350" y="400';
  svgData[136] = '<use xlink:href="#tri" x="400" y="400';
  svgData[137] = '<use xlink:href="#tri2" x="450" y="400';
  svgData[138] = '<use xlink:href="#tri" x="500" y="400';
  svgData[139] = '<use xlink:href="#tri2" x="550" y="400';
  svgData[140] = '<use xlink:href="#tri" x="600" y="400';
  svgData[141] = '<use xlink:href="#tri2" x="650" y="400';
  svgData[142] = '<use xlink:href="#tri" x="700" y="400';
  svgData[143] = '<use xlink:href="#tri2" x="750" y="400';
  svgData[144] = '<use xlink:href="#tri" x="0" y="450';
  svgData[145] = '<use xlink:href="#tri2" x="50" y="450';
  svgData[146] = '<use xlink:href="#tri" x="100" y="450';
  svgData[147] = '<use xlink:href="#tri2" x="150" y="450';
  svgData[148] = '<use xlink:href="#tri" x="200" y="450';
  svgData[149] = '<use xlink:href="#tri2" x="250" y="450';
  svgData[150] = '<use xlink:href="#tri" x="300" y="450';
  svgData[151] = '<use xlink:href="#tri2" x="350" y="450';
  svgData[152] = '<use xlink:href="#tri" x="400" y="450';
  svgData[153] = '<use xlink:href="#tri2" x="450" y="450';
  svgData[154] = '<use xlink:href="#tri" x="500" y="450';
  svgData[155] = '<use xlink:href="#tri2" x="550" y="450';
  svgData[156] = '<use xlink:href="#tri" x="600" y="450';
  svgData[157] = '<use xlink:href="#tri2" x="650" y="450';
  svgData[158] = '<use xlink:href="#tri" x="700" y="450';
  svgData[159] = '<use xlink:href="#tri2" x="750" y="450';
  svgData[160] = '<use xlink:href="#tri" x="0" y="500';
  svgData[161] = '<use xlink:href="#tri2" x="50" y="500';
  svgData[162] = '<use xlink:href="#tri" x="100" y="500';
  svgData[163] = '<use xlink:href="#tri2" x="150" y="500';
  svgData[164] = '<use xlink:href="#tri" x="200" y="500';
  svgData[165] = '<use xlink:href="#tri2" x="250" y="500';
  svgData[166] = '<use xlink:href="#tri" x="300" y="500';
  svgData[167] = '<use xlink:href="#tri2" x="350" y="500';
  svgData[168] = '<use xlink:href="#tri" x="400" y="500';
  svgData[169] = '<use xlink:href="#tri2" x="450" y="500';
  svgData[170] = '<use xlink:href="#tri" x="500" y="500';
  svgData[171] = '<use xlink:href="#tri2" x="550" y="500';
  svgData[172] = '<use xlink:href="#tri" x="600" y="500';
  svgData[173] = '<use xlink:href="#tri2" x="650" y="500';
  svgData[174] = '<use xlink:href="#tri" x="700" y="500';
  svgData[175] = '<use xlink:href="#tri2" x="750" y="500';
  svgData[176] = '<use xlink:href="#tri" x="0" y="550';
  svgData[177] = '<use xlink:href="#tri2" x="50" y="550';
  svgData[178] = '<use xlink:href="#tri" x="100" y="550';
  svgData[179] = '<use xlink:href="#tri2" x="150" y="550';
  svgData[180] = '<use xlink:href="#tri" x="200" y="550';
  svgData[181] = '<use xlink:href="#tri2" x="250" y="550';
  svgData[182] = '<use xlink:href="#tri" x="300" y="550';
  svgData[183] = '<use xlink:href="#tri2" x="350" y="550';
  svgData[184] = '<use xlink:href="#tri" x="400" y="550';
  svgData[185] = '<use xlink:href="#tri2" x="450" y="550';
  svgData[186] = '<use xlink:href="#tri" x="500" y="550';
  svgData[187] = '<use xlink:href="#tri2" x="550" y="550';
  svgData[188] = '<use xlink:href="#tri" x="600" y="550';
  svgData[189] = '<use xlink:href="#tri2" x="650" y="550';
  svgData[190] = '<use xlink:href="#tri" x="700" y="550';
  svgData[191] = '<use xlink:href="#tri2" x="750" y="550';
  svgData[192] = '<use xlink:href="#tri" x="0" y="600';
  svgData[193] = '<use xlink:href="#tri2" x="50" y="600';
  svgData[194] = '<use xlink:href="#tri" x="100" y="600';
  svgData[195] = '<use xlink:href="#tri2" x="150" y="600';
  svgData[196] = '<use xlink:href="#tri" x="200" y="600';
  svgData[197] = '<use xlink:href="#tri2" x="250" y="600';
  svgData[198] = '<use xlink:href="#tri" x="300" y="600';
  svgData[199] = '<use xlink:href="#tri2" x="350" y="600';
  svgData[200] = '<use xlink:href="#tri" x="400" y="600';
  svgData[201] = '<use xlink:href="#tri2" x="450" y="600';
  svgData[202] = '<use xlink:href="#tri" x="500" y="600';
  svgData[203] = '<use xlink:href="#tri2" x="550" y="600';
  svgData[204] = '<use xlink:href="#tri" x="600" y="600';
  svgData[205] = '<use xlink:href="#tri2" x="650" y="600';
  svgData[206] = '<use xlink:href="#tri" x="700" y="600';
  svgData[207] = '<use xlink:href="#tri2" x="750" y="600';
  svgData[208] = '<use xlink:href="#tri" x="0" y="650';
  svgData[209] = '<use xlink:href="#tri2" x="50" y="650';
  svgData[210] = '<use xlink:href="#tri" x="100" y="650';
  svgData[211] = '<use xlink:href="#tri2" x="150" y="650';
  svgData[212] = '<use xlink:href="#tri" x="200" y="650';
  svgData[213] = '<use xlink:href="#tri2" x="250" y="650';
  svgData[214] = '<use xlink:href="#tri" x="300" y="650';
  svgData[215] = '<use xlink:href="#tri2" x="350" y="650';
  svgData[216] = '<use xlink:href="#tri" x="400" y="650';
  svgData[217] = '<use xlink:href="#tri2" x="450" y="650';
  svgData[218] = '<use xlink:href="#tri" x="500" y="650';
  svgData[219] = '<use xlink:href="#tri2" x="550" y="650';
  svgData[220] = '<use xlink:href="#tri" x="600" y="650';
  svgData[221] = '<use xlink:href="#tri2" x="650" y="650';
  svgData[222] = '<use xlink:href="#tri" x="700" y="650';
  svgData[223] = '<use xlink:href="#tri2" x="750" y="650';
  svgData[224] = '<use xlink:href="#tri" x="0" y="700';
  svgData[225] = '<use xlink:href="#tri2" x="50" y="700';
  svgData[226] = '<use xlink:href="#tri" x="100" y="700';
  svgData[227] = '<use xlink:href="#tri2" x="150" y="700';
  svgData[228] = '<use xlink:href="#tri" x="200" y="700';
  svgData[229] = '<use xlink:href="#tri2" x="250" y="700';
  svgData[230] = '<use xlink:href="#tri" x="300" y="700';
  svgData[231] = '<use xlink:href="#tri2" x="350" y="700';
  svgData[232] = '<use xlink:href="#tri" x="400" y="700';
  svgData[233] = '<use xlink:href="#tri2" x="450" y="700';
  svgData[234] = '<use xlink:href="#tri" x="500" y="700';
  svgData[235] = '<use xlink:href="#tri2" x="550" y="700';
  svgData[236] = '<use xlink:href="#tri" x="600" y="700';
  svgData[237] = '<use xlink:href="#tri2" x="650" y="700';
  svgData[238] = '<use xlink:href="#tri" x="700" y="700';
  svgData[239] = '<use xlink:href="#tri2" x="750" y="700';
  svgData[240] = '<use xlink:href="#tri" x="0" y="750';
  svgData[241] = '<use xlink:href="#tri2" x="50" y="750';
  svgData[242] = '<use xlink:href="#tri" x="100" y="750';
  svgData[243] = '<use xlink:href="#tri2" x="150" y="750';
  svgData[244] = '<use xlink:href="#tri" x="200" y="750';
  svgData[245] = '<use xlink:href="#tri2" x="250" y="750';
  svgData[246] = '<use xlink:href="#tri" x="300" y="750';
  svgData[247] = '<use xlink:href="#tri2" x="350" y="750';
  svgData[248] = '<use xlink:href="#tri" x="400" y="750';
  svgData[249] = '<use xlink:href="#tri2" x="450" y="750';
  svgData[250] = '<use xlink:href="#tri" x="500" y="750';
  svgData[251] = '<use xlink:href="#tri2" x="550" y="750';
  svgData[252] = '<use xlink:href="#tri" x="600" y="750';
  svgData[253] = '<use xlink:href="#tri2" x="650" y="750';
  svgData[254] = '<use xlink:href="#tri" x="700" y="750';
  svgData[255] = '<use xlink:href="#tri2" x="750" y="750';
  svgData[256] = '<use xlink:href="#tri" x="0" y="800';
  svgData[257] = '<use xlink:href="#tri2" x="50" y="800';
  svgData[258] = '<use xlink:href="#tri" x="100" y="800';
  svgData[259] = '<use xlink:href="#tri2" x="150" y="800';
  svgData[260] = '<use xlink:href="#tri" x="200" y="800';
  svgData[261] = '<use xlink:href="#tri2" x="250" y="800';
  svgData[262] = '<use xlink:href="#tri" x="300" y="800';
  svgData[263] = '<use xlink:href="#tri2" x="350" y="800';
  svgData[264] = '<use xlink:href="#tri" x="400" y="800';
  svgData[265] = '<use xlink:href="#tri2" x="450" y="800';
  svgData[266] = '<use xlink:href="#tri" x="500" y="800';
  svgData[267] = '<use xlink:href="#tri2" x="550" y="800';
  svgData[268] = '<use xlink:href="#tri" x="600" y="800';
  svgData[269] = '<use xlink:href="#tri2" x="650" y="800';
  svgData[270] = '<use xlink:href="#tri" x="700" y="800';
  svgData[271] = '<use xlink:href="#tri2" x="750" y="800';
  svgData[272] = '<use xlink:href="#tri" x="0" y="850';
  svgData[273] = '<use xlink:href="#tri2" x="50" y="850';
  svgData[274] = '<use xlink:href="#tri" x="100" y="850';
  svgData[275] = '<use xlink:href="#tri2" x="150" y="850';
  svgData[276] = '<use xlink:href="#tri" x="200" y="850';
  svgData[277] = '<use xlink:href="#tri2" x="250" y="850';
  svgData[278] = '<use xlink:href="#tri" x="300" y="850';
  svgData[279] = '<use xlink:href="#tri2" x="350" y="850';
  svgData[280] = '<use xlink:href="#tri" x="400" y="850';
  svgData[281] = '<use xlink:href="#tri2" x="450" y="850';
  svgData[282] = '<use xlink:href="#tri" x="500" y="850';
  svgData[283] = '<use xlink:href="#tri2" x="550" y="850';
  svgData[284] = '<use xlink:href="#tri" x="600" y="850';
  svgData[285] = '<use xlink:href="#tri2" x="650" y="850';
  svgData[286] = '<use xlink:href="#tri" x="700" y="850';
  svgData[287] = '<use xlink:href="#tri2" x="750" y="850';
  svgData[288] = '<use xlink:href="#tri" x="0" y="900';
  svgData[289] = '<use xlink:href="#tri2" x="50" y="900';
  svgData[290] = '<use xlink:href="#tri" x="100" y="900';
  svgData[291] = '<use xlink:href="#tri2" x="150" y="900';
  svgData[292] = '<use xlink:href="#tri" x="200" y="900';
  svgData[293] = '<use xlink:href="#tri2" x="250" y="900';
  svgData[294] = '<use xlink:href="#tri" x="300" y="900';
  svgData[295] = '<use xlink:href="#tri2" x="350" y="900';
  svgData[296] = '<use xlink:href="#tri" x="400" y="900';
  svgData[297] = '<use xlink:href="#tri2" x="450" y="900';
  svgData[298] = '<use xlink:href="#tri" x="500" y="900';
  svgData[299] = '<use xlink:href="#tri2" x="550" y="900';
  svgData[300] = '<use xlink:href="#tri" x="600" y="900';
  svgData[301] = '<use xlink:href="#tri2" x="650" y="900';
  svgData[302] = '<use xlink:href="#tri" x="700" y="900';
  svgData[303] = '<use xlink:href="#tri2" x="750" y="900';
  svgData[304] = '<use xlink:href="#tri" x="0" y="950';
  svgData[305] = '<use xlink:href="#tri2" x="50" y="950';
  svgData[306] = '<use xlink:href="#tri" x="100" y="950';
  svgData[307] = '<use xlink:href="#tri2" x="150" y="950';
  svgData[308] = '<use xlink:href="#tri" x="200" y="950';
  svgData[309] = '<use xlink:href="#tri2" x="250" y="950';
  svgData[310] = '<use xlink:href="#tri" x="300" y="950';
  svgData[311] = '<use xlink:href="#tri2" x="350" y="950';
  svgData[312] = '<use xlink:href="#tri" x="400" y="950';
  svgData[313] = '<use xlink:href="#tri2" x="450" y="950';
  svgData[314] = '<use xlink:href="#tri" x="500" y="950';
  svgData[315] = '<use xlink:href="#tri2" x="550" y="950';
  svgData[316] = '<use xlink:href="#tri" x="600" y="950';
  svgData[317] = '<use xlink:href="#tri2" x="650" y="950';
  svgData[318] = '<use xlink:href="#tri" x="700" y="950';
  svgData[319] = '<use xlink:href="#tri2" x="750" y="950';
  svgData[320] = '<use xlink:href="#tri" x="0" y="1000';
  svgData[321] = '<use xlink:href="#tri2" x="50" y="1000';
  svgData[322] = '<use xlink:href="#tri" x="100" y="1000';
  svgData[323] = '<use xlink:href="#tri2" x="150" y="1000';
  svgData[324] = '<use xlink:href="#tri" x="200" y="1000';
  svgData[325] = '<use xlink:href="#tri2" x="250" y="1000';
  svgData[326] = '<use xlink:href="#tri" x="300" y="1000';
  svgData[327] = '<use xlink:href="#tri2" x="350" y="1000';
  svgData[328] = '<use xlink:href="#tri" x="400" y="1000';
  svgData[329] = '<use xlink:href="#tri2" x="450" y="1000';
  svgData[330] = '<use xlink:href="#tri" x="500" y="1000';
  svgData[331] = '<use xlink:href="#tri2" x="550" y="1000';
  svgData[332] = '<use xlink:href="#tri" x="600" y="1000';
  svgData[333] = '<use xlink:href="#tri2" x="650" y="1000';
  svgData[334] = '<use xlink:href="#tri" x="700" y="1000';
  svgData[335] = '<use xlink:href="#tri2" x="750" y="1000';
  svgData[336] = '<use xlink:href="#tri" x="0" y="1050';
  svgData[337] = '<use xlink:href="#tri2" x="50" y="1050';
  svgData[338] = '<use xlink:href="#tri" x="100" y="1050';
  svgData[339] = '<use xlink:href="#tri2" x="150" y="1050';
  svgData[340] = '<use xlink:href="#tri" x="200" y="1050';
  svgData[341] = '<use xlink:href="#tri2" x="250" y="1050';
  svgData[342] = '<use xlink:href="#tri" x="300" y="1050';
  svgData[343] = '<use xlink:href="#tri2" x="350" y="1050';
  svgData[344] = '<use xlink:href="#tri" x="400" y="1050';
  svgData[345] = '<use xlink:href="#tri2" x="450" y="1050';
  svgData[346] = '<use xlink:href="#tri" x="500" y="1050';
  svgData[347] = '<use xlink:href="#tri2" x="550" y="1050';
  svgData[348] = '<use xlink:href="#tri" x="600" y="1050';
  svgData[349] = '<use xlink:href="#tri2" x="650" y="1050';
  svgData[350] = '<use xlink:href="#tri" x="700" y="1050';
  svgData[351] = '<use xlink:href="#tri2" x="750" y="1050';
  svgData[352] = '<use xlink:href="#tri" x="0" y="1100';
  svgData[353] = '<use xlink:href="#tri2" x="50" y="1100';
  svgData[354] = '<use xlink:href="#tri" x="100" y="1100';
  svgData[355] = '<use xlink:href="#tri2" x="150" y="1100';
  svgData[356] = '<use xlink:href="#tri" x="200" y="1100';
  svgData[357] = '<use xlink:href="#tri2" x="250" y="1100';
  svgData[358] = '<use xlink:href="#tri" x="300" y="1100';
  svgData[359] = '<use xlink:href="#tri2" x="350" y="1100';
  svgData[360] = '<use xlink:href="#tri" x="400" y="1100';
  svgData[361] = '<use xlink:href="#tri2" x="450" y="1100';
  svgData[362] = '<use xlink:href="#tri" x="500" y="1100';
  svgData[363] = '<use xlink:href="#tri2" x="550" y="1100';
  svgData[364] = '<use xlink:href="#tri" x="600" y="1100';
  svgData[365] = '<use xlink:href="#tri2" x="650" y="1100';
  svgData[366] = '<use xlink:href="#tri" x="700" y="1100';
  svgData[367] = '<use xlink:href="#tri2" x="750" y="1100';
  svgData[368] = '<use xlink:href="#tri" x="0" y="1150';
  svgData[369] = '<use xlink:href="#tri2" x="50" y="1150';
  svgData[370] = '<use xlink:href="#tri" x="100" y="1150';
  svgData[371] = '<use xlink:href="#tri2" x="150" y="1150';
  svgData[372] = '<use xlink:href="#tri" x="200" y="1150';
  svgData[373] = '<use xlink:href="#tri2" x="250" y="1150';
  svgData[374] = '<use xlink:href="#tri" x="300" y="1150';
  svgData[375] = '<use xlink:href="#tri2" x="350" y="1150';
  svgData[376] = '<use xlink:href="#tri" x="400" y="1150';
  svgData[377] = '<use xlink:href="#tri2" x="450" y="1150';
  svgData[378] = '<use xlink:href="#tri" x="500" y="1150';
  svgData[379] = '<use xlink:href="#tri2" x="550" y="1150';
  svgData[380] = '<use xlink:href="#tri" x="600" y="1150';
  svgData[381] = '<use xlink:href="#tri2" x="650" y="1150';
  svgData[382] = '<use xlink:href="#tri" x="700" y="1150';
  svgData[383] = '<use xlink:href="#tri2" x="750" y="1150';
  svgData[384] = '<use xlink:href="#tri" x="0" y="1200';
  svgData[385] = '<use xlink:href="#tri2" x="50" y="1200';
  svgData[386] = '<use xlink:href="#tri" x="100" y="1200';
  svgData[387] = '<use xlink:href="#tri2" x="150" y="1200';
  svgData[388] = '<use xlink:href="#tri" x="200" y="1200';
  svgData[389] = '<use xlink:href="#tri2" x="250" y="1200';
  svgData[390] = '<use xlink:href="#tri" x="300" y="1200';
  svgData[391] = '<use xlink:href="#tri2" x="350" y="1200';
  svgData[392] = '<use xlink:href="#tri" x="400" y="1200';
  svgData[393] = '<use xlink:href="#tri2" x="450" y="1200';
  svgData[394] = '<use xlink:href="#tri" x="500" y="1200';
  svgData[395] = '<use xlink:href="#tri2" x="550" y="1200';
  svgData[396] = '<use xlink:href="#tri" x="600" y="1200';
  svgData[397] = '<use xlink:href="#tri2" x="650" y="1200';
  svgData[398] = '<use xlink:href="#tri" x="700" y="1200';
  svgData[399] = '<use xlink:href="#tri2" x="750" y="1200';
  svgData[400] = '<use xlink:href="#tri" x="0" y="1250';
  svgData[401] = '<use xlink:href="#tri2" x="50" y="1250';
  svgData[402] = '<use xlink:href="#tri" x="100" y="1250';
  svgData[403] = '<use xlink:href="#tri2" x="150" y="1250';
  svgData[404] = '<use xlink:href="#tri" x="200" y="1250';
  svgData[405] = '<use xlink:href="#tri2" x="250" y="1250';
  svgData[406] = '<use xlink:href="#tri" x="300" y="1250';
  svgData[407] = '<use xlink:href="#tri2" x="350" y="1250';
  svgData[408] = '<use xlink:href="#tri" x="400" y="1250';
  svgData[409] = '<use xlink:href="#tri2" x="450" y="1250';
  svgData[410] = '<use xlink:href="#tri" x="500" y="1250';
  svgData[411] = '<use xlink:href="#tri2" x="550" y="1250';
  svgData[412] = '<use xlink:href="#tri" x="600" y="1250';
  svgData[413] = '<use xlink:href="#tri2" x="650" y="1250';
  svgData[414] = '<use xlink:href="#tri" x="700" y="1250';
  svgData[415] = '<use xlink:href="#tri2" x="750" y="1250';
  svgData[416] = '<use xlink:href="#tri" x="0" y="1300';
  svgData[417] = '<use xlink:href="#tri2" x="50" y="1300';
  svgData[418] = '<use xlink:href="#tri" x="100" y="1300';
  svgData[419] = '<use xlink:href="#tri2" x="150" y="1300';
  svgData[420] = '<use xlink:href="#tri" x="200" y="1300';
  svgData[421] = '<use xlink:href="#tri2" x="250" y="1300';
  svgData[422] = '<use xlink:href="#tri" x="300" y="1300';
  svgData[423] = '<use xlink:href="#tri2" x="350" y="1300';
  svgData[424] = '<use xlink:href="#tri" x="400" y="1300';
  svgData[425] = '<use xlink:href="#tri2" x="450" y="1300';
  svgData[426] = '<use xlink:href="#tri" x="500" y="1300';
  svgData[427] = '<use xlink:href="#tri2" x="550" y="1300';
  svgData[428] = '<use xlink:href="#tri" x="600" y="1300';
  svgData[429] = '<use xlink:href="#tri2" x="650" y="1300';
  svgData[430] = '<use xlink:href="#tri" x="700" y="1300';
  svgData[431] = '<use xlink:href="#tri2" x="750" y="1300';
  svgData[432] = '<use xlink:href="#tri" x="0" y="1350';
  svgData[433] = '<use xlink:href="#tri2" x="50" y="1350';
  svgData[434] = '<use xlink:href="#tri" x="100" y="1350';
  svgData[435] = '<use xlink:href="#tri2" x="150" y="1350';
  svgData[436] = '<use xlink:href="#tri" x="200" y="1350';
  svgData[437] = '<use xlink:href="#tri2" x="250" y="1350';
  svgData[438] = '<use xlink:href="#tri" x="300" y="1350';
  svgData[439] = '<use xlink:href="#tri2" x="350" y="1350';
  svgData[440] = '<use xlink:href="#tri" x="400" y="1350';
  svgData[441] = '<use xlink:href="#tri2" x="450" y="1350';
  svgData[442] = '<use xlink:href="#tri" x="500" y="1350';
  svgData[443] = '<use xlink:href="#tri2" x="550" y="1350';
  svgData[444] = '<use xlink:href="#tri" x="600" y="1350';
  svgData[445] = '<use xlink:href="#tri2" x="650" y="1350';
  svgData[446] = '<use xlink:href="#tri" x="700" y="1350';
  svgData[447] = '<use xlink:href="#tri2" x="750" y="1350';
  svgData[448] = '<use xlink:href="#tri" x="0" y="1400';
  svgData[449] = '<use xlink:href="#tri2" x="50" y="1400';
  svgData[450] = '<use xlink:href="#tri" x="100" y="1400';
  svgData[451] = '<use xlink:href="#tri2" x="150" y="1400';
  svgData[452] = '<use xlink:href="#tri" x="200" y="1400';
  svgData[453] = '<use xlink:href="#tri2" x="250" y="1400';
  svgData[454] = '<use xlink:href="#tri" x="300" y="1400';
  svgData[455] = '<use xlink:href="#tri2" x="350" y="1400';
  svgData[456] = '<use xlink:href="#tri" x="400" y="1400';
  svgData[457] = '<use xlink:href="#tri2" x="450" y="1400';
  svgData[458] = '<use xlink:href="#tri" x="500" y="1400';
  svgData[459] = '<use xlink:href="#tri2" x="550" y="1400';
  svgData[460] = '<use xlink:href="#tri" x="600" y="1400';
  svgData[461] = '<use xlink:href="#tri2" x="650" y="1400';
  svgData[462] = '<use xlink:href="#tri" x="700" y="1400';
  svgData[463] = '<use xlink:href="#tri2" x="750" y="1400';
  svgData[464] = '<use xlink:href="#tri" x="0" y="1450';
  svgData[465] = '<use xlink:href="#tri2" x="50" y="1450';
  svgData[466] = '<use xlink:href="#tri" x="100" y="1450';
  svgData[467] = '<use xlink:href="#tri2" x="150" y="1450';
  svgData[468] = '<use xlink:href="#tri" x="200" y="1450';
  svgData[469] = '<use xlink:href="#tri2" x="250" y="1450';
  svgData[470] = '<use xlink:href="#tri" x="300" y="1450';
  svgData[471] = '<use xlink:href="#tri2" x="350" y="1450';
  svgData[472] = '<use xlink:href="#tri" x="400" y="1450';
  svgData[473] = '<use xlink:href="#tri2" x="450" y="1450';
  svgData[474] = '<use xlink:href="#tri" x="500" y="1450';
  svgData[475] = '<use xlink:href="#tri2" x="550" y="1450';
  svgData[476] = '<use xlink:href="#tri" x="600" y="1450';
  svgData[477] = '<use xlink:href="#tri2" x="650" y="1450';
  svgData[478] = '<use xlink:href="#tri" x="700" y="1450';
  svgData[479] = '<use xlink:href="#tri2" x="750" y="1450';
  svgData[480] = '<use xlink:href="#tri" x="0" y="1500';
  svgData[481] = '<use xlink:href="#tri2" x="50" y="1500';
  svgData[482] = '<use xlink:href="#tri" x="100" y="1500';
  svgData[483] = '<use xlink:href="#tri2" x="150" y="1500';
  svgData[484] = '<use xlink:href="#tri" x="200" y="1500';
  svgData[485] = '<use xlink:href="#tri2" x="250" y="1500';
  svgData[486] = '<use xlink:href="#tri" x="300" y="1500';
  svgData[487] = '<use xlink:href="#tri2" x="350" y="1500';
  svgData[488] = '<use xlink:href="#tri" x="400" y="1500';
  svgData[489] = '<use xlink:href="#tri2" x="450" y="1500';
  svgData[490] = '<use xlink:href="#tri" x="500" y="1500';
  svgData[491] = '<use xlink:href="#tri2" x="550" y="1500';
  svgData[492] = '<use xlink:href="#tri" x="600" y="1500';
  svgData[493] = '<use xlink:href="#tri2" x="650" y="1500';
  svgData[494] = '<use xlink:href="#tri" x="700" y="1500';
  svgData[495] = '<use xlink:href="#tri2" x="750" y="1500';
  svgData[496] = '<use xlink:href="#tri" x="0" y="1550';
  svgData[497] = '<use xlink:href="#tri2" x="50" y="1550';
  svgData[498] = '<use xlink:href="#tri" x="100" y="1550';
  svgData[499] = '<use xlink:href="#tri2" x="150" y="1550';
  svgData[500] = '<use xlink:href="#tri" x="200" y="1550';
  svgData[501] = '<use xlink:href="#tri2" x="250" y="1550';
  svgData[502] = '<use xlink:href="#tri" x="300" y="1550';
  svgData[503] = '<use xlink:href="#tri2" x="350" y="1550';
  svgData[504] = '<use xlink:href="#tri" x="400" y="1550';
  svgData[505] = '<use xlink:href="#tri2" x="450" y="1550';
  svgData[506] = '<use xlink:href="#tri" x="500" y="1550';
  svgData[507] = '<use xlink:href="#tri2" x="550" y="1550';
  svgData[508] = '<use xlink:href="#tri" x="600" y="1550';
  svgData[509] = '<use xlink:href="#tri2" x="650" y="1550';
  svgData[510] = '<use xlink:href="#tri" x="700" y="1550';
  svgData[511] = '<use xlink:href="#tri2" x="750" y="1550';




  var svgBackgroundColor = {};
  svgBackgroundColor[0] = '#800000"/>';
  svgBackgroundColor[1] = '#8B0000"/>';
  svgBackgroundColor[2] = '#A52A2A"/>';
  svgBackgroundColor[3] = '#B22222"/>';
  svgBackgroundColor[4] = '#DC143C"/>';
  svgBackgroundColor[5] = '#FF0000"/>';
  svgBackgroundColor[6] = '#FF6347"/>';
  svgBackgroundColor[7] = '#FF7F50"/>';
  svgBackgroundColor[8] = '#CD5C5C"/>';
  svgBackgroundColor[9] = '#F08080"/>';
  svgBackgroundColor[10] = '#E9967A"/>';
  svgBackgroundColor[11] = '#FA8072"/>';
  svgBackgroundColor[12] = '#FFA07A"/>';
  svgBackgroundColor[13] = '#FF4500"/>';
  svgBackgroundColor[14] = '#FF8C00"/>';
  svgBackgroundColor[15] = '#FFA500"/>';
  svgBackgroundColor[16] = '#FFD700"/>';
  svgBackgroundColor[17] = '#B8860B"/>';
  svgBackgroundColor[18] = '#DAA520"/>';
  svgBackgroundColor[19] = '#EEE8AA"/>';
  svgBackgroundColor[20] = '#BDB76B"/>';
  svgBackgroundColor[21] = '#F0E68C"/>';
  svgBackgroundColor[22] = '#808000"/>';
  svgBackgroundColor[23] = '#FFFF00"/>';
  svgBackgroundColor[24] = '#9ACD32"/>';
  svgBackgroundColor[25] = '#556B2F"/>';
  svgBackgroundColor[26] = '#6B8E23"/>';
  svgBackgroundColor[27] = '#7CFC00"/>';
  svgBackgroundColor[28] = '#7FFF00"/>';
  svgBackgroundColor[29] = '#ADFF2F"/>';
  svgBackgroundColor[30] = '#006400"/>';
  svgBackgroundColor[31] = '#008000"/>';
  svgBackgroundColor[32] = '#228B22"/>';
  svgBackgroundColor[33] = '#00FF00"/>';
  svgBackgroundColor[34] = '#32CD32"/>';
  svgBackgroundColor[35] = '#90EE90"/>';
  svgBackgroundColor[36] = '#98FB98"/>';
  svgBackgroundColor[37] = '#8FBC8F"/>';
  svgBackgroundColor[38] = '#00FA9A"/>';
  svgBackgroundColor[39] = '#00FF7F"/>';
  svgBackgroundColor[40] = '#2E8B57"/>';
  svgBackgroundColor[41] = '#66CDAA"/>';
  svgBackgroundColor[42] = '#3CB371"/>';
  svgBackgroundColor[43] = '#20B2AA"/>';
  svgBackgroundColor[44] = '#2F4F4F"/>';
  svgBackgroundColor[45] = '#008080"/>';
  svgBackgroundColor[46] = '#008B8B"/>';
  svgBackgroundColor[47] = '#00FFFF"/>';
  svgBackgroundColor[48] = '#00FFFF"/>';
  svgBackgroundColor[49] = '#E0FFFF"/>';
  svgBackgroundColor[50] = '#00CED1"/>';
  svgBackgroundColor[51] = '#40E0D0"/>';
  svgBackgroundColor[52] = '#48D1CC"/>';
  svgBackgroundColor[53] = '#AFEEEE"/>';
  svgBackgroundColor[54] = '#7FFFD4"/>';
  svgBackgroundColor[55] = '#B0E0E6"/>';
  svgBackgroundColor[56] = '#5F9EA0"/>';
  svgBackgroundColor[57] = '#4682B4"/>';
  svgBackgroundColor[58] = '#6495ED"/>';
  svgBackgroundColor[59] = '#00BFFF"/>';
  svgBackgroundColor[60] = '#1E90FF"/>';
  svgBackgroundColor[61] = '#ADD8E6"/>';
  svgBackgroundColor[62] = '#87CEEB"/>';
  svgBackgroundColor[63] = '#87CEFA"/>';
  svgBackgroundColor[64] = '#191970"/>';
  svgBackgroundColor[65] = '#000080"/>';
  svgBackgroundColor[66] = '#00008B"/>';
  svgBackgroundColor[67] = '#0000CD"/>';
  svgBackgroundColor[68] = '#0000FF"/>';
  svgBackgroundColor[69] = '#4169E1"/>';
  svgBackgroundColor[70] = '#8A2BE2"/>';
  svgBackgroundColor[71] = '#4B0082"/>';
  svgBackgroundColor[72] = '#483D8B"/>';
  svgBackgroundColor[73] = '#6A5ACD"/>';
  svgBackgroundColor[74] = '#7B68EE"/>';
  svgBackgroundColor[75] = '#9370DB"/>';
  svgBackgroundColor[76] = '#8B008B"/>';
  svgBackgroundColor[77] = '#9400D3"/>';
  svgBackgroundColor[78] = '#9932CC"/>';
  svgBackgroundColor[79] = '#BA55D3"/>';
  svgBackgroundColor[80] = '#800080"/>';
  svgBackgroundColor[81] = '#D8BFD8"/>';
  svgBackgroundColor[82] = '#DDA0DD"/>';
  svgBackgroundColor[83] = '#EE82EE"/>';
  svgBackgroundColor[84] = '#FF00FF"/>';
  svgBackgroundColor[85] = '#DA70D6"/>';
  svgBackgroundColor[86] = '#C71585"/>';
  svgBackgroundColor[87] = '#DB7093"/>';
  svgBackgroundColor[88] = '#FF1493"/>';
  svgBackgroundColor[89] = '#FF69B4"/>';
  svgBackgroundColor[90] = '#FFB6C1"/>';
  svgBackgroundColor[91] = '#FFC0CB"/>';
  svgBackgroundColor[92] = '#FAEBD7"/>';
  svgBackgroundColor[93] = '#F5F5DC"/>';
  svgBackgroundColor[94] = '#FFE4C4"/>';
  svgBackgroundColor[95] = '#FFEBCD"/>';
  svgBackgroundColor[96] = '#F5DEB3"/>';
  svgBackgroundColor[97] = '#FFF8DC"/>';
  svgBackgroundColor[98] = '#FFFACD"/>';
  svgBackgroundColor[99] = '#FAFAD2"/>';
  svgBackgroundColor[100] = '#FFFFE0"/>';
  svgBackgroundColor[101] = '#8B4513"/>';
  svgBackgroundColor[102] = '#A0522D"/>';
  svgBackgroundColor[103] = '#D2691E"/>';
  svgBackgroundColor[104] = '#CD853F"/>';
  svgBackgroundColor[105] = '#F4A460"/>';
  svgBackgroundColor[106] = '#DEB887"/>';
  svgBackgroundColor[107] = '#D2B48C"/>';
  svgBackgroundColor[108] = '#BC8F8F"/>';
  svgBackgroundColor[109] = '#FFE4B5"/>';
  svgBackgroundColor[110] = '#FFDEAD"/>';
  svgBackgroundColor[111] = '#FFDAB9"/>';
  svgBackgroundColor[112] = '#FFE4E1"/>';
  svgBackgroundColor[113] = '#FFF0F5"/>';
  svgBackgroundColor[114] = '#FAF0E6"/>';
  svgBackgroundColor[115] = '#FDF5E6"/>';
  svgBackgroundColor[116] = '#FFEFD5"/>';
  svgBackgroundColor[117] = '#FFF5EE"/>';
  svgBackgroundColor[118] = '#F5FFFA"/>';
  svgBackgroundColor[119] = '#708090"/>';
  svgBackgroundColor[120] = '#778899"/>';
  svgBackgroundColor[121] = '#B0C4DE"/>';
  svgBackgroundColor[122] = '#E6E6FA"/>';
  svgBackgroundColor[123] = '#FFFAF0"/>';
  svgBackgroundColor[124] = '#F0F8FF"/>';
  svgBackgroundColor[125] = '#F8F8FF"/>';
  svgBackgroundColor[126] = '#F0FFF0"/>';
  svgBackgroundColor[127] = '#FFFFF0"/>';
  svgBackgroundColor[128] = '#F0FFFF"/>';
  svgBackgroundColor[129] = '#FFFAFA"/>';
  svgBackgroundColor[130] = '#000000"/>';
  svgBackgroundColor[131] = '#696969"/>';
  svgBackgroundColor[132] = '#808080"/>';
  svgBackgroundColor[133] = '#A9A9A9"/>';
  svgBackgroundColor[134] = '#C0C0C0"/>';
  svgBackgroundColor[135] = '#D3D3D3"/>';
  svgBackgroundColor[136] = '#DCDCDC"/>';
  svgBackgroundColor[137] = '#FFFFFF"/>';


  let submitCustom = async (_tokenID) => {
    /* global BigInt */


    let tempPeices1 = peices.slice(0,256)
    let tempPeices2 = peices.slice(256, 512)

    let data1 = BigInt('0b'+tempPeices1.join("").toString())
    let data2 = BigInt('0b'+tempPeices2.join("").toString())

    let tempColorOne1 = color1.slice(0,256)
    let tempColorOne2 = color1.slice(256, 512)
    let colorOne1 = BigInt('0b'+tempColorOne1.join("").toString())
    let colorOne2 = BigInt('0b'+tempColorOne2.join("").toString())

    let tempColorTwo1 = color2.slice(0,256)
    let tempColorTwo2 = color2.slice(256, 512)

    let colorTwo1 = BigInt('0b'+tempColorTwo1.join("").toString())
    let colorTwo2 = BigInt('0b'+tempColorTwo2.join("").toString())

    // await metakays.methods.kustomize()
        // await metakays.methods.kustomize(data1, data2, [colorOne1, colorOne2, colorTwo1, colorTwo2], four_colors, tokenID).send({
        //   from: userAccount,

        //   })
        setMsg1("Head to https://optimistic.etherscan.io/address/0x151c384c9d8aece2dfb50e61890217128f90c321#writeContract and connect your OPTIMISM wallet. Scroll down to the *kustomize* function and input these values:")
        setMsg2("_data1: "+String(data1))
        setMsg3("_data2: "+String(data2))
        setMsg4("_colors: "+String(colorOne1)+','+String(colorOne2)+','+String(colorTwo1)+','+String(colorTwo2))
        setMsg5("_colorSelectors: "+String(four_colors[0])+','+String(four_colors[1])+','+String(four_colors[2])+','+String(four_colors[3]))
        setMsg6("_itemID: YOUR_TOKEN_ID")
        }


  // const handleShow = async (_value) => {
  //   setCustomChooser(_value)
  //   if (showKustomModal) {
  //     setKustomModal(false)
  //   } else {
  //     setKustomModal(true)
  //   }
  // };

  return (
        <div className="Custom">
        {/* backgroundColor */}
        {showKustomModal ?
          <div className="CustomSVGRightSide">
            <div className='over'><CustomIndividualOPT/></div>
            <div className="CustomHeader">MENU</div>
            <Link to='/kustomoptmenu' className="itemDropKustomETH">BACK</Link>
            <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, KUSTOMIZE ON OPTIMISM BY HEADING TO https://optimistic.etherscan.io/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER OPTIMISM AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: KUSTOMIZE ON HERE, THEN COPY AND PASTE THE DATA BELOW INTO THE "KUSTOMIZE" FUNCTION ON OPTIMISM AND SUBMIT.</div>
        <div className="instructions">YOU MAY CHOOSE 4 COLORS (DEFAULT COLOR, COLOR #2, COLOR #3, COLOR #4). </div>

        {/* <div className="itemDropKustomETH" onClick={() => handleShow(24)}>LOAD</div> */}
            {/* <div className="itemDropKustomETH" onClick={() => handleShow(29)}>RESET (BLANK)</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT DEFAULT COLOR</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR #4</div>


            <div className="itemDropKustomETH" onClick={() => handleShow(2)}>DRAW WITH COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(1)}>DRAW WITH COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(0)}>DRAW WITH COLOR #4</div> */}
                        <OPTApp/>


            <div className="itemDropKustomETH" onClick={() => submitCustom()}>SUBMIT</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>
            <div className="customMessage">{msg3}</div>
            <div className="customMessage">{msg4}</div>
            <div className="customMessage">{msg5}</div>
            <div className="customMessage">{msg6}</div>


          </div>
        :
        <div className="CustomSVGRightSide">


          <div className="CustomHeader">MENU</div>
          <Link to='/kustomoptmenu' className="itemDropKustomETH">BACK</Link>
          <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, KUSTOMIZE ON OPTIMISM BY HEADING TO https://optimistic.etherscan.io/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER OPTIMISM AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: KUSTOMIZE ON HERE, THEN COPY AND PASTE THE DATA BELOW INTO THE "KUSTOMIZE" FUNCTION ON OPTIMISM AND SUBMIT.</div>
        <div className="instructions">YOU MAY CHOOSE 4 COLORS (DEFAULT COLOR, COLOR #2, COLOR #3, COLOR #4). </div>
        {/* <div className="itemDropKustomETH" onClick={() => handleShow(24)}>LOAD</div> */}
            {/* <div className="itemDropKustomETH" onClick={() => handleShow(29)}>RESET (BLANK)</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT DEFAULT COLOR</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR #4</div>


            <div className="itemDropKustomETH" onClick={() => handleShow(2)}>DRAW WITH COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(1)}>DRAW WITH COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(0)}>DRAW WITH COLOR #4</div> */}
            <OPTApp/>

            <div className="itemDropKustomETH" onClick={() => submitCustom()}>SUBMIT</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>
            <div className="customMessage">{msg3}</div>
            <div className="customMessage">{msg4}</div>
            <div className="customMessage">{msg5}</div>
            <div className="customMessage">{msg6}</div>



        </div>}
        <div className="CustomSVGRightSide">
            {/* background  */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="{w}pt" height="{h}pt" viewBox="0 0 800.000000 1600.000000" preserveAspectRatio="xMidYMid meet" xmlnsXlink="http://www.w3.org/1999/xlink">
              {parse('<defs> <polygon id="tri" style="" points="0 0, 100 100, 200 0" transform="scale(.5,.5)"/> </defs>')}
              {parse('<defs> <polygon id="tri2" style="" points="0 100, 200 100, 100 0" transform="scale(.5,.5)"/> </defs>')}
            {parse('<g transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> ')}
            {parse('<path d="M0 8000 l0 -8000 4000 0 4000 0 0 8000 0 8000 -4000 0 -4000 0 0 -8000z" fill="' + String(svgBackgroundColor[bgColor]) + '</g>')}



            {/* {parse('<use xlinkHref="#cube" x="487" y="540" fill="#000000"/>')} */}

            {/* {peices.split("").forEach(character => {if (character == "0") {return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')}})} */}

            {peices.map((value, index) => {

let color;

if (parseInt(value) === 1) {
  color = svgBackgroundColor[four_colors[0]]
} else {
  if (parseInt(color1[index]) === 1){
    color = svgBackgroundColor[four_colors[1]]
  } else {
    if (parseInt(color2[index]) === 1){
      color = svgBackgroundColor[four_colors[2]]
    } else {
      color = svgBackgroundColor[four_colors[3]]
    }
  }
}
  // let color = color1[index] == 1 ? svgBackgroundColor[three_colors[0]] : color2[index] == 1 ? svgBackgroundColor[three_colors[1]] : svgBackgroundColor[three_colors[2]]
  let temp = svgData[index] + '" fill="' + color;
  return parse(temp)
  // return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')

})}

{parse('<g transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)" fill="#F5F5F5" stroke="none"> <path d="M6720 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z M7440 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z"/> </g></svg>')}
</svg>
          </div>
      </div>
  );
}






