import "./Custom8888Menu.css";
import { Link } from 'react-router-dom'

export default function CustomETHMenu() {
  return (
    <div className="RealCustomMenu">
      <div className="CustomMenuHeader">SELECT</div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustom'>KUSTOMIZE</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/finality'>FINALITY</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomMenu'>BACK</Link>
      </div>
    </div>
  );
}
