import React, {useEffect } from "react";
import "./NotFound.css";

export default function NotFound() {
  useEffect(() => {
    alert("ERROR! PLEASE RESTART METAVERSE-8888!")
  }, []);

  return (
    <div className="NotFound">
      <img className='nonFoundGameboyImage' src="/images/GameboyError.png" alt=''></img>
      {/* <h3 className="overText">Error! Restart Gameboy Metaverse...</h3> */}
    </div>
  );
}
