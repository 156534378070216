import React, {useEffect } from "react";
import "./Custom.css";
import parse from 'html-react-parser';
import CustomIndividual from "./CustomIndividual";
import { useAppContext } from "../libs/contextLib";
import web3 from '../web3';
import metakays8888 from '../metakays8888';
import { Link } from 'react-router-dom'



export default function Custom() {

  const { showKustomModal, setKustomModal, backgroundColor, hairColor, eyesColor, mouthColor, bodyColor, skinColor, shirtText, misc1Style, misc2Style ,hairStyle ,mouthStyle, setCustomChooser , customArray, userAccount,  setMyMETAKAYS, setUserAccount, tokenID, misc1Color,  misc2Color} = useAppContext();

  useEffect(() => {
    const getMETAKAYS = async () => {
      alert ("REMEMBER TO 'LOAD' BEFORE KUSTOMIZING. \nSHIRT TEXT MAY ONLY BE UP TO 8 CAPITAL LETTERS (NO SPACES, NO SPECIAL CHARS, NO NUMBERS).")

      try {

      const accounts = await web3.eth.getAccounts();
      setUserAccount(accounts[0])
      let supplyOfOwner = await metakays8888.methods.balanceOf(accounts[0]).call()
      for (let i = 0; i<supplyOfOwner; i++) {
        let tempID = await metakays8888.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        let tempStruct = await metakays8888.methods.features(tempID).call()

        setMyMETAKAYS(myMETAKAYS => ({...myMETAKAYS,
      [tempID]: Object.values(tempStruct).slice(0,13)}))

        }

      } catch (error) {
        console.log(error)

      }
      }

    getMETAKAYS();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps




  let submitCustom = async (_tokenID) => {
    alert("MAKE SURE YOU LOADED FIRST & THAT YOUR SHIRT TEXT IS ONLY UP TO 8 CAPITAL LETTERS (NO SPACES, NO SPECIAL CHARS) OR YOUR TRANSACTION WILL NOT PROCESS. \n ONCE YOUR TRANSACTION PROCESSES ON-CHAIN, HEAD TO OPENSEA AND CLICK REFRESH (USUALLY TAKES 10+ MINUTES TO SHOW UP).")
        await metakays8888.methods.kustomize(_tokenID, [customArray["background"],customArray["bodyColor"],customArray["skinColor"],customArray["hairColor"],customArray["mouthColor"],customArray["hairStyle"],customArray["mouthStyle"],customArray["misc1"],customArray["miscOneColor"],customArray["misc2"], customArray["miscTwoColor"],customArray["eyesColor"]], shirtText).send({
          from: userAccount,

          })
        }

  const multiParse = (_value) => {
    var temp = _value.split("<path")
    for (var i=temp.length; i< 5; i++){
      temp[i] = ""
    }
    var temp0 = (temp[0].length > 1)? "<path"+temp[0] : ""
    var temp1 = (temp[1].length > 1) ? ("<path"+temp[1]) : ""
    var temp2 = (temp[2].length > 1) ? ("<path"+temp[2]) : ""
    var temp3 = (temp[3].length > 1) ? "<path"+temp[3] : ""
    var temp4 = (temp[4].length > 1) ? "<path"+temp[4] : ""

    return [parse(temp0),parse(temp1),parse(temp2),parse(temp3),parse(temp4)]
  }

  const handleShow = async (_value) => {
    setCustomChooser(_value)
    if (showKustomModal) {
      setKustomModal(false)
    } else {
      setKustomModal(true)
    }
  };

  return (
        <div className="Custom">
        {/* backgroundColor */}
        {showKustomModal ?
          <div className="CustomSVGRightSide">
            <div className='over'><CustomIndividual/></div>
            <div className="CustomHeader">MENU</div>
            <Link className="itemDrop" to='/kustom8888menu'>BACK</Link>
            <div className="itemDrop" onClick={() => handleShow(13)}>LOAD</div>
            <div className="itemDrop" onClick={() => handleShow(0)}>BACKGROUND COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(1)}>SKIN COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(2)}>BODY COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(3)}>SHIRT TEXT</div>
            <div className="itemDrop" onClick={() => handleShow(4)}>EYE COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(5)}>MOUTH STYLE</div>
            <div className="itemDrop" onClick={() => handleShow(6)}>MOUTH COLOR</div>
            <div className="itemDrop" onClick={() => handleShow(7)}>MISC ONE</div>
            <div className="itemDrop" onClick={() => handleShow(8)}>MISC ONE COLOR</div>
            <div className="itemDrop" onClick={() => handleShow(9)}>MISC TWO</div>
            <div className="itemDrop" onClick={() => handleShow(10)}>MISC TWO COLOR</div>
            <div className="itemDrop" onClick={() => handleShow(11)}>HAIR STYLE</div>
            <div className="itemDrop" onClick={() => handleShow(12)}>HAIR COLOR</div>
            <div className="itemDrop" onClick={() => submitCustom(tokenID)}>SUBMIT</div>


          </div>
        :
        <div className="CustomSVGRightSide">
            <div className="CustomHeader">MENU</div>
            <Link className="itemDrop" to='/kustom8888menu'>BACK</Link>
          <div className="itemDrop" onClick={() => handleShow(13)}>LOAD</div>
          <div className="itemDrop" onClick={() => handleShow(0)}>BACKGROUND COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(1)}>SKIN COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(2)}>BODY COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(3)}>SHIRT TEXT</div>
            <div className="itemDrop" onClick={() => handleShow(4)}>EYE COLORS</div>
            <div className="itemDrop" onClick={() => handleShow(5)}>MOUTH STYLE</div>
            <div className="itemDrop" onClick={() => handleShow(6)}>MOUTH COLOR</div>
            <div className="itemDrop" onClick={() => handleShow(7)}>MISC ONE</div>
            <div className="itemDrop" onClick={() => handleShow(8)}>MISC ONE COLOR</div>
            <div className="itemDrop" onClick={() => handleShow(9)}>MISC TWO</div>
            <div className="itemDrop" onClick={() => handleShow(10)}>MISC TWO COLOR</div>
            <div className="itemDrop" onClick={() => handleShow(11)}>HAIR STYLE</div>
            <div className="itemDrop" onClick={() => handleShow(12)}>HAIR COLOR</div>
            <div className="itemDrop" onClick={() => submitCustom(tokenID)}>SUBMIT</div>
        </div>}
        <div className="CustomSVGRightSide">
            {/* background  */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="{w}pt" height="{h} pt" viewBox="0 0 888.000000 888.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,888.000000) scale(0.100000,-0.100000)">
            {parse('<path d="M0 4440 l0 -4440 4440 0 4440 0 0 4440 0 4440 -4440 0 -4440 0 0 -4440z" fill="'+backgroundColor)}
            {/* body */}
            {multiParse('<path d="M3530 3270 l0 -130 -395 0 -395 0 0 -130 0 -130 -515 0 -515 0 0 -130 0 -130 -130 0 -130 0 0 -1310 0 -1310 130 0 130 0 0 1310 0 1310 515 0 515 0 0 130 0 130 395 0 395 0 0 130 0 130 910 0 910 0 0 -130 0 -130 395 0 395 0 0 -130 0 -130 515 0 515 0 0 -1310 0 -1310 130 0 130 0 0 1310 0 1310 -130 0 -130 0 0 130 0 130 -515 0 -515 0 0 130 0 130 -395 0 -395 0 0 130 0 130 -910 0 -910 0 0 -130z M2610 660 l0 -660 130 0 130 0 0 660 0 660 -130 0 -130 0 0 -660z M6010 660 l0 -660 130 0 130 0 0 660 0 660 -130 0 -130 0 0 -660z"/> <path d="M3530 3010 l0 -130 -395 0 -395 0 0 -130 0 -130 -515 0 -515 0 0 -1310 0 -1310 450 0 450 0 0 660 0 660 130 0 130 0 0 -660 0 -660 1570 0 1570 0 0 660 0 660 130 0 130 0 0 -660 0 -660 450 0 450 0 0 1310 0 1310 -515 0 -515 0 0 130 0 130 -395 0 -395 0 0 130 0 130 -910 0 -910 0 0 -130z" fill="'+bodyColor)}
            {/* custom text */}
            {/* <text x="44188" y="-5500" font-size="4000px" */}
            {parse('<g transform="translate(0.000000,888.000000) scale(0.100000,-0.100000)"><text x="44488" y="-8800" font-size="4800px" font-family="impact" font-weight="bold" dominant-baseline="middle" text-anchor="middle">'+shirtText+'</text></g>')}
            {/* shoulder number */}
            {parse('</text></g><g transform="translate(0.000000,888.000000) scale(0.100000,-0.100000)"><text x="71188" y="-14488" font-size="2888px" font-family="impact"  font-weight="bold" dominant-baseline="center" text-anchor="end">'+tokenID+'</text></g>')}
            {/* face */}
            {multiParse('<path d="M3130 5540 l0 -1600 100 0 100 0 0 -185 0 -185 195 0 195 0 0 -85 0 -85 130 0 130 0 0 215 0 215 -195 0 -195 0 0 185 0 185 -100 0 -100 0 0 1340 0 1340 1050 0 1050 0 0 -1340 0 -1340 -100 0 -100 0 0 -185 0 -185 -195 0 -195 0 0 -215 0 -215 130 0 130 0 0 85 0 85 195 0 195 0 0 185 0 185 100 0 100 0 0 1600 0 1600 -1310 0 -1310 0 0 -1600z"/> <path d="M3390 5540 l0 -1340 100 0 100 0 0 -185 0 -185 195 0 195 0 0 -215 0 -215 460 0 460 0 0 215 0 215 195 0 195 0 0 185 0 185 100 0 100 0 0 1340 0 1340 -1050 0 -1050 0 0 -1340z" fill="'+skinColor)}
            {/* eyes */}
            {parse('<path d="M3750 5770 l0 -120 -120 0 -120 0 0 -120 0 -120 120 0 120 0 0 -240 0 -240 120 0 120 0 0 240 0 240 120 0 120 0 0 120 0 120 -120 0 -120 0 0 120 0 120 -120 0 -120 0 0 -120z M4890 5770 l0 -120 -120 0 -120 0 0 -120 0 -120 120 0 120 0 0 -240 0 -240 120 0 120 0 0 240 0 240 120 0 120 0 0 120 0 120 -120 0 -120 0 0 120 0 120 -120 0 -120 0 0 -120z" fill="'+eyesColor)}
            {/* mouth */}
            {multiParse(mouthStyle+mouthColor)}
            {multiParse(misc1Style+misc1Color)}
            {multiParse(misc2Style+misc2Color)}
            {/* hair */}
            {multiParse(hairStyle+hairColor)}
            </g></svg>
          </div>
      </div>

  );
}






