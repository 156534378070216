import "./FamilyPhoto.css";
import React, {useState } from "react";
import metakays from '../metakays';

export default function FamilyPhotoUpdate() {


  const [totalKustoms, setTotalKustoms] = useState(0);
  const [totalNUMBER, setNUMBER] = useState(0);




  let submitCustom = async () => {
    try {
      let arrayOfKustoms = [];

    for (let i = parseInt(totalNUMBER)-2222; i<parseInt(totalNUMBER)+1; i++) {
        let tempStruct = await metakays.methods.features(i).call()
        if (Object.values(tempStruct).slice(0,4).reduce((a, b) => a + b, 0) !== "00000") {
          arrayOfKustoms.push(i)
          setTotalKustoms(i)
        }
    }
    // let url ="/.netlify/functions/getIDs?id=" + String(arrayOfKustoms)
    // const docClientCheck = await fetch(url).then((res) => res.json());

    } catch (error) {
      console.log(error)
    }
    }



  return (
<div className="RealHome">
    <div className="regular">


    <div className="customIndividual">
            <div><input className="digiTextBox" type="text" placeholder="NUMBER" onChange={e => setNUMBER(e.target.value)} /></div>
        </div>


    <div onClick={submitCustom}>UPDATE</div>
    {totalKustoms}
       </div>

    </div>




  )
}