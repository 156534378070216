import React, {useEffect, useState} from "react";
import "./CustomPOLYGON.css";
import parse from 'html-react-parser';
import CustomIndividualPOLYGONBackground from "./CustomIndividualPOLYGONBackground";
import { useAppContext } from "../../libs/contextLib";
import web3 from '../../web3';
import { Link } from 'react-router-dom'



export default function CustomPOLYGONFinality() {

  const { four_colors, bgColor, color1, color2, peices, showKustomModal, setUserAccount} = useAppContext();

  // const [peices, setPeices] = useState([0,0,1,0]);

  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");

  useEffect(() => {
    const getMETAKAYS = async () => {

      try {

      const accounts = await web3.eth.getAccounts();
      setUserAccount(accounts[0])

      } catch (error) {
        console.log(error)

      }
      }

    getMETAKAYS();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var svgData = {}
  svgData[0] = '<use xlink:href="#cube" x="487" y="540';
  svgData[1] = '<use xlink:href="#cube" x="543" y="568';
  svgData[2] = '<use xlink:href="#cube" x="599" y="596';
  svgData[3] = '<use xlink:href="#cube" x="655" y="624';
  svgData[4] = '<use xlink:href="#cube" x="711" y="652';
  svgData[5] = '<use xlink:href="#cube" x="767" y="680';
  svgData[6] = '<use xlink:href="#cube" x="823" y="708';
  svgData[7] = '<use xlink:href="#cube" x="879" y="736';
  svgData[8] = '<use xlink:href="#cube" x="487" y="468';
  svgData[9] = '<use xlink:href="#cube" x="543" y="496';
  svgData[10] = '<use xlink:href="#cube" x="599" y="524';
  svgData[11] = '<use xlink:href="#cube" x="655" y="552';
  svgData[12] = '<use xlink:href="#cube" x="711" y="580';
  svgData[13] = '<use xlink:href="#cube" x="767" y="608';
  svgData[14] = '<use xlink:href="#cube" x="823" y="636';
  svgData[15] = '<use xlink:href="#cube" x="879" y="664';
  svgData[16] = '<use xlink:href="#cube" x="487" y="396';
  svgData[17] = '<use xlink:href="#cube" x="543" y="424';
  svgData[18] = '<use xlink:href="#cube" x="599" y="452';
  svgData[19] = '<use xlink:href="#cube" x="655" y="480';
  svgData[20] = '<use xlink:href="#cube" x="711" y="508';
  svgData[21] = '<use xlink:href="#cube" x="767" y="536';
  svgData[22] = '<use xlink:href="#cube" x="823" y="564';
  svgData[23] = '<use xlink:href="#cube" x="879" y="592';
  svgData[24] = '<use xlink:href="#cube" x="487" y="324';
  svgData[25] = '<use xlink:href="#cube" x="543" y="352';
  svgData[26] = '<use xlink:href="#cube" x="599" y="380';
  svgData[27] = '<use xlink:href="#cube" x="655" y="408';
  svgData[28] = '<use xlink:href="#cube" x="711" y="436';
  svgData[29] = '<use xlink:href="#cube" x="767" y="464';
  svgData[30] = '<use xlink:href="#cube" x="823" y="492';
  svgData[31] = '<use xlink:href="#cube" x="879" y="520';
  svgData[32] = '<use xlink:href="#cube" x="487" y="252';
  svgData[33] = '<use xlink:href="#cube" x="543" y="280';
  svgData[34] = '<use xlink:href="#cube" x="599" y="308';
  svgData[35] = '<use xlink:href="#cube" x="655" y="336';
  svgData[36] = '<use xlink:href="#cube" x="711" y="364';
  svgData[37] = '<use xlink:href="#cube" x="767" y="392';
  svgData[38] = '<use xlink:href="#cube" x="823" y="420';
  svgData[39] = '<use xlink:href="#cube" x="879" y="448';
  svgData[40] = '<use xlink:href="#cube" x="487" y="180';
  svgData[41] = '<use xlink:href="#cube" x="543" y="208';
  svgData[42] = '<use xlink:href="#cube" x="599" y="236';
  svgData[43] = '<use xlink:href="#cube" x="655" y="264';
  svgData[44] = '<use xlink:href="#cube" x="711" y="292';
  svgData[45] = '<use xlink:href="#cube" x="767" y="320';
  svgData[46] = '<use xlink:href="#cube" x="823" y="348';
  svgData[47] = '<use xlink:href="#cube" x="879" y="376';
  svgData[48] = '<use xlink:href="#cube" x="487" y="108';
  svgData[49] = '<use xlink:href="#cube" x="543" y="136';
  svgData[50] = '<use xlink:href="#cube" x="599" y="164';
  svgData[51] = '<use xlink:href="#cube" x="655" y="192';
  svgData[52] = '<use xlink:href="#cube" x="711" y="220';
  svgData[53] = '<use xlink:href="#cube" x="767" y="248';
  svgData[54] = '<use xlink:href="#cube" x="823" y="276';
  svgData[55] = '<use xlink:href="#cube" x="879" y="304';
  svgData[56] = '<use xlink:href="#cube" x="487" y="36';
  svgData[57] = '<use xlink:href="#cube" x="543" y="64';
  svgData[58] = '<use xlink:href="#cube" x="599" y="92';
  svgData[59] = '<use xlink:href="#cube" x="655" y="120';
  svgData[60] = '<use xlink:href="#cube" x="711" y="148';
  svgData[61] = '<use xlink:href="#cube" x="767" y="176';
  svgData[62] = '<use xlink:href="#cube" x="823" y="204';
  svgData[63] = '<use xlink:href="#cube" x="879" y="232';
  svgData[64] = '<use xlink:href="#cube" x="431" y="568';
  svgData[65] = '<use xlink:href="#cube" x="487" y="596';
  svgData[66] = '<use xlink:href="#cube" x="543" y="624';
  svgData[67] = '<use xlink:href="#cube" x="599" y="652';
  svgData[68] = '<use xlink:href="#cube" x="655" y="680';
  svgData[69] = '<use xlink:href="#cube" x="711" y="708';
  svgData[70] = '<use xlink:href="#cube" x="767" y="736';
  svgData[71] = '<use xlink:href="#cube" x="823" y="764';
  svgData[72] = '<use xlink:href="#cube" x="431" y="496';
  svgData[73] = '<use xlink:href="#cube" x="487" y="524';
  svgData[74] = '<use xlink:href="#cube" x="543" y="552';
  svgData[75] = '<use xlink:href="#cube" x="599" y="580';
  svgData[76] = '<use xlink:href="#cube" x="655" y="608';
  svgData[77] = '<use xlink:href="#cube" x="711" y="636';
  svgData[78] = '<use xlink:href="#cube" x="767" y="664';
  svgData[79] = '<use xlink:href="#cube" x="823" y="692';
  svgData[80] = '<use xlink:href="#cube" x="431" y="424';
  svgData[81] = '<use xlink:href="#cube" x="487" y="452';
  svgData[82] = '<use xlink:href="#cube" x="543" y="480';
  svgData[83] = '<use xlink:href="#cube" x="599" y="508';
  svgData[84] = '<use xlink:href="#cube" x="655" y="536';
  svgData[85] = '<use xlink:href="#cube" x="711" y="564';
  svgData[86] = '<use xlink:href="#cube" x="767" y="592';
  svgData[87] = '<use xlink:href="#cube" x="823" y="620';
  svgData[88] = '<use xlink:href="#cube" x="431" y="352';
  svgData[89] = '<use xlink:href="#cube" x="487" y="380';
  svgData[90] = '<use xlink:href="#cube" x="543" y="408';
  svgData[91] = '<use xlink:href="#cube" x="599" y="436';
  svgData[92] = '<use xlink:href="#cube" x="655" y="464';
  svgData[93] = '<use xlink:href="#cube" x="711" y="492';
  svgData[94] = '<use xlink:href="#cube" x="767" y="520';
  svgData[95] = '<use xlink:href="#cube" x="823" y="548';
  svgData[96] = '<use xlink:href="#cube" x="431" y="280';
  svgData[97] = '<use xlink:href="#cube" x="487" y="308';
  svgData[98] = '<use xlink:href="#cube" x="543" y="336';
  svgData[99] = '<use xlink:href="#cube" x="599" y="364';
  svgData[100] = '<use xlink:href="#cube" x="655" y="392';
  svgData[101] = '<use xlink:href="#cube" x="711" y="420';
  svgData[102] = '<use xlink:href="#cube" x="767" y="448';
  svgData[103] = '<use xlink:href="#cube" x="823" y="476';
  svgData[104] = '<use xlink:href="#cube" x="431" y="208';
  svgData[105] = '<use xlink:href="#cube" x="487" y="236';
  svgData[106] = '<use xlink:href="#cube" x="543" y="264';
  svgData[107] = '<use xlink:href="#cube" x="599" y="292';
  svgData[108] = '<use xlink:href="#cube" x="655" y="320';
  svgData[109] = '<use xlink:href="#cube" x="711" y="348';
  svgData[110] = '<use xlink:href="#cube" x="767" y="376';
  svgData[111] = '<use xlink:href="#cube" x="823" y="404';
  svgData[112] = '<use xlink:href="#cube" x="431" y="136';
  svgData[113] = '<use xlink:href="#cube" x="487" y="164';
  svgData[114] = '<use xlink:href="#cube" x="543" y="192';
  svgData[115] = '<use xlink:href="#cube" x="599" y="220';
  svgData[116] = '<use xlink:href="#cube" x="655" y="248';
  svgData[117] = '<use xlink:href="#cube" x="711" y="276';
  svgData[118] = '<use xlink:href="#cube" x="767" y="304';
  svgData[119] = '<use xlink:href="#cube" x="823" y="332';
  svgData[120] = '<use xlink:href="#cube" x="431" y="64';
  svgData[121] = '<use xlink:href="#cube" x="487" y="92';
  svgData[122] = '<use xlink:href="#cube" x="543" y="120';
  svgData[123] = '<use xlink:href="#cube" x="599" y="148';
  svgData[124] = '<use xlink:href="#cube" x="655" y="176';
  svgData[125] = '<use xlink:href="#cube" x="711" y="204';
  svgData[126] = '<use xlink:href="#cube" x="767" y="232';
  svgData[127] = '<use xlink:href="#cube" x="823" y="260';
  svgData[128] = '<use xlink:href="#cube" x="375" y="596';
  svgData[129] = '<use xlink:href="#cube" x="431" y="624';
  svgData[130] = '<use xlink:href="#cube" x="487" y="652';
  svgData[131] = '<use xlink:href="#cube" x="543" y="680';
  svgData[132] = '<use xlink:href="#cube" x="599" y="708';
  svgData[133] = '<use xlink:href="#cube" x="655" y="736';
  svgData[134] = '<use xlink:href="#cube" x="711" y="764';
  svgData[135] = '<use xlink:href="#cube" x="767" y="792';
  svgData[136] = '<use xlink:href="#cube" x="375" y="524';
  svgData[137] = '<use xlink:href="#cube" x="431" y="552';
  svgData[138] = '<use xlink:href="#cube" x="487" y="580';
  svgData[139] = '<use xlink:href="#cube" x="543" y="608';
  svgData[140] = '<use xlink:href="#cube" x="599" y="636';
  svgData[141] = '<use xlink:href="#cube" x="655" y="664';
  svgData[142] = '<use xlink:href="#cube" x="711" y="692';
  svgData[143] = '<use xlink:href="#cube" x="767" y="720';
  svgData[144] = '<use xlink:href="#cube" x="375" y="452';
  svgData[145] = '<use xlink:href="#cube" x="431" y="480';
  svgData[146] = '<use xlink:href="#cube" x="487" y="508';
  svgData[147] = '<use xlink:href="#cube" x="543" y="536';
  svgData[148] = '<use xlink:href="#cube" x="599" y="564';
  svgData[149] = '<use xlink:href="#cube" x="655" y="592';
  svgData[150] = '<use xlink:href="#cube" x="711" y="620';
  svgData[151] = '<use xlink:href="#cube" x="767" y="648';
  svgData[152] = '<use xlink:href="#cube" x="375" y="380';
  svgData[153] = '<use xlink:href="#cube" x="431" y="408';
  svgData[154] = '<use xlink:href="#cube" x="487" y="436';
  svgData[155] = '<use xlink:href="#cube" x="543" y="464';
  svgData[156] = '<use xlink:href="#cube" x="599" y="492';
  svgData[157] = '<use xlink:href="#cube" x="655" y="520';
  svgData[158] = '<use xlink:href="#cube" x="711" y="548';
  svgData[159] = '<use xlink:href="#cube" x="767" y="576';
  svgData[160] = '<use xlink:href="#cube" x="375" y="308';
  svgData[161] = '<use xlink:href="#cube" x="431" y="336';
  svgData[162] = '<use xlink:href="#cube" x="487" y="364';
  svgData[163] = '<use xlink:href="#cube" x="543" y="392';
  svgData[164] = '<use xlink:href="#cube" x="599" y="420';
  svgData[165] = '<use xlink:href="#cube" x="655" y="448';
  svgData[166] = '<use xlink:href="#cube" x="711" y="476';
  svgData[167] = '<use xlink:href="#cube" x="767" y="504';
  svgData[168] = '<use xlink:href="#cube" x="375" y="236';
  svgData[169] = '<use xlink:href="#cube" x="431" y="264';
  svgData[170] = '<use xlink:href="#cube" x="487" y="292';
  svgData[171] = '<use xlink:href="#cube" x="543" y="320';
  svgData[172] = '<use xlink:href="#cube" x="599" y="348';
  svgData[173] = '<use xlink:href="#cube" x="655" y="376';
  svgData[174] = '<use xlink:href="#cube" x="711" y="404';
  svgData[175] = '<use xlink:href="#cube" x="767" y="432';
  svgData[176] = '<use xlink:href="#cube" x="375" y="164';
  svgData[177] = '<use xlink:href="#cube" x="431" y="192';
  svgData[178] = '<use xlink:href="#cube" x="487" y="220';
  svgData[179] = '<use xlink:href="#cube" x="543" y="248';
  svgData[180] = '<use xlink:href="#cube" x="599" y="276';
  svgData[181] = '<use xlink:href="#cube" x="655" y="304';
  svgData[182] = '<use xlink:href="#cube" x="711" y="332';
  svgData[183] = '<use xlink:href="#cube" x="767" y="360';
  svgData[184] = '<use xlink:href="#cube" x="375" y="92';
  svgData[185] = '<use xlink:href="#cube" x="431" y="120';
  svgData[186] = '<use xlink:href="#cube" x="487" y="148';
  svgData[187] = '<use xlink:href="#cube" x="543" y="176';
  svgData[188] = '<use xlink:href="#cube" x="599" y="204';
  svgData[189] = '<use xlink:href="#cube" x="655" y="232';
  svgData[190] = '<use xlink:href="#cube" x="711" y="260';
  svgData[191] = '<use xlink:href="#cube" x="767" y="288';
  svgData[192] = '<use xlink:href="#cube" x="319" y="624';
  svgData[193] = '<use xlink:href="#cube" x="375" y="652';
  svgData[194] = '<use xlink:href="#cube" x="431" y="680';
  svgData[195] = '<use xlink:href="#cube" x="487" y="708';
  svgData[196] = '<use xlink:href="#cube" x="543" y="736';
  svgData[197] = '<use xlink:href="#cube" x="599" y="764';
  svgData[198] = '<use xlink:href="#cube" x="655" y="792';
  svgData[199] = '<use xlink:href="#cube" x="711" y="820';
  svgData[200] = '<use xlink:href="#cube" x="319" y="552';
  svgData[201] = '<use xlink:href="#cube" x="375" y="580';
  svgData[202] = '<use xlink:href="#cube" x="431" y="608';
  svgData[203] = '<use xlink:href="#cube" x="487" y="636';
  svgData[204] = '<use xlink:href="#cube" x="543" y="664';
  svgData[205] = '<use xlink:href="#cube" x="599" y="692';
  svgData[206] = '<use xlink:href="#cube" x="655" y="720';
  svgData[207] = '<use xlink:href="#cube" x="711" y="748';
  svgData[208] = '<use xlink:href="#cube" x="319" y="480';
  svgData[209] = '<use xlink:href="#cube" x="375" y="508';
  svgData[210] = '<use xlink:href="#cube" x="431" y="536';
  svgData[211] = '<use xlink:href="#cube" x="487" y="564';
  svgData[212] = '<use xlink:href="#cube" x="543" y="592';
  svgData[213] = '<use xlink:href="#cube" x="599" y="620';
  svgData[214] = '<use xlink:href="#cube" x="655" y="648';
  svgData[215] = '<use xlink:href="#cube" x="711" y="676';
  svgData[216] = '<use xlink:href="#cube" x="319" y="408';
  svgData[217] = '<use xlink:href="#cube" x="375" y="436';
  svgData[218] = '<use xlink:href="#cube" x="431" y="464';
  svgData[219] = '<use xlink:href="#cube" x="487" y="492';
  svgData[220] = '<use xlink:href="#cube" x="543" y="520';
  svgData[221] = '<use xlink:href="#cube" x="599" y="548';
  svgData[222] = '<use xlink:href="#cube" x="655" y="576';
  svgData[223] = '<use xlink:href="#cube" x="711" y="604';
  svgData[224] = '<use xlink:href="#cube" x="319" y="336';
  svgData[225] = '<use xlink:href="#cube" x="375" y="364';
  svgData[226] = '<use xlink:href="#cube" x="431" y="392';
  svgData[227] = '<use xlink:href="#cube" x="487" y="420';
  svgData[228] = '<use xlink:href="#cube" x="543" y="448';
  svgData[229] = '<use xlink:href="#cube" x="599" y="476';
  svgData[230] = '<use xlink:href="#cube" x="655" y="504';
  svgData[231] = '<use xlink:href="#cube" x="711" y="532';
  svgData[232] = '<use xlink:href="#cube" x="319" y="264';
  svgData[233] = '<use xlink:href="#cube" x="375" y="292';
  svgData[234] = '<use xlink:href="#cube" x="431" y="320';
  svgData[235] = '<use xlink:href="#cube" x="487" y="348';
  svgData[236] = '<use xlink:href="#cube" x="543" y="376';
  svgData[237] = '<use xlink:href="#cube" x="599" y="404';
  svgData[238] = '<use xlink:href="#cube" x="655" y="432';
  svgData[239] = '<use xlink:href="#cube" x="711" y="460';
  svgData[240] = '<use xlink:href="#cube" x="319" y="192';
  svgData[241] = '<use xlink:href="#cube" x="375" y="220';
  svgData[242] = '<use xlink:href="#cube" x="431" y="248';
  svgData[243] = '<use xlink:href="#cube" x="487" y="276';
  svgData[244] = '<use xlink:href="#cube" x="543" y="304';
  svgData[245] = '<use xlink:href="#cube" x="599" y="332';
  svgData[246] = '<use xlink:href="#cube" x="655" y="360';
  svgData[247] = '<use xlink:href="#cube" x="711" y="388';
  svgData[248] = '<use xlink:href="#cube" x="319" y="120';
  svgData[249] = '<use xlink:href="#cube" x="375" y="148';
  svgData[250] = '<use xlink:href="#cube" x="431" y="176';
  svgData[251] = '<use xlink:href="#cube" x="487" y="204';
  svgData[252] = '<use xlink:href="#cube" x="543" y="232';
  svgData[253] = '<use xlink:href="#cube" x="599" y="260';
  svgData[254] = '<use xlink:href="#cube" x="655" y="288';
  svgData[255] = '<use xlink:href="#cube" x="711" y="316';
  svgData[256] = '<use xlink:href="#cube" x="263" y="652';
  svgData[257] = '<use xlink:href="#cube" x="319" y="680';
  svgData[258] = '<use xlink:href="#cube" x="375" y="708';
  svgData[259] = '<use xlink:href="#cube" x="431" y="736';
  svgData[260] = '<use xlink:href="#cube" x="487" y="764';
  svgData[261] = '<use xlink:href="#cube" x="543" y="792';
  svgData[262] = '<use xlink:href="#cube" x="599" y="820';
  svgData[263] = '<use xlink:href="#cube" x="655" y="848';
  svgData[264] = '<use xlink:href="#cube" x="263" y="580';
  svgData[265] = '<use xlink:href="#cube" x="319" y="608';
  svgData[266] = '<use xlink:href="#cube" x="375" y="636';
  svgData[267] = '<use xlink:href="#cube" x="431" y="664';
  svgData[268] = '<use xlink:href="#cube" x="487" y="692';
  svgData[269] = '<use xlink:href="#cube" x="543" y="720';
  svgData[270] = '<use xlink:href="#cube" x="599" y="748';
  svgData[271] = '<use xlink:href="#cube" x="655" y="776';
  svgData[272] = '<use xlink:href="#cube" x="263" y="508';
  svgData[273] = '<use xlink:href="#cube" x="319" y="536';
  svgData[274] = '<use xlink:href="#cube" x="375" y="564';
  svgData[275] = '<use xlink:href="#cube" x="431" y="592';
  svgData[276] = '<use xlink:href="#cube" x="487" y="620';
  svgData[277] = '<use xlink:href="#cube" x="543" y="648';
  svgData[278] = '<use xlink:href="#cube" x="599" y="676';
  svgData[279] = '<use xlink:href="#cube" x="655" y="704';
  svgData[280] = '<use xlink:href="#cube" x="263" y="436';
  svgData[281] = '<use xlink:href="#cube" x="319" y="464';
  svgData[282] = '<use xlink:href="#cube" x="375" y="492';
  svgData[283] = '<use xlink:href="#cube" x="431" y="520';
  svgData[284] = '<use xlink:href="#cube" x="487" y="548';
  svgData[285] = '<use xlink:href="#cube" x="543" y="576';
  svgData[286] = '<use xlink:href="#cube" x="599" y="604';
  svgData[287] = '<use xlink:href="#cube" x="655" y="632';
  svgData[288] = '<use xlink:href="#cube" x="263" y="364';
  svgData[289] = '<use xlink:href="#cube" x="319" y="392';
  svgData[290] = '<use xlink:href="#cube" x="375" y="420';
  svgData[291] = '<use xlink:href="#cube" x="431" y="448';
  svgData[292] = '<use xlink:href="#cube" x="487" y="476';
  svgData[293] = '<use xlink:href="#cube" x="543" y="504';
  svgData[294] = '<use xlink:href="#cube" x="599" y="532';
  svgData[295] = '<use xlink:href="#cube" x="655" y="560';
  svgData[296] = '<use xlink:href="#cube" x="263" y="292';
  svgData[297] = '<use xlink:href="#cube" x="319" y="320';
  svgData[298] = '<use xlink:href="#cube" x="375" y="348';
  svgData[299] = '<use xlink:href="#cube" x="431" y="376';
  svgData[300] = '<use xlink:href="#cube" x="487" y="404';
  svgData[301] = '<use xlink:href="#cube" x="543" y="432';
  svgData[302] = '<use xlink:href="#cube" x="599" y="460';
  svgData[303] = '<use xlink:href="#cube" x="655" y="488';
  svgData[304] = '<use xlink:href="#cube" x="263" y="220';
  svgData[305] = '<use xlink:href="#cube" x="319" y="248';
  svgData[306] = '<use xlink:href="#cube" x="375" y="276';
  svgData[307] = '<use xlink:href="#cube" x="431" y="304';
  svgData[308] = '<use xlink:href="#cube" x="487" y="332';
  svgData[309] = '<use xlink:href="#cube" x="543" y="360';
  svgData[310] = '<use xlink:href="#cube" x="599" y="388';
  svgData[311] = '<use xlink:href="#cube" x="655" y="416';
  svgData[312] = '<use xlink:href="#cube" x="263" y="148';
  svgData[313] = '<use xlink:href="#cube" x="319" y="176';
  svgData[314] = '<use xlink:href="#cube" x="375" y="204';
  svgData[315] = '<use xlink:href="#cube" x="431" y="232';
  svgData[316] = '<use xlink:href="#cube" x="487" y="260';
  svgData[317] = '<use xlink:href="#cube" x="543" y="288';
  svgData[318] = '<use xlink:href="#cube" x="599" y="316';
  svgData[319] = '<use xlink:href="#cube" x="655" y="344';
  svgData[320] = '<use xlink:href="#cube" x="207" y="680';
  svgData[321] = '<use xlink:href="#cube" x="263" y="708';
  svgData[322] = '<use xlink:href="#cube" x="319" y="736';
  svgData[323] = '<use xlink:href="#cube" x="375" y="764';
  svgData[324] = '<use xlink:href="#cube" x="431" y="792';
  svgData[325] = '<use xlink:href="#cube" x="487" y="820';
  svgData[326] = '<use xlink:href="#cube" x="543" y="848';
  svgData[327] = '<use xlink:href="#cube" x="599" y="876';
  svgData[328] = '<use xlink:href="#cube" x="207" y="608';
  svgData[329] = '<use xlink:href="#cube" x="263" y="636';
  svgData[330] = '<use xlink:href="#cube" x="319" y="664';
  svgData[331] = '<use xlink:href="#cube" x="375" y="692';
  svgData[332] = '<use xlink:href="#cube" x="431" y="720';
  svgData[333] = '<use xlink:href="#cube" x="487" y="748';
  svgData[334] = '<use xlink:href="#cube" x="543" y="776';
  svgData[335] = '<use xlink:href="#cube" x="599" y="804';
  svgData[336] = '<use xlink:href="#cube" x="207" y="536';
  svgData[337] = '<use xlink:href="#cube" x="263" y="564';
  svgData[338] = '<use xlink:href="#cube" x="319" y="592';
  svgData[339] = '<use xlink:href="#cube" x="375" y="620';
  svgData[340] = '<use xlink:href="#cube" x="431" y="648';
  svgData[341] = '<use xlink:href="#cube" x="487" y="676';
  svgData[342] = '<use xlink:href="#cube" x="543" y="704';
  svgData[343] = '<use xlink:href="#cube" x="599" y="732';
  svgData[344] = '<use xlink:href="#cube" x="207" y="464';
  svgData[345] = '<use xlink:href="#cube" x="263" y="492';
  svgData[346] = '<use xlink:href="#cube" x="319" y="520';
  svgData[347] = '<use xlink:href="#cube" x="375" y="548';
  svgData[348] = '<use xlink:href="#cube" x="431" y="576';
  svgData[349] = '<use xlink:href="#cube" x="487" y="604';
  svgData[350] = '<use xlink:href="#cube" x="543" y="632';
  svgData[351] = '<use xlink:href="#cube" x="599" y="660';
  svgData[352] = '<use xlink:href="#cube" x="207" y="392';
  svgData[353] = '<use xlink:href="#cube" x="263" y="420';
  svgData[354] = '<use xlink:href="#cube" x="319" y="448';
  svgData[355] = '<use xlink:href="#cube" x="375" y="476';
  svgData[356] = '<use xlink:href="#cube" x="431" y="504';
  svgData[357] = '<use xlink:href="#cube" x="487" y="532';
  svgData[358] = '<use xlink:href="#cube" x="543" y="560';
  svgData[359] = '<use xlink:href="#cube" x="599" y="588';
  svgData[360] = '<use xlink:href="#cube" x="207" y="320';
  svgData[361] = '<use xlink:href="#cube" x="263" y="348';
  svgData[362] = '<use xlink:href="#cube" x="319" y="376';
  svgData[363] = '<use xlink:href="#cube" x="375" y="404';
  svgData[364] = '<use xlink:href="#cube" x="431" y="432';
  svgData[365] = '<use xlink:href="#cube" x="487" y="460';
  svgData[366] = '<use xlink:href="#cube" x="543" y="488';
  svgData[367] = '<use xlink:href="#cube" x="599" y="516';
  svgData[368] = '<use xlink:href="#cube" x="207" y="248';
  svgData[369] = '<use xlink:href="#cube" x="263" y="276';
  svgData[370] = '<use xlink:href="#cube" x="319" y="304';
  svgData[371] = '<use xlink:href="#cube" x="375" y="332';
  svgData[372] = '<use xlink:href="#cube" x="431" y="360';
  svgData[373] = '<use xlink:href="#cube" x="487" y="388';
  svgData[374] = '<use xlink:href="#cube" x="543" y="416';
  svgData[375] = '<use xlink:href="#cube" x="599" y="444';
  svgData[376] = '<use xlink:href="#cube" x="207" y="176';
  svgData[377] = '<use xlink:href="#cube" x="263" y="204';
  svgData[378] = '<use xlink:href="#cube" x="319" y="232';
  svgData[379] = '<use xlink:href="#cube" x="375" y="260';
  svgData[380] = '<use xlink:href="#cube" x="431" y="288';
  svgData[381] = '<use xlink:href="#cube" x="487" y="316';
  svgData[382] = '<use xlink:href="#cube" x="543" y="344';
  svgData[383] = '<use xlink:href="#cube" x="599" y="372';
  svgData[384] = '<use xlink:href="#cube" x="151" y="708';
  svgData[385] = '<use xlink:href="#cube" x="207" y="736';
  svgData[386] = '<use xlink:href="#cube" x="263" y="764';
  svgData[387] = '<use xlink:href="#cube" x="319" y="792';
  svgData[388] = '<use xlink:href="#cube" x="375" y="820';
  svgData[389] = '<use xlink:href="#cube" x="431" y="848';
  svgData[390] = '<use xlink:href="#cube" x="487" y="876';
  svgData[391] = '<use xlink:href="#cube" x="543" y="904';
  svgData[392] = '<use xlink:href="#cube" x="151" y="636';
  svgData[393] = '<use xlink:href="#cube" x="207" y="664';
  svgData[394] = '<use xlink:href="#cube" x="263" y="692';
  svgData[395] = '<use xlink:href="#cube" x="319" y="720';
  svgData[396] = '<use xlink:href="#cube" x="375" y="748';
  svgData[397] = '<use xlink:href="#cube" x="431" y="776';
  svgData[398] = '<use xlink:href="#cube" x="487" y="804';
  svgData[399] = '<use xlink:href="#cube" x="543" y="832';
  svgData[400] = '<use xlink:href="#cube" x="151" y="564';
  svgData[401] = '<use xlink:href="#cube" x="207" y="592';
  svgData[402] = '<use xlink:href="#cube" x="263" y="620';
  svgData[403] = '<use xlink:href="#cube" x="319" y="648';
  svgData[404] = '<use xlink:href="#cube" x="375" y="676';
  svgData[405] = '<use xlink:href="#cube" x="431" y="704';
  svgData[406] = '<use xlink:href="#cube" x="487" y="732';
  svgData[407] = '<use xlink:href="#cube" x="543" y="760';
  svgData[408] = '<use xlink:href="#cube" x="151" y="492';
  svgData[409] = '<use xlink:href="#cube" x="207" y="520';
  svgData[410] = '<use xlink:href="#cube" x="263" y="548';
  svgData[411] = '<use xlink:href="#cube" x="319" y="576';
  svgData[412] = '<use xlink:href="#cube" x="375" y="604';
  svgData[413] = '<use xlink:href="#cube" x="431" y="632';
  svgData[414] = '<use xlink:href="#cube" x="487" y="660';
  svgData[415] = '<use xlink:href="#cube" x="543" y="688';
  svgData[416] = '<use xlink:href="#cube" x="151" y="420';
  svgData[417] = '<use xlink:href="#cube" x="207" y="448';
  svgData[418] = '<use xlink:href="#cube" x="263" y="476';
  svgData[419] = '<use xlink:href="#cube" x="319" y="504';
  svgData[420] = '<use xlink:href="#cube" x="375" y="532';
  svgData[421] = '<use xlink:href="#cube" x="431" y="560';
  svgData[422] = '<use xlink:href="#cube" x="487" y="588';
  svgData[423] = '<use xlink:href="#cube" x="543" y="616';
  svgData[424] = '<use xlink:href="#cube" x="151" y="348';
  svgData[425] = '<use xlink:href="#cube" x="207" y="376';
  svgData[426] = '<use xlink:href="#cube" x="263" y="404';
  svgData[427] = '<use xlink:href="#cube" x="319" y="432';
  svgData[428] = '<use xlink:href="#cube" x="375" y="460';
  svgData[429] = '<use xlink:href="#cube" x="431" y="488';
  svgData[430] = '<use xlink:href="#cube" x="487" y="516';
  svgData[431] = '<use xlink:href="#cube" x="543" y="544';
  svgData[432] = '<use xlink:href="#cube" x="151" y="276';
  svgData[433] = '<use xlink:href="#cube" x="207" y="304';
  svgData[434] = '<use xlink:href="#cube" x="263" y="332';
  svgData[435] = '<use xlink:href="#cube" x="319" y="360';
  svgData[436] = '<use xlink:href="#cube" x="375" y="388';
  svgData[437] = '<use xlink:href="#cube" x="431" y="416';
  svgData[438] = '<use xlink:href="#cube" x="487" y="444';
  svgData[439] = '<use xlink:href="#cube" x="543" y="472';
  svgData[440] = '<use xlink:href="#cube" x="151" y="204';
  svgData[441] = '<use xlink:href="#cube" x="207" y="232';
  svgData[442] = '<use xlink:href="#cube" x="263" y="260';
  svgData[443] = '<use xlink:href="#cube" x="319" y="288';
  svgData[444] = '<use xlink:href="#cube" x="375" y="316';
  svgData[445] = '<use xlink:href="#cube" x="431" y="344';
  svgData[446] = '<use xlink:href="#cube" x="487" y="372';
  svgData[447] = '<use xlink:href="#cube" x="543" y="400';
  svgData[448] = '<use xlink:href="#cube" x="95" y="736';
  svgData[449] = '<use xlink:href="#cube" x="151" y="764';
  svgData[450] = '<use xlink:href="#cube" x="207" y="792';
  svgData[451] = '<use xlink:href="#cube" x="263" y="820';
  svgData[452] = '<use xlink:href="#cube" x="319" y="848';
  svgData[453] = '<use xlink:href="#cube" x="375" y="876';
  svgData[454] = '<use xlink:href="#cube" x="431" y="904';
  svgData[455] = '<use xlink:href="#cube" x="487" y="932';
  svgData[456] = '<use xlink:href="#cube" x="95" y="664';
  svgData[457] = '<use xlink:href="#cube" x="151" y="692';
  svgData[458] = '<use xlink:href="#cube" x="207" y="720';
  svgData[459] = '<use xlink:href="#cube" x="263" y="748';
  svgData[460] = '<use xlink:href="#cube" x="319" y="776';
  svgData[461] = '<use xlink:href="#cube" x="375" y="804';
  svgData[462] = '<use xlink:href="#cube" x="431" y="832';
  svgData[463] = '<use xlink:href="#cube" x="487" y="860';
  svgData[464] = '<use xlink:href="#cube" x="95" y="592';
  svgData[465] = '<use xlink:href="#cube" x="151" y="620';
  svgData[466] = '<use xlink:href="#cube" x="207" y="648';
  svgData[467] = '<use xlink:href="#cube" x="263" y="676';
  svgData[468] = '<use xlink:href="#cube" x="319" y="704';
  svgData[469] = '<use xlink:href="#cube" x="375" y="732';
  svgData[470] = '<use xlink:href="#cube" x="431" y="760';
  svgData[471] = '<use xlink:href="#cube" x="487" y="788';
  svgData[472] = '<use xlink:href="#cube" x="95" y="520';
  svgData[473] = '<use xlink:href="#cube" x="151" y="548';
  svgData[474] = '<use xlink:href="#cube" x="207" y="576';
  svgData[475] = '<use xlink:href="#cube" x="263" y="604';
  svgData[476] = '<use xlink:href="#cube" x="319" y="632';
  svgData[477] = '<use xlink:href="#cube" x="375" y="660';
  svgData[478] = '<use xlink:href="#cube" x="431" y="688';
  svgData[479] = '<use xlink:href="#cube" x="487" y="716';
  svgData[480] = '<use xlink:href="#cube" x="95" y="448';
  svgData[481] = '<use xlink:href="#cube" x="151" y="476';
  svgData[482] = '<use xlink:href="#cube" x="207" y="504';
  svgData[483] = '<use xlink:href="#cube" x="263" y="532';
  svgData[484] = '<use xlink:href="#cube" x="319" y="560';
  svgData[485] = '<use xlink:href="#cube" x="375" y="588';
  svgData[486] = '<use xlink:href="#cube" x="431" y="616';
  svgData[487] = '<use xlink:href="#cube" x="487" y="644';
  svgData[488] = '<use xlink:href="#cube" x="95" y="376';
  svgData[489] = '<use xlink:href="#cube" x="151" y="404';
  svgData[490] = '<use xlink:href="#cube" x="207" y="432';
  svgData[491] = '<use xlink:href="#cube" x="263" y="460';
  svgData[492] = '<use xlink:href="#cube" x="319" y="488';
  svgData[493] = '<use xlink:href="#cube" x="375" y="516';
  svgData[494] = '<use xlink:href="#cube" x="431" y="544';
  svgData[495] = '<use xlink:href="#cube" x="487" y="572';
  svgData[496] = '<use xlink:href="#cube" x="95" y="304';
  svgData[497] = '<use xlink:href="#cube" x="151" y="332';
  svgData[498] = '<use xlink:href="#cube" x="207" y="360';
  svgData[499] = '<use xlink:href="#cube" x="263" y="388';
  svgData[500] = '<use xlink:href="#cube" x="319" y="416';
  svgData[501] = '<use xlink:href="#cube" x="375" y="444';
  svgData[502] = '<use xlink:href="#cube" x="431" y="472';
  svgData[503] = '<use xlink:href="#cube" x="487" y="500';
  svgData[504] = '<use xlink:href="#cube" x="95" y="232';
  svgData[505] = '<use xlink:href="#cube" x="151" y="260';
  svgData[506] = '<use xlink:href="#cube" x="207" y="288';
  svgData[507] = '<use xlink:href="#cube" x="263" y="316';
  svgData[508] = '<use xlink:href="#cube" x="319" y="344';
  svgData[509] = '<use xlink:href="#cube" x="375" y="372';
  svgData[510] = '<use xlink:href="#cube" x="431" y="400';
  svgData[511] = '<use xlink:href="#cube" x="487" y="428';





  var svgBackgroundColor = {};
  svgBackgroundColor[0] = '#800000"/>';
  svgBackgroundColor[1] = '#8B0000"/>';
  svgBackgroundColor[2] = '#A52A2A"/>';
  svgBackgroundColor[3] = '#B22222"/>';
  svgBackgroundColor[4] = '#DC143C"/>';
  svgBackgroundColor[5] = '#FF0000"/>';
  svgBackgroundColor[6] = '#FF6347"/>';
  svgBackgroundColor[7] = '#FF7F50"/>';
  svgBackgroundColor[8] = '#CD5C5C"/>';
  svgBackgroundColor[9] = '#F08080"/>';
  svgBackgroundColor[10] = '#E9967A"/>';
  svgBackgroundColor[11] = '#FA8072"/>';
  svgBackgroundColor[12] = '#FFA07A"/>';
  svgBackgroundColor[13] = '#FF4500"/>';
  svgBackgroundColor[14] = '#FF8C00"/>';
  svgBackgroundColor[15] = '#FFA500"/>';
  svgBackgroundColor[16] = '#FFD700"/>';
  svgBackgroundColor[17] = '#B8860B"/>';
  svgBackgroundColor[18] = '#DAA520"/>';
  svgBackgroundColor[19] = '#EEE8AA"/>';
  svgBackgroundColor[20] = '#BDB76B"/>';
  svgBackgroundColor[21] = '#F0E68C"/>';
  svgBackgroundColor[22] = '#808000"/>';
  svgBackgroundColor[23] = '#FFFF00"/>';
  svgBackgroundColor[24] = '#9ACD32"/>';
  svgBackgroundColor[25] = '#556B2F"/>';
  svgBackgroundColor[26] = '#6B8E23"/>';
  svgBackgroundColor[27] = '#7CFC00"/>';
  svgBackgroundColor[28] = '#7FFF00"/>';
  svgBackgroundColor[29] = '#ADFF2F"/>';
  svgBackgroundColor[30] = '#006400"/>';
  svgBackgroundColor[31] = '#008000"/>';
  svgBackgroundColor[32] = '#228B22"/>';
  svgBackgroundColor[33] = '#00FF00"/>';
  svgBackgroundColor[34] = '#32CD32"/>';
  svgBackgroundColor[35] = '#90EE90"/>';
  svgBackgroundColor[36] = '#98FB98"/>';
  svgBackgroundColor[37] = '#8FBC8F"/>';
  svgBackgroundColor[38] = '#00FA9A"/>';
  svgBackgroundColor[39] = '#00FF7F"/>';
  svgBackgroundColor[40] = '#2E8B57"/>';
  svgBackgroundColor[41] = '#66CDAA"/>';
  svgBackgroundColor[42] = '#3CB371"/>';
  svgBackgroundColor[43] = '#20B2AA"/>';
  svgBackgroundColor[44] = '#2F4F4F"/>';
  svgBackgroundColor[45] = '#008080"/>';
  svgBackgroundColor[46] = '#008B8B"/>';
  svgBackgroundColor[47] = '#00FFFF"/>';
  svgBackgroundColor[48] = '#00FFFF"/>';
  svgBackgroundColor[49] = '#E0FFFF"/>';
  svgBackgroundColor[50] = '#00CED1"/>';
  svgBackgroundColor[51] = '#40E0D0"/>';
  svgBackgroundColor[52] = '#48D1CC"/>';
  svgBackgroundColor[53] = '#AFEEEE"/>';
  svgBackgroundColor[54] = '#7FFFD4"/>';
  svgBackgroundColor[55] = '#B0E0E6"/>';
  svgBackgroundColor[56] = '#5F9EA0"/>';
  svgBackgroundColor[57] = '#4682B4"/>';
  svgBackgroundColor[58] = '#6495ED"/>';
  svgBackgroundColor[59] = '#00BFFF"/>';
  svgBackgroundColor[60] = '#1E90FF"/>';
  svgBackgroundColor[61] = '#ADD8E6"/>';
  svgBackgroundColor[62] = '#87CEEB"/>';
  svgBackgroundColor[63] = '#87CEFA"/>';
  svgBackgroundColor[64] = '#191970"/>';
  svgBackgroundColor[65] = '#000080"/>';
  svgBackgroundColor[66] = '#00008B"/>';
  svgBackgroundColor[67] = '#0000CD"/>';
  svgBackgroundColor[68] = '#0000FF"/>';
  svgBackgroundColor[69] = '#4169E1"/>';
  svgBackgroundColor[70] = '#8A2BE2"/>';
  svgBackgroundColor[71] = '#4B0082"/>';
  svgBackgroundColor[72] = '#483D8B"/>';
  svgBackgroundColor[73] = '#6A5ACD"/>';
  svgBackgroundColor[74] = '#7B68EE"/>';
  svgBackgroundColor[75] = '#9370DB"/>';
  svgBackgroundColor[76] = '#8B008B"/>';
  svgBackgroundColor[77] = '#9400D3"/>';
  svgBackgroundColor[78] = '#9932CC"/>';
  svgBackgroundColor[79] = '#BA55D3"/>';
  svgBackgroundColor[80] = '#800080"/>';
  svgBackgroundColor[81] = '#D8BFD8"/>';
  svgBackgroundColor[82] = '#DDA0DD"/>';
  svgBackgroundColor[83] = '#EE82EE"/>';
  svgBackgroundColor[84] = '#FF00FF"/>';
  svgBackgroundColor[85] = '#DA70D6"/>';
  svgBackgroundColor[86] = '#C71585"/>';
  svgBackgroundColor[87] = '#DB7093"/>';
  svgBackgroundColor[88] = '#FF1493"/>';
  svgBackgroundColor[89] = '#FF69B4"/>';
  svgBackgroundColor[90] = '#FFB6C1"/>';
  svgBackgroundColor[91] = '#FFC0CB"/>';
  svgBackgroundColor[92] = '#FAEBD7"/>';
  svgBackgroundColor[93] = '#F5F5DC"/>';
  svgBackgroundColor[94] = '#FFE4C4"/>';
  svgBackgroundColor[95] = '#FFEBCD"/>';
  svgBackgroundColor[96] = '#F5DEB3"/>';
  svgBackgroundColor[97] = '#FFF8DC"/>';
  svgBackgroundColor[98] = '#FFFACD"/>';
  svgBackgroundColor[99] = '#FAFAD2"/>';
  svgBackgroundColor[100] = '#FFFFE0"/>';
  svgBackgroundColor[101] = '#8B4513"/>';
  svgBackgroundColor[102] = '#A0522D"/>';
  svgBackgroundColor[103] = '#D2691E"/>';
  svgBackgroundColor[104] = '#CD853F"/>';
  svgBackgroundColor[105] = '#F4A460"/>';
  svgBackgroundColor[106] = '#DEB887"/>';
  svgBackgroundColor[107] = '#D2B48C"/>';
  svgBackgroundColor[108] = '#BC8F8F"/>';
  svgBackgroundColor[109] = '#FFE4B5"/>';
  svgBackgroundColor[110] = '#FFDEAD"/>';
  svgBackgroundColor[111] = '#FFDAB9"/>';
  svgBackgroundColor[112] = '#FFE4E1"/>';
  svgBackgroundColor[113] = '#FFF0F5"/>';
  svgBackgroundColor[114] = '#FAF0E6"/>';
  svgBackgroundColor[115] = '#FDF5E6"/>';
  svgBackgroundColor[116] = '#FFEFD5"/>';
  svgBackgroundColor[117] = '#FFF5EE"/>';
  svgBackgroundColor[118] = '#F5FFFA"/>';
  svgBackgroundColor[119] = '#708090"/>';
  svgBackgroundColor[120] = '#778899"/>';
  svgBackgroundColor[121] = '#B0C4DE"/>';
  svgBackgroundColor[122] = '#E6E6FA"/>';
  svgBackgroundColor[123] = '#FFFAF0"/>';
  svgBackgroundColor[124] = '#F0F8FF"/>';
  svgBackgroundColor[125] = '#F8F8FF"/>';
  svgBackgroundColor[126] = '#F0FFF0"/>';
  svgBackgroundColor[127] = '#FFFFF0"/>';
  svgBackgroundColor[128] = '#F0FFFF"/>';
  svgBackgroundColor[129] = '#FFFAFA"/>';
  svgBackgroundColor[130] = '#000000"/>';
  svgBackgroundColor[131] = '#696969"/>';
  svgBackgroundColor[132] = '#808080"/>';
  svgBackgroundColor[133] = '#A9A9A9"/>';
  svgBackgroundColor[134] = '#C0C0C0"/>';
  svgBackgroundColor[135] = '#D3D3D3"/>';
  svgBackgroundColor[136] = '#DCDCDC"/>';
  svgBackgroundColor[137] = '#FFFFFF"/>';

  let submitCustom = async () => {
    // alert("ONCE YOUR TRANSACTION PROCESSES ON-CHAIN, HEAD TO OPENSEA AND CLICK REFRESH (USUALLY TAKES 10+ MINUTES TO SHOW UP).")
    // // await metakays.methods.kustomize()
    //     await metakays.methods.kustomizeBackground(bgColor, tokenID).send({
    //       from: userAccount,

    //       })
    alert("FINALITY IS IRREVERSIBLE. \nYOU WILL NEVER BE ABLE TO KUSTOMIZE NOR TRAVERSE CHAINS EVER AGAIN. \nPROCEED WITH THE TRANSACTION IF YOU UNDERSTAND.")

    setMsg1("Head to https://polygonscan.com/address/0x151c384c9d8aece2dfb50e61890217128f90c321 and connect your POLYGON wallet. Scroll down to the *finality* function and input these values:")
    setMsg2("_itemID: YOUR_TOKEN_ID")
        }



  // const handleShow = async (_value) => {
  //   setCustomChooser(_value)
  //   if (showKustomModal) {
  //     setKustomModal(false)
  //   } else {
  //     setKustomModal(true)
  //   }
  // };

  return (
        <div className="Custom">
        {/* backgroundColor */}
        {showKustomModal ?
          <div className="CustomSVGRightSide">
            <div className='over'><CustomIndividualPOLYGONBackground/></div>
            <div className="CustomHeader">MENU</div>
            <Link className="itemDropBackground" to='/kustompolygonmenu'>BACK</Link>
            <div className="itemDropFinality" onClick={() => submitCustom()}>SUBMIT FINALITY</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>

          </div>
        :
        <div className="CustomSVGRightSide">
          <div className="CustomHeader">MENU</div>
          <Link className="itemDropBackground" to='/kustompolygonmenu'>BACK</Link>
          <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, FINALIZE ON POLYGON BY HEADING TO https://polygonscan.com/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER POLYGONSCAN AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: SIMPLY COPY AND PASTE THE DATA BELOW INTO THE "FINALITY" FUNCTION ON POLYGONSCAN AND SUBMIT.</div>
            <div className="itemDropFinality" onClick={() => submitCustom()}>SUBMIT FINALITY</div>

            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>


        </div>}
        <div className="CustomSVGRightSide">
            {/* background  */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="{w}pt" height="{h}pt" viewBox="0 0 1600.000000 800.000000" preserveAspectRatio="xMidYMid meet" xmlnsXlink="http://www.w3.org/1999/xlink">
            {parse('<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">')}
            {parse('<path d="M0 4000 l0 -4000 8000 0 8000 0 0 4000 0 4000 -8000 0 -8000 0 0 -4000z" fill="' + String(svgBackgroundColor[bgColor]) + '</g>')}


            {/* {parse('<use xlinkHref="#cube" x="487" y="540" fill="#000000"/>')} */}

            {/* {peices.split("").forEach(character => {if (character == "0") {return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')}})} */}

            {peices.map((value, index) => {

              let color;

              if (parseInt(value) === 1) {
                color = svgBackgroundColor[four_colors[0]]
              } else {
                if (parseInt(color1[index]) === 1){
                  color = svgBackgroundColor[four_colors[1]]
                } else {
                  if (parseInt(color2[index]) === 1){
                    color = svgBackgroundColor[four_colors[2]]
                  } else {
                    color = svgBackgroundColor[four_colors[3]]
                  }
                }
              }
  // let color = color1[index] == 1 ? svgBackgroundColor[three_colors[0]] : color2[index] == 1 ? svgBackgroundColor[three_colors[1]] : svgBackgroundColor[three_colors[2]]
  let temp = svgData[index] + '" fill="' + color;
  return parse(temp)
  // return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')

})}

{parse('<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)" fill="#F5F5F5" stroke="none"> <path d="M14720 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z M15440 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z"/></g></svg>')}
            </svg>
          </div>
      </div>
  );
}






