import React from "react";
import { Route, Switch } from "react-router-dom";
import RealHome from "./RealHome";
import NotFound from "./NotFound";
////////////////////////////////////////
import CustomOPT from "./OPT/CustomOPT"
import CustomOPTMenu from "./OPT/CustomOPTMenu"
import CustomOPTBackground from "./OPT/CustomOPTBackground"
import CustomOPTFinality from "./OPT/CustomOPTFinality"
////////////////////////////////////////
import CustomARB from "./ARB/CustomARB"
import CustomARBMenu from "./ARB/CustomARBMenu"
import CustomARBBackground from "./ARB/CustomARBBackground"
import CustomARBFinality from "./ARB/CustomARBFinality"
////////////////////////////////////////
import CustomPOLYGON from "./POLYGON/CustomPOLYGON"
import CustomPOLYGONMenu from "./POLYGON/CustomPOLYGONMenu"
import CustomPOLYGONBackground from "./POLYGON/CustomPOLYGONBackground"
import CustomPOLYGONFinality from "./POLYGON/CustomPOLYGONFinality"
////////////////////////////////////////
import CustomBNB from "./BNB/CustomBNB"
import CustomBNBMenu from "./BNB/CustomBNBMenu"
import CustomBNBBackground from "./BNB/CustomBNBBackground"
import CustomBNBFinality from "./BNB/CustomBNBFinality"
////////////////////////////////////////
import CustomAVAX from "./AVAX/CustomAVAX"
import CustomAVAXMenu from "./AVAX/CustomAVAXMenu"
import CustomAVAXBackground from "./AVAX/CustomAVAXBackground"
import CustomAVAXFinality from "./AVAX/CustomAVAXFinality"
//////////////////////////////////////////
import CustomETH from "./ETH/CustomETH"
import CustomETHMenu from "./ETH/CustomETHMenu"
import CustomETHBackground from "./ETH/CustomETHBackground"
import CustomETHFinality from "./ETH/CustomETHFinality"
import CustomMenu from "./CustomMenu"
import Custom8888Menu from "./Custom8888Menu"
import Custom from "./Custom"
import TAC from "./TAC"
import FAQs from "./FAQs"
import Claim from "./Claim"
import FamilyPhoto from "./FamilyPhoto"
import FamilyPhotoUpdate from "./FamilyPhotoUpdate"
import Finality from "./Finality";
import Mint from "./Mint";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
      <RealHome/>
      </Route>
      <Route exact path="/kustom">
        <Custom/>
        </Route>
      <Route exact path="/finality">
        <Finality/>
      </Route>
      <Route exact path="/kustommenu">
        <CustomMenu />
        </Route>
        <Route exact path="/kustom8888menu">
        <Custom8888Menu />
        </Route>
      <Route exact path="/kustomethmenu">
        <CustomETHMenu/>
      </Route>
      <Route exact path="/kustometh">
        <CustomETH />
        </Route>
        <Route exact path="/kustomethbackground">
        <CustomETHBackground />
        </Route>
      <Route exact path="/kustomethfinality">
        <CustomETHFinality />
      </Route>
    {/* ************************************** */}

      <Route exact path="/kustomavaxmenu">
        <CustomAVAXMenu/>
      </Route>
      <Route exact path="/kustomavax">
        <CustomAVAX />
        </Route>
        <Route exact path="/kustomavaxbackground">
        <CustomAVAXBackground />
        </Route>
      <Route exact path="/kustomavaxfinality">
        <CustomAVAXFinality />
      </Route>
      {/* ************************************** */}

      <Route exact path="/kustombnbmenu">
        <CustomBNBMenu/>
      </Route>
      <Route exact path="/kustombnb">
        <CustomBNB />
        </Route>
        <Route exact path="/kustombnbbackground">
        <CustomBNBBackground />
        </Route>
      <Route exact path="/kustombnbfinality">
        <CustomBNBFinality />
      </Route>
      {/* ************************************** */}
      <Route exact path="/kustompolygonmenu">
        <CustomPOLYGONMenu/>
      </Route>
      <Route exact path="/kustompolygon">
        <CustomPOLYGON />
        </Route>
        <Route exact path="/kustompolygonbackground">
        <CustomPOLYGONBackground />
        </Route>
      <Route exact path="/kustompolygonfinality">
        <CustomPOLYGONFinality />
      </Route>
      {/* ************************************** */}
      <Route exact path="/kustomarbmenu">
        <CustomARBMenu/>
      </Route>
      <Route exact path="/kustomarb">
        <CustomARB/>
        </Route>
        <Route exact path="/kustomarbbackground">
        <CustomARBBackground />
        </Route>
      <Route exact path="/kustomarbfinality">
        <CustomARBFinality />
      </Route>
      {/* ************************************** */}
      <Route exact path="/kustomoptmenu">
        <CustomOPTMenu/>
      </Route>
      <Route exact path="/kustomopt">
        <CustomOPT/>
        </Route>
        <Route exact path="/kustomoptbackground">
        <CustomOPTBackground />
        </Route>
      <Route exact path="/kustomoptfinality">
        <CustomOPTFinality />
      </Route>
      {/* ************************************** */}
      <Route exact path="/claim">
        <Claim />
      </Route>
      <Route exact path="/termsandconditions">
        <TAC />
      </Route>
      <Route exact path="/familyphoto">
        <FamilyPhoto />
      </Route>
      <Route exact path="/familyphotoupdate">
        <FamilyPhotoUpdate />
      </Route>
      <Route exact path="/faqs">
        <FAQs/>
      </Route>
      <Route exact path="/mint">
        <Mint/>
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
