import React, {useEffect, useState } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { AppContext } from "../libs/contextLib";
import "./Pokemetaverse.css";
import openingSong from '../static/TwinMusicomNESTheme.mp3'
import web3 from '../web3';
// import metakays from '../metakays';
import metakays8888 from '../metakays8888';
import Routes from "./Routes";

require('dotenv').config();

export default function PokeMetaverse() {

    const [peices, setPeices] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0]);
    const [color1, setColor1] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0]);
    const [color2, setColor2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0]);
    const [three_colors, set_three_colors] = useState([0,0,0]);
    const [bgColor, setBgColor] = useState(0);

    const [four_colors, set_four_colors] = useState([0,0,0,0]);


    const pokemonAudio = new Audio(openingSong);
    const [themeSong, setThemeSong] = useState(pokemonAudio);
    const [songIsPlaying, setSongPlaying] = useState(false);
    // const [loaded, setLoaded] = useState(false);

    const [backgroundColor, setBackgroundColor] = useState('#2dd055"/>');
    const [bodyColor, setBodyColor] = useState('#2dd055"/>');
    const [skinColor, setSkinColor] = useState('#2dd055"/>');
    const [eyesColor, setEyesColor] = useState('#2dd055"/>');
    const [shirtText, setShirtText] = useState('METAKAYS');
    const [mouthColor, setMouthColor] = useState('#2dd055"/>');
    const [hairColor, setHairColor] = useState('#2dd055"/>');
    const [misc1Style, setMisc1Style] = useState('<path d="" fill="');
    const [misc2Style, setMisc2Style] = useState('<path d="" fill="');
    const [misc1Color, setMisc1Color] = useState('#2dd055"/>');
    const [misc2Color, setMisc2Color] = useState('#2dd055"/>');
    const [hairStyle, setHairStyle] = useState('<path d="" fill="');
    const [mouthStyle, setMouthStyle] = useState('<path d="" fill="');
    const [customChooser, setCustomChooser] = useState(0);
    const [ownsMetakays, setOwnsMetakays] = useState(false);

    const [customArray, setCustomArray] = useState({});

    const [myMETAKAYS, setMyMETAKAYS] = useState({}); //!!!

    const [tokenID, setTokenID] = useState(0);



    const [skipIntro, setSkipIntro] = useState(false);
    const [skipTypewriter, setSkipTypewriter] = useState(true);

    //MODAL
    const [showMenu, setMenu] = useState(false);
    const [selectMenu, setSelectMenu] = useState(0);
    const [highlightMenuPosition, setHighlightMenuPosition] = useState(0);
    const [highlightMenuObject, setHighlightMenuObject] = useState({
      0: {"backcolor":"", "fontcolor":""},
      1: {"backcolor":"", "fontcolor":""},
      2: {"backcolor":"", "fontcolor":""},
      3: {"backcolor":"", "fontcolor":""},
      4: {"backcolor":"", "fontcolor":""},
      5: {"backcolor":"", "fontcolor":""},
      6: {"backcolor":"", "fontcolor":""},
      7: {"backcolor":"", "fontcolor":""},
      8: {"backcolor":"", "fontcolor":""}
    });

    //WEB3
    const [userAccount, setUserAccount] = useState([]);
    const [showKustomModal, setKustomModal] = useState(false);


    //WEB3 Get user's Digikaws!
    useEffect(() => {



//GET DIGIDEX
    let fetchDataFormDynamoDb = async () => {
        // let accounts = ['undefined']
        // accounts = await web3.eth.getAccounts();
        // setUserAccount(accounts[0])
        const accounts = await web3.eth.getAccounts();
        setUserAccount(accounts[0])
      // let supplyOfOwner = 0;
      // if (String(accounts[0]) != 'undefined'){
        try {
          let supplyOfOwner = await metakays8888.methods.balanceOf(accounts[0]).call()
          if (supplyOfOwner > 0) {
            setOwnsMetakays(true)
          }
          // setLoaded(true);

      } catch (e) {
        console.log(e)
      }

    }
      fetchDataFormDynamoDb();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps


    //CONTEXT
    const contextDict = {four_colors, set_four_colors, bgColor, setBgColor, three_colors, set_three_colors, color1, setColor1, color2, setColor2, peices, setPeices, ownsMetakays, themeSong, setThemeSong,songIsPlaying, setSongPlaying, skipIntro, setSkipIntro, showMenu, setMenu, selectMenu, setSelectMenu,
      highlightMenuPosition, setHighlightMenuPosition, highlightMenuObject, setHighlightMenuObject, skipTypewriter, setSkipTypewriter,userAccount, setUserAccount, backgroundColor, setBackgroundColor, hairColor, setHairColor, eyesColor, setEyesColor, mouthColor, setMouthColor, bodyColor, setBodyColor, skinColor, setSkinColor, shirtText, setShirtText, misc1Style, setMisc1Style, misc2Style, setMisc2Style ,hairStyle, setHairStyle ,mouthStyle, setMouthStyle, setCustomChooser, customChooser, customArray, setCustomArray, myMETAKAYS, setMyMETAKAYS, misc1Color, setMisc1Color, misc2Color, setMisc2Color, setTokenID, tokenID, showKustomModal, setKustomModal};

  return (

    <div className="pokemetaverse">
      <ErrorBoundary>
          <AppContext.Provider value={contextDict}>
        <Routes/>
      </AppContext.Provider>
    </ErrorBoundary>
    </div>
  );
}
