import "../CustomMenu.css";
import { Link } from 'react-router-dom'

export default function CustomETHMenu() {
  return (
    <div className="RealCustomMenu">
      <div className="CustomMenuHeader">SELECT</div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustometh'>KUSTOMIZE</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomethbackground'>CHANGE BACKGROUND COLOR</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomethfinality'>FINALITY</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomMenu'>BACK</Link>
      </div>
    </div>
  );
}
