import "./CustomMenu.css";
import { Link } from 'react-router-dom'

export default function CustomMenu() {
  return (
    <div className="RealCustomMenu">
      <div className="CustomMenuHeader">CHOOSE YOUR METAVERSE!</div>
      <div className="CustomMenuSubHeader">Current kustomizers + UI/UX are temporary.</div>
      <div className="CustomMenuSubHeader">3D kustomizers + proper drawing tools (better UI/UX) coming soon.</div>
      <div className="CustomMenuSubHeader">(All the data is completely on-chain, so feel free to build one for the community if you're excited enough.)</div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomethmenu'>METAKUBES (ETHEREUM)</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomavaxmenu'>METAKANVAS (AVAX)</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustombnbmenu'>METAKERNELS (BNB)</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustompolygonmenu'>METAKARMICS (POLYGON)</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomarbmenu'>METAKNOXS (ARBITRUM)</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomoptmenu'>METAKRYPTS (OPTIMISM)</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustom8888menu'>METAVERSE-8888</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/'>BACK</Link>
      </div>
    </div>
  );
}
