import "./RealHome.css";
import { Link } from 'react-router-dom'

export default function RealHome() {

  const openSea = async () => {
    return window.location.href = "https://opensea.io/collection/metakubes64"
  };

  const twitter = async () => {
    return window.location.href = "https://twitter.com/metakays"
  };

  const discord = async () => {
    return window.location.href = "https://discord.gg/METAKAYS"
  }

  const exit = async () => {
    return window.location.href = "https://google.com"
  }

  return (
    <div className="RealHome">
      <div className="Header">METAKAYS</div>
      <div className="Menu">
        <Link className="Menu" to='/faqs'>FAQs</Link>
      </div>
      <div className="Menu">
      <div className="Menu" onClick={openSea}>OPENSEA</div>
      </div>
      <div className="Menu">
      <div className="Menu" onClick={twitter}>TWITTER</div>
      </div>
      <div className="Menu">
      <div className="Menu" onClick={discord}>DISCORD</div>
      </div>
      {/* <div className="Menu">
        <Link className="Menu" to='/termsandconditions'>TERMS & CONDITIONS</Link>
      </div> */}
      <div className="Menu">
      <div className="Menu" onClick={exit}>EXIT</div>
      </div>
      <div className="MenuK">
        <Link className="MenuK" to='/kustomMenu'>K</Link>
      </div>
    </div>
  );
}
