import "./Claim.css";
import web3 from '../web3';
import React, {useState, useEffect } from "react";



export default function Claim() {


  const [count, setCount] = useState(0);


  // const [claimMessage, setMessage] = useState("");
  // const [myHeader, setMyHeader] = useState("Enter The Multimetaverse")

  const [saleIsActive, setSaleIsActive] = useState(false);

  useEffect(() => {
    const isWhitelist = async () => {
      const accounts = await web3.eth.getAccounts();
      if (String(accounts[0]) !== "undefined"){
        setSaleIsActive(true)
      }
      }

    isWhitelist();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps




  const termsAndConditions = async () => {
    return window.location.href = "https://metakays.com/termsandconditions"
  };

  return (

    <div className="claim">
      <h3 className="touchscreenActivated2">TOUCH SCREEN ACTIVATED</h3>
      <div className="chooseHeader">Enter The Multimetaverse</div>


      {saleIsActive ? <div>
        <div className="termsAndConditions">By minting, you agree to our <div className="termsAndConditions" onClick={termsAndConditions}>TERMS AND CONDITIONS.</div></div>
      <div><div className="chooseNumber">{count}</div></div>
    <button className="plusMinusButton" onClick={() => {setCount(count===5 ? 5 : count + 1)}}>+</button>
    <button className="plusMinusButton" onClick={() => {setCount(count===0 ? 0 : count - 1)}}>-</button>
    <div className="rainbowMint rainbow_text_animatedMint slide-right">SOLD OUT</div>
     {/* <div className="loadingText">{String(totalMinted)+" out of 8888 minted"}</div> */}
    </div> : <div className="youAreOnWL">{"Please connect your MetaMask & refresh the page."}</div>
      }


    </div>



  )
}