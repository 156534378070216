import "../CustomMenu.css";
import { Link } from 'react-router-dom'

export default function CustomPOLYGONMenu() {
  return (
    <div className="RealCustomMenu">
      <div className="CustomMenuHeader">SELECT</div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustompolygon'>KUSTOMIZE</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustompolygonbackground'>CHANGE BACKGROUND COLOR</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustompolygonfinality'>FINALITY</Link>
      </div>
      <div className="CustomMenu">
        <Link className="CustomMenu" to='/kustomMenu'>BACK</Link>
      </div>
    </div>
  );
}
