import React from "react";
import "./FAQs.css";
import { Link } from 'react-router-dom';



export default function FAQs() {


  return (

    <div className = "FAQs">
<Link className="itemDropFAQ" to='/'>BACK</Link>

<div className="contentCopyOrangee">“What are METAKAYS in METAVERSE-64 through METAVERSE-69?”</div>
<div className="contentCopyRedd">
<div>METAKAYS in M-64–M-69 span across six different chains:</div>
<div>METAKUBES in M-64 (ETH)</div>
<div>METAKANVAS in M-65 (AVAX)</div>
<div>METAKERNELS in M-66 (BNB)</div>
<div>METAKARMICS in M-67 (POLYGON)</div>
<div>METAKNOXS in M-68 (ARBITRUM)</div>
<div>METAKRYPTS in M-69 (OPTIMISM)</div>
<div>These METAKAYS are 100% on-chain, 100% kustomizable (as many times as you want), and traversable across six different chains. The image (and name) of your METAKAYS changes when you traverse to another METAVERSE (chain).
When you land in a new METAVERSE for the first time, you will be a blank slate and must kustomize. Your kustom will still be there if you were to traverse again and return.
</div>
<div>METAKAYS are composable + extendable, allowing for anyone to easily and permisionless-ly build on top.</div>
</div>

<div className="contentCopyOrangee">“What is the point of METAKAYS (M64-M69)?”</div>
<div className="contentCopyRedd">
<div>We built METAKAYS to (hopefully) stand the test of time. They are constructed fully on-chain and across six different chains. There is no single point of failure.
They are also kustomizable as many times as you want, allowing the exploration of new ideas years later and adding a "time-less" component.
</div>
</div>

<div className="contentCopyOrangee">“What is FINALITY in M64-M69?”</div>
<div className="contentCopyRedd">
<div>
FINALITY is a function you may call on each chain to "finalize" your kustom  METAKAYS. It locks in your kustom as a one-of-one on the chain you are currently on and prevents anyone else from finalizing the same artwork. Once you finalize, you may never kustomize or traverse chains again.
</div>
</div>

<div className="contentCopyOrangee">“How do I traverse chains?”</div>
<div className="contentCopyRedd">
<div>
(It’s still very early for multi-chain NFTs – great UI/UX hasn't been created yet.)
</div>
<div>
This tutorial from our gh0stly frens: https://twitter.com/gh0stlygh0sts/status/1510870944549642241?s=20&t=in3YYH8FbjN2LEaxpI09VA
</div>
<div>
Traverse functions on TofuNFT will be available within the next few days.
</div>
</div>


<div className="contentCopyOrangee">“How do I kustomize my MKs (M64-69)?”</div>
<div className="contentCopyRedd">
<div>
Full kustomizer UI isn't available yet, but we threw together a temporary frontend you can use here: METAKAYS.com/kustommenu</div>
<div>
Better UI/UX will be deployed. We also encourage the community to build their own frontends – all of the data is entirely on-chain. (Join our Discord, and let's cook some stuff up together.)
</div>
</div>





<div className="contentCopyOrangee">“What are METAKAYS in METAVERSE-8888?”</div>
<div className="contentCopyRedd">
<div>
METAKAYS are 8888 unique NFT characters on the Ethereum blockchain.
METAKAYS are fully on-chain and fully kustomizable, as many times as you want, forever.
METAKAYS will have a tattoo of a unique # on their left shoulder. Other than that, each METAKAYS DNA and uniqueness is 100% up to the owner.
Personalize your background, hair, eyes, face, and body, and choose any combination of 1-8 letters (capitalized) on your shirt.
Over 88 billion unique combinations you can create with just one METAKAYS.
You may kustomize your METAKAYS for free (pay for gas) whenever you want, infinite times.
You may kustomize directly with the contract or any frontend that anyone can build on top of — it is 100% on-chain and decentralized.
In addition, there's a one-time, irreversible function named FINALITY that any owner can call that will make their METAKAYS permanent (no longer kustomizable). FINALITY does NOT prevent someone else from using the same DNA – although uniqueness from ID #, date, provenance, etc., are all verifiable on-chain.
METAKAYS in METAVERSE-8888 are NOT traversable.
</div>
</div>


<div className="contentCopyOrangee">Do we have rights to our METAKAYS?</div>
<div className="contentCopyRedd">
<div>METAKAYS are released as CC0 public domain! You may use METAKAYS non-commercially AND commercially!
We EXPECT and ENCOURAGE the community to create derivatives. We are ALL artists + builders and must ALL contribute to the lore of the Multimetaverse. We'll work on the canon timeline, and each of you is encouraged to create your own METAVERSE-???
</div>
</div>
</div>

  )
}
