import React, {useEffect, useState} from "react";
import "./CustomPOLYGON.css";
import parse from 'html-react-parser';
import CustomIndividualPOLYGON from "./CustomIndividualPOLYGON";
import { useAppContext } from "../../libs/contextLib";
import web3 from '../../web3';
import metakays from '../../metakays';
import { Link } from 'react-router-dom'




export default function CustomPOLYGON() {

  const {bgColor, four_colors, color1, color2, peices, showKustomModal, setKustomModal, setCustomChooser,  setMyMETAKAYS, setUserAccount} = useAppContext();

  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");
  const [msg3, setMsg3] = useState("");
  const [msg4, setMsg4] = useState("");
  const [msg5, setMsg5] = useState("");
  const [msg6, setMsg6] = useState("");



  useEffect(() => {
    const getMETAKAYS = async () => {

      try {

      const accounts = await web3.eth.getAccounts();
      setUserAccount(accounts[0])
      let supplyOfOwner = await metakays.methods.balanceOf(accounts[0]).call()
      for (let i = 0; i<supplyOfOwner; i++) {
        let tempID = await metakays.methods.tokenOfOwnerByIndex(accounts[0], i).call()
        let tempStruct = await metakays.methods.features(tempID).call()
        // let tokenURItest = await metakays.methods.tokenURI(2).call()


        setMyMETAKAYS(myMETAKAYS => ({...myMETAKAYS,
      [tempID]: Object.values(tempStruct).slice(0,13)}))

        }

      } catch (error) {
        console.log(error)

      }
      }

    getMETAKAYS();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var svgData = {}
  svgData[0] = '<circle r="25" cx="25" cy="25';
  svgData[1] = '<circle r="25" cx="75" cy="25';
  svgData[2] = '<circle r="25" cx="125" cy="25';
  svgData[3] = '<circle r="25" cx="175" cy="25';
  svgData[4] = '<circle r="25" cx="225" cy="25';
  svgData[5] = '<circle r="25" cx="275" cy="25';
  svgData[6] = '<circle r="25" cx="325" cy="25';
  svgData[7] = '<circle r="25" cx="375" cy="25';
  svgData[8] = '<circle r="25" cx="425" cy="25';
  svgData[9] = '<circle r="25" cx="475" cy="25';
  svgData[10] = '<circle r="25" cx="525" cy="25';
  svgData[11] = '<circle r="25" cx="575" cy="25';
  svgData[12] = '<circle r="25" cx="625" cy="25';
  svgData[13] = '<circle r="25" cx="675" cy="25';
  svgData[14] = '<circle r="25" cx="725" cy="25';
  svgData[15] = '<circle r="25" cx="775" cy="25';
  svgData[16] = '<circle r="25" cx="25" cy="75';
  svgData[17] = '<circle r="25" cx="75" cy="75';
  svgData[18] = '<circle r="25" cx="125" cy="75';
  svgData[19] = '<circle r="25" cx="175" cy="75';
  svgData[20] = '<circle r="25" cx="225" cy="75';
  svgData[21] = '<circle r="25" cx="275" cy="75';
  svgData[22] = '<circle r="25" cx="325" cy="75';
  svgData[23] = '<circle r="25" cx="375" cy="75';
  svgData[24] = '<circle r="25" cx="425" cy="75';
  svgData[25] = '<circle r="25" cx="475" cy="75';
  svgData[26] = '<circle r="25" cx="525" cy="75';
  svgData[27] = '<circle r="25" cx="575" cy="75';
  svgData[28] = '<circle r="25" cx="625" cy="75';
  svgData[29] = '<circle r="25" cx="675" cy="75';
  svgData[30] = '<circle r="25" cx="725" cy="75';
  svgData[31] = '<circle r="25" cx="775" cy="75';
  svgData[32] = '<circle r="25" cx="25" cy="125';
  svgData[33] = '<circle r="25" cx="75" cy="125';
  svgData[34] = '<circle r="25" cx="125" cy="125';
  svgData[35] = '<circle r="25" cx="175" cy="125';
  svgData[36] = '<circle r="25" cx="225" cy="125';
  svgData[37] = '<circle r="25" cx="275" cy="125';
  svgData[38] = '<circle r="25" cx="325" cy="125';
  svgData[39] = '<circle r="25" cx="375" cy="125';
  svgData[40] = '<circle r="25" cx="425" cy="125';
  svgData[41] = '<circle r="25" cx="475" cy="125';
  svgData[42] = '<circle r="25" cx="525" cy="125';
  svgData[43] = '<circle r="25" cx="575" cy="125';
  svgData[44] = '<circle r="25" cx="625" cy="125';
  svgData[45] = '<circle r="25" cx="675" cy="125';
  svgData[46] = '<circle r="25" cx="725" cy="125';
  svgData[47] = '<circle r="25" cx="775" cy="125';
  svgData[48] = '<circle r="25" cx="25" cy="175';
  svgData[49] = '<circle r="25" cx="75" cy="175';
  svgData[50] = '<circle r="25" cx="125" cy="175';
  svgData[51] = '<circle r="25" cx="175" cy="175';
  svgData[52] = '<circle r="25" cx="225" cy="175';
  svgData[53] = '<circle r="25" cx="275" cy="175';
  svgData[54] = '<circle r="25" cx="325" cy="175';
  svgData[55] = '<circle r="25" cx="375" cy="175';
  svgData[56] = '<circle r="25" cx="425" cy="175';
  svgData[57] = '<circle r="25" cx="475" cy="175';
  svgData[58] = '<circle r="25" cx="525" cy="175';
  svgData[59] = '<circle r="25" cx="575" cy="175';
  svgData[60] = '<circle r="25" cx="625" cy="175';
  svgData[61] = '<circle r="25" cx="675" cy="175';
  svgData[62] = '<circle r="25" cx="725" cy="175';
  svgData[63] = '<circle r="25" cx="775" cy="175';
  svgData[64] = '<circle r="25" cx="25" cy="225';
  svgData[65] = '<circle r="25" cx="75" cy="225';
  svgData[66] = '<circle r="25" cx="125" cy="225';
  svgData[67] = '<circle r="25" cx="175" cy="225';
  svgData[68] = '<circle r="25" cx="225" cy="225';
  svgData[69] = '<circle r="25" cx="275" cy="225';
  svgData[70] = '<circle r="25" cx="325" cy="225';
  svgData[71] = '<circle r="25" cx="375" cy="225';
  svgData[72] = '<circle r="25" cx="425" cy="225';
  svgData[73] = '<circle r="25" cx="475" cy="225';
  svgData[74] = '<circle r="25" cx="525" cy="225';
  svgData[75] = '<circle r="25" cx="575" cy="225';
  svgData[76] = '<circle r="25" cx="625" cy="225';
  svgData[77] = '<circle r="25" cx="675" cy="225';
  svgData[78] = '<circle r="25" cx="725" cy="225';
  svgData[79] = '<circle r="25" cx="775" cy="225';
  svgData[80] = '<circle r="25" cx="25" cy="275';
  svgData[81] = '<circle r="25" cx="75" cy="275';
  svgData[82] = '<circle r="25" cx="125" cy="275';
  svgData[83] = '<circle r="25" cx="175" cy="275';
  svgData[84] = '<circle r="25" cx="225" cy="275';
  svgData[85] = '<circle r="25" cx="275" cy="275';
  svgData[86] = '<circle r="25" cx="325" cy="275';
  svgData[87] = '<circle r="25" cx="375" cy="275';
  svgData[88] = '<circle r="25" cx="425" cy="275';
  svgData[89] = '<circle r="25" cx="475" cy="275';
  svgData[90] = '<circle r="25" cx="525" cy="275';
  svgData[91] = '<circle r="25" cx="575" cy="275';
  svgData[92] = '<circle r="25" cx="625" cy="275';
  svgData[93] = '<circle r="25" cx="675" cy="275';
  svgData[94] = '<circle r="25" cx="725" cy="275';
  svgData[95] = '<circle r="25" cx="775" cy="275';
  svgData[96] = '<circle r="25" cx="25" cy="325';
  svgData[97] = '<circle r="25" cx="75" cy="325';
  svgData[98] = '<circle r="25" cx="125" cy="325';
  svgData[99] = '<circle r="25" cx="175" cy="325';
  svgData[100] = '<circle r="25" cx="225" cy="325';
  svgData[101] = '<circle r="25" cx="275" cy="325';
  svgData[102] = '<circle r="25" cx="325" cy="325';
  svgData[103] = '<circle r="25" cx="375" cy="325';
  svgData[104] = '<circle r="25" cx="425" cy="325';
  svgData[105] = '<circle r="25" cx="475" cy="325';
  svgData[106] = '<circle r="25" cx="525" cy="325';
  svgData[107] = '<circle r="25" cx="575" cy="325';
  svgData[108] = '<circle r="25" cx="625" cy="325';
  svgData[109] = '<circle r="25" cx="675" cy="325';
  svgData[110] = '<circle r="25" cx="725" cy="325';
  svgData[111] = '<circle r="25" cx="775" cy="325';
  svgData[112] = '<circle r="25" cx="25" cy="375';
  svgData[113] = '<circle r="25" cx="75" cy="375';
  svgData[114] = '<circle r="25" cx="125" cy="375';
  svgData[115] = '<circle r="25" cx="175" cy="375';
  svgData[116] = '<circle r="25" cx="225" cy="375';
  svgData[117] = '<circle r="25" cx="275" cy="375';
  svgData[118] = '<circle r="25" cx="325" cy="375';
  svgData[119] = '<circle r="25" cx="375" cy="375';
  svgData[120] = '<circle r="25" cx="425" cy="375';
  svgData[121] = '<circle r="25" cx="475" cy="375';
  svgData[122] = '<circle r="25" cx="525" cy="375';
  svgData[123] = '<circle r="25" cx="575" cy="375';
  svgData[124] = '<circle r="25" cx="625" cy="375';
  svgData[125] = '<circle r="25" cx="675" cy="375';
  svgData[126] = '<circle r="25" cx="725" cy="375';
  svgData[127] = '<circle r="25" cx="775" cy="375';
  svgData[128] = '<circle r="25" cx="25" cy="425';
  svgData[129] = '<circle r="25" cx="75" cy="425';
  svgData[130] = '<circle r="25" cx="125" cy="425';
  svgData[131] = '<circle r="25" cx="175" cy="425';
  svgData[132] = '<circle r="25" cx="225" cy="425';
  svgData[133] = '<circle r="25" cx="275" cy="425';
  svgData[134] = '<circle r="25" cx="325" cy="425';
  svgData[135] = '<circle r="25" cx="375" cy="425';
  svgData[136] = '<circle r="25" cx="425" cy="425';
  svgData[137] = '<circle r="25" cx="475" cy="425';
  svgData[138] = '<circle r="25" cx="525" cy="425';
  svgData[139] = '<circle r="25" cx="575" cy="425';
  svgData[140] = '<circle r="25" cx="625" cy="425';
  svgData[141] = '<circle r="25" cx="675" cy="425';
  svgData[142] = '<circle r="25" cx="725" cy="425';
  svgData[143] = '<circle r="25" cx="775" cy="425';
  svgData[144] = '<circle r="25" cx="25" cy="475';
  svgData[145] = '<circle r="25" cx="75" cy="475';
  svgData[146] = '<circle r="25" cx="125" cy="475';
  svgData[147] = '<circle r="25" cx="175" cy="475';
  svgData[148] = '<circle r="25" cx="225" cy="475';
  svgData[149] = '<circle r="25" cx="275" cy="475';
  svgData[150] = '<circle r="25" cx="325" cy="475';
  svgData[151] = '<circle r="25" cx="375" cy="475';
  svgData[152] = '<circle r="25" cx="425" cy="475';
  svgData[153] = '<circle r="25" cx="475" cy="475';
  svgData[154] = '<circle r="25" cx="525" cy="475';
  svgData[155] = '<circle r="25" cx="575" cy="475';
  svgData[156] = '<circle r="25" cx="625" cy="475';
  svgData[157] = '<circle r="25" cx="675" cy="475';
  svgData[158] = '<circle r="25" cx="725" cy="475';
  svgData[159] = '<circle r="25" cx="775" cy="475';
  svgData[160] = '<circle r="25" cx="25" cy="525';
  svgData[161] = '<circle r="25" cx="75" cy="525';
  svgData[162] = '<circle r="25" cx="125" cy="525';
  svgData[163] = '<circle r="25" cx="175" cy="525';
  svgData[164] = '<circle r="25" cx="225" cy="525';
  svgData[165] = '<circle r="25" cx="275" cy="525';
  svgData[166] = '<circle r="25" cx="325" cy="525';
  svgData[167] = '<circle r="25" cx="375" cy="525';
  svgData[168] = '<circle r="25" cx="425" cy="525';
  svgData[169] = '<circle r="25" cx="475" cy="525';
  svgData[170] = '<circle r="25" cx="525" cy="525';
  svgData[171] = '<circle r="25" cx="575" cy="525';
  svgData[172] = '<circle r="25" cx="625" cy="525';
  svgData[173] = '<circle r="25" cx="675" cy="525';
  svgData[174] = '<circle r="25" cx="725" cy="525';
  svgData[175] = '<circle r="25" cx="775" cy="525';
  svgData[176] = '<circle r="25" cx="25" cy="575';
  svgData[177] = '<circle r="25" cx="75" cy="575';
  svgData[178] = '<circle r="25" cx="125" cy="575';
  svgData[179] = '<circle r="25" cx="175" cy="575';
  svgData[180] = '<circle r="25" cx="225" cy="575';
  svgData[181] = '<circle r="25" cx="275" cy="575';
  svgData[182] = '<circle r="25" cx="325" cy="575';
  svgData[183] = '<circle r="25" cx="375" cy="575';
  svgData[184] = '<circle r="25" cx="425" cy="575';
  svgData[185] = '<circle r="25" cx="475" cy="575';
  svgData[186] = '<circle r="25" cx="525" cy="575';
  svgData[187] = '<circle r="25" cx="575" cy="575';
  svgData[188] = '<circle r="25" cx="625" cy="575';
  svgData[189] = '<circle r="25" cx="675" cy="575';
  svgData[190] = '<circle r="25" cx="725" cy="575';
  svgData[191] = '<circle r="25" cx="775" cy="575';
  svgData[192] = '<circle r="25" cx="25" cy="625';
  svgData[193] = '<circle r="25" cx="75" cy="625';
  svgData[194] = '<circle r="25" cx="125" cy="625';
  svgData[195] = '<circle r="25" cx="175" cy="625';
  svgData[196] = '<circle r="25" cx="225" cy="625';
  svgData[197] = '<circle r="25" cx="275" cy="625';
  svgData[198] = '<circle r="25" cx="325" cy="625';
  svgData[199] = '<circle r="25" cx="375" cy="625';
  svgData[200] = '<circle r="25" cx="425" cy="625';
  svgData[201] = '<circle r="25" cx="475" cy="625';
  svgData[202] = '<circle r="25" cx="525" cy="625';
  svgData[203] = '<circle r="25" cx="575" cy="625';
  svgData[204] = '<circle r="25" cx="625" cy="625';
  svgData[205] = '<circle r="25" cx="675" cy="625';
  svgData[206] = '<circle r="25" cx="725" cy="625';
  svgData[207] = '<circle r="25" cx="775" cy="625';
  svgData[208] = '<circle r="25" cx="25" cy="675';
  svgData[209] = '<circle r="25" cx="75" cy="675';
  svgData[210] = '<circle r="25" cx="125" cy="675';
  svgData[211] = '<circle r="25" cx="175" cy="675';
  svgData[212] = '<circle r="25" cx="225" cy="675';
  svgData[213] = '<circle r="25" cx="275" cy="675';
  svgData[214] = '<circle r="25" cx="325" cy="675';
  svgData[215] = '<circle r="25" cx="375" cy="675';
  svgData[216] = '<circle r="25" cx="425" cy="675';
  svgData[217] = '<circle r="25" cx="475" cy="675';
  svgData[218] = '<circle r="25" cx="525" cy="675';
  svgData[219] = '<circle r="25" cx="575" cy="675';
  svgData[220] = '<circle r="25" cx="625" cy="675';
  svgData[221] = '<circle r="25" cx="675" cy="675';
  svgData[222] = '<circle r="25" cx="725" cy="675';
  svgData[223] = '<circle r="25" cx="775" cy="675';
  svgData[224] = '<circle r="25" cx="25" cy="725';
  svgData[225] = '<circle r="25" cx="75" cy="725';
  svgData[226] = '<circle r="25" cx="125" cy="725';
  svgData[227] = '<circle r="25" cx="175" cy="725';
  svgData[228] = '<circle r="25" cx="225" cy="725';
  svgData[229] = '<circle r="25" cx="275" cy="725';
  svgData[230] = '<circle r="25" cx="325" cy="725';
  svgData[231] = '<circle r="25" cx="375" cy="725';
  svgData[232] = '<circle r="25" cx="425" cy="725';
  svgData[233] = '<circle r="25" cx="475" cy="725';
  svgData[234] = '<circle r="25" cx="525" cy="725';
  svgData[235] = '<circle r="25" cx="575" cy="725';
  svgData[236] = '<circle r="25" cx="625" cy="725';
  svgData[237] = '<circle r="25" cx="675" cy="725';
  svgData[238] = '<circle r="25" cx="725" cy="725';
  svgData[239] = '<circle r="25" cx="775" cy="725';
  svgData[240] = '<circle r="25" cx="25" cy="775';
  svgData[241] = '<circle r="25" cx="75" cy="775';
  svgData[242] = '<circle r="25" cx="125" cy="775';
  svgData[243] = '<circle r="25" cx="175" cy="775';
  svgData[244] = '<circle r="25" cx="225" cy="775';
  svgData[245] = '<circle r="25" cx="275" cy="775';
  svgData[246] = '<circle r="25" cx="325" cy="775';
  svgData[247] = '<circle r="25" cx="375" cy="775';
  svgData[248] = '<circle r="25" cx="425" cy="775';
  svgData[249] = '<circle r="25" cx="475" cy="775';
  svgData[250] = '<circle r="25" cx="525" cy="775';
  svgData[251] = '<circle r="25" cx="575" cy="775';
  svgData[252] = '<circle r="25" cx="625" cy="775';
  svgData[253] = '<circle r="25" cx="675" cy="775';
  svgData[254] = '<circle r="25" cx="725" cy="775';
  svgData[255] = '<circle r="25" cx="775" cy="775';
  svgData[256] = '<circle r="25" cx="25" cy="825';
  svgData[257] = '<circle r="25" cx="75" cy="825';
  svgData[258] = '<circle r="25" cx="125" cy="825';
  svgData[259] = '<circle r="25" cx="175" cy="825';
  svgData[260] = '<circle r="25" cx="225" cy="825';
  svgData[261] = '<circle r="25" cx="275" cy="825';
  svgData[262] = '<circle r="25" cx="325" cy="825';
  svgData[263] = '<circle r="25" cx="375" cy="825';
  svgData[264] = '<circle r="25" cx="425" cy="825';
  svgData[265] = '<circle r="25" cx="475" cy="825';
  svgData[266] = '<circle r="25" cx="525" cy="825';
  svgData[267] = '<circle r="25" cx="575" cy="825';
  svgData[268] = '<circle r="25" cx="625" cy="825';
  svgData[269] = '<circle r="25" cx="675" cy="825';
  svgData[270] = '<circle r="25" cx="725" cy="825';
  svgData[271] = '<circle r="25" cx="775" cy="825';
  svgData[272] = '<circle r="25" cx="25" cy="875';
  svgData[273] = '<circle r="25" cx="75" cy="875';
  svgData[274] = '<circle r="25" cx="125" cy="875';
  svgData[275] = '<circle r="25" cx="175" cy="875';
  svgData[276] = '<circle r="25" cx="225" cy="875';
  svgData[277] = '<circle r="25" cx="275" cy="875';
  svgData[278] = '<circle r="25" cx="325" cy="875';
  svgData[279] = '<circle r="25" cx="375" cy="875';
  svgData[280] = '<circle r="25" cx="425" cy="875';
  svgData[281] = '<circle r="25" cx="475" cy="875';
  svgData[282] = '<circle r="25" cx="525" cy="875';
  svgData[283] = '<circle r="25" cx="575" cy="875';
  svgData[284] = '<circle r="25" cx="625" cy="875';
  svgData[285] = '<circle r="25" cx="675" cy="875';
  svgData[286] = '<circle r="25" cx="725" cy="875';
  svgData[287] = '<circle r="25" cx="775" cy="875';
  svgData[288] = '<circle r="25" cx="25" cy="925';
  svgData[289] = '<circle r="25" cx="75" cy="925';
  svgData[290] = '<circle r="25" cx="125" cy="925';
  svgData[291] = '<circle r="25" cx="175" cy="925';
  svgData[292] = '<circle r="25" cx="225" cy="925';
  svgData[293] = '<circle r="25" cx="275" cy="925';
  svgData[294] = '<circle r="25" cx="325" cy="925';
  svgData[295] = '<circle r="25" cx="375" cy="925';
  svgData[296] = '<circle r="25" cx="425" cy="925';
  svgData[297] = '<circle r="25" cx="475" cy="925';
  svgData[298] = '<circle r="25" cx="525" cy="925';
  svgData[299] = '<circle r="25" cx="575" cy="925';
  svgData[300] = '<circle r="25" cx="625" cy="925';
  svgData[301] = '<circle r="25" cx="675" cy="925';
  svgData[302] = '<circle r="25" cx="725" cy="925';
  svgData[303] = '<circle r="25" cx="775" cy="925';
  svgData[304] = '<circle r="25" cx="25" cy="975';
  svgData[305] = '<circle r="25" cx="75" cy="975';
  svgData[306] = '<circle r="25" cx="125" cy="975';
  svgData[307] = '<circle r="25" cx="175" cy="975';
  svgData[308] = '<circle r="25" cx="225" cy="975';
  svgData[309] = '<circle r="25" cx="275" cy="975';
  svgData[310] = '<circle r="25" cx="325" cy="975';
  svgData[311] = '<circle r="25" cx="375" cy="975';
  svgData[312] = '<circle r="25" cx="425" cy="975';
  svgData[313] = '<circle r="25" cx="475" cy="975';
  svgData[314] = '<circle r="25" cx="525" cy="975';
  svgData[315] = '<circle r="25" cx="575" cy="975';
  svgData[316] = '<circle r="25" cx="625" cy="975';
  svgData[317] = '<circle r="25" cx="675" cy="975';
  svgData[318] = '<circle r="25" cx="725" cy="975';
  svgData[319] = '<circle r="25" cx="775" cy="975';
  svgData[320] = '<circle r="25" cx="25" cy="1025';
  svgData[321] = '<circle r="25" cx="75" cy="1025';
  svgData[322] = '<circle r="25" cx="125" cy="1025';
  svgData[323] = '<circle r="25" cx="175" cy="1025';
  svgData[324] = '<circle r="25" cx="225" cy="1025';
  svgData[325] = '<circle r="25" cx="275" cy="1025';
  svgData[326] = '<circle r="25" cx="325" cy="1025';
  svgData[327] = '<circle r="25" cx="375" cy="1025';
  svgData[328] = '<circle r="25" cx="425" cy="1025';
  svgData[329] = '<circle r="25" cx="475" cy="1025';
  svgData[330] = '<circle r="25" cx="525" cy="1025';
  svgData[331] = '<circle r="25" cx="575" cy="1025';
  svgData[332] = '<circle r="25" cx="625" cy="1025';
  svgData[333] = '<circle r="25" cx="675" cy="1025';
  svgData[334] = '<circle r="25" cx="725" cy="1025';
  svgData[335] = '<circle r="25" cx="775" cy="1025';
  svgData[336] = '<circle r="25" cx="25" cy="1075';
  svgData[337] = '<circle r="25" cx="75" cy="1075';
  svgData[338] = '<circle r="25" cx="125" cy="1075';
  svgData[339] = '<circle r="25" cx="175" cy="1075';
  svgData[340] = '<circle r="25" cx="225" cy="1075';
  svgData[341] = '<circle r="25" cx="275" cy="1075';
  svgData[342] = '<circle r="25" cx="325" cy="1075';
  svgData[343] = '<circle r="25" cx="375" cy="1075';
  svgData[344] = '<circle r="25" cx="425" cy="1075';
  svgData[345] = '<circle r="25" cx="475" cy="1075';
  svgData[346] = '<circle r="25" cx="525" cy="1075';
  svgData[347] = '<circle r="25" cx="575" cy="1075';
  svgData[348] = '<circle r="25" cx="625" cy="1075';
  svgData[349] = '<circle r="25" cx="675" cy="1075';
  svgData[350] = '<circle r="25" cx="725" cy="1075';
  svgData[351] = '<circle r="25" cx="775" cy="1075';
  svgData[352] = '<circle r="25" cx="25" cy="1125';
  svgData[353] = '<circle r="25" cx="75" cy="1125';
  svgData[354] = '<circle r="25" cx="125" cy="1125';
  svgData[355] = '<circle r="25" cx="175" cy="1125';
  svgData[356] = '<circle r="25" cx="225" cy="1125';
  svgData[357] = '<circle r="25" cx="275" cy="1125';
  svgData[358] = '<circle r="25" cx="325" cy="1125';
  svgData[359] = '<circle r="25" cx="375" cy="1125';
  svgData[360] = '<circle r="25" cx="425" cy="1125';
  svgData[361] = '<circle r="25" cx="475" cy="1125';
  svgData[362] = '<circle r="25" cx="525" cy="1125';
  svgData[363] = '<circle r="25" cx="575" cy="1125';
  svgData[364] = '<circle r="25" cx="625" cy="1125';
  svgData[365] = '<circle r="25" cx="675" cy="1125';
  svgData[366] = '<circle r="25" cx="725" cy="1125';
  svgData[367] = '<circle r="25" cx="775" cy="1125';
  svgData[368] = '<circle r="25" cx="25" cy="1175';
  svgData[369] = '<circle r="25" cx="75" cy="1175';
  svgData[370] = '<circle r="25" cx="125" cy="1175';
  svgData[371] = '<circle r="25" cx="175" cy="1175';
  svgData[372] = '<circle r="25" cx="225" cy="1175';
  svgData[373] = '<circle r="25" cx="275" cy="1175';
  svgData[374] = '<circle r="25" cx="325" cy="1175';
  svgData[375] = '<circle r="25" cx="375" cy="1175';
  svgData[376] = '<circle r="25" cx="425" cy="1175';
  svgData[377] = '<circle r="25" cx="475" cy="1175';
  svgData[378] = '<circle r="25" cx="525" cy="1175';
  svgData[379] = '<circle r="25" cx="575" cy="1175';
  svgData[380] = '<circle r="25" cx="625" cy="1175';
  svgData[381] = '<circle r="25" cx="675" cy="1175';
  svgData[382] = '<circle r="25" cx="725" cy="1175';
  svgData[383] = '<circle r="25" cx="775" cy="1175';
  svgData[384] = '<circle r="25" cx="25" cy="1225';
  svgData[385] = '<circle r="25" cx="75" cy="1225';
  svgData[386] = '<circle r="25" cx="125" cy="1225';
  svgData[387] = '<circle r="25" cx="175" cy="1225';
  svgData[388] = '<circle r="25" cx="225" cy="1225';
  svgData[389] = '<circle r="25" cx="275" cy="1225';
  svgData[390] = '<circle r="25" cx="325" cy="1225';
  svgData[391] = '<circle r="25" cx="375" cy="1225';
  svgData[392] = '<circle r="25" cx="425" cy="1225';
  svgData[393] = '<circle r="25" cx="475" cy="1225';
  svgData[394] = '<circle r="25" cx="525" cy="1225';
  svgData[395] = '<circle r="25" cx="575" cy="1225';
  svgData[396] = '<circle r="25" cx="625" cy="1225';
  svgData[397] = '<circle r="25" cx="675" cy="1225';
  svgData[398] = '<circle r="25" cx="725" cy="1225';
  svgData[399] = '<circle r="25" cx="775" cy="1225';
  svgData[400] = '<circle r="25" cx="25" cy="1275';
  svgData[401] = '<circle r="25" cx="75" cy="1275';
  svgData[402] = '<circle r="25" cx="125" cy="1275';
  svgData[403] = '<circle r="25" cx="175" cy="1275';
  svgData[404] = '<circle r="25" cx="225" cy="1275';
  svgData[405] = '<circle r="25" cx="275" cy="1275';
  svgData[406] = '<circle r="25" cx="325" cy="1275';
  svgData[407] = '<circle r="25" cx="375" cy="1275';
  svgData[408] = '<circle r="25" cx="425" cy="1275';
  svgData[409] = '<circle r="25" cx="475" cy="1275';
  svgData[410] = '<circle r="25" cx="525" cy="1275';
  svgData[411] = '<circle r="25" cx="575" cy="1275';
  svgData[412] = '<circle r="25" cx="625" cy="1275';
  svgData[413] = '<circle r="25" cx="675" cy="1275';
  svgData[414] = '<circle r="25" cx="725" cy="1275';
  svgData[415] = '<circle r="25" cx="775" cy="1275';
  svgData[416] = '<circle r="25" cx="25" cy="1325';
  svgData[417] = '<circle r="25" cx="75" cy="1325';
  svgData[418] = '<circle r="25" cx="125" cy="1325';
  svgData[419] = '<circle r="25" cx="175" cy="1325';
  svgData[420] = '<circle r="25" cx="225" cy="1325';
  svgData[421] = '<circle r="25" cx="275" cy="1325';
  svgData[422] = '<circle r="25" cx="325" cy="1325';
  svgData[423] = '<circle r="25" cx="375" cy="1325';
  svgData[424] = '<circle r="25" cx="425" cy="1325';
  svgData[425] = '<circle r="25" cx="475" cy="1325';
  svgData[426] = '<circle r="25" cx="525" cy="1325';
  svgData[427] = '<circle r="25" cx="575" cy="1325';
  svgData[428] = '<circle r="25" cx="625" cy="1325';
  svgData[429] = '<circle r="25" cx="675" cy="1325';
  svgData[430] = '<circle r="25" cx="725" cy="1325';
  svgData[431] = '<circle r="25" cx="775" cy="1325';
  svgData[432] = '<circle r="25" cx="25" cy="1375';
  svgData[433] = '<circle r="25" cx="75" cy="1375';
  svgData[434] = '<circle r="25" cx="125" cy="1375';
  svgData[435] = '<circle r="25" cx="175" cy="1375';
  svgData[436] = '<circle r="25" cx="225" cy="1375';
  svgData[437] = '<circle r="25" cx="275" cy="1375';
  svgData[438] = '<circle r="25" cx="325" cy="1375';
  svgData[439] = '<circle r="25" cx="375" cy="1375';
  svgData[440] = '<circle r="25" cx="425" cy="1375';
  svgData[441] = '<circle r="25" cx="475" cy="1375';
  svgData[442] = '<circle r="25" cx="525" cy="1375';
  svgData[443] = '<circle r="25" cx="575" cy="1375';
  svgData[444] = '<circle r="25" cx="625" cy="1375';
  svgData[445] = '<circle r="25" cx="675" cy="1375';
  svgData[446] = '<circle r="25" cx="725" cy="1375';
  svgData[447] = '<circle r="25" cx="775" cy="1375';
  svgData[448] = '<circle r="25" cx="25" cy="1425';
  svgData[449] = '<circle r="25" cx="75" cy="1425';
  svgData[450] = '<circle r="25" cx="125" cy="1425';
  svgData[451] = '<circle r="25" cx="175" cy="1425';
  svgData[452] = '<circle r="25" cx="225" cy="1425';
  svgData[453] = '<circle r="25" cx="275" cy="1425';
  svgData[454] = '<circle r="25" cx="325" cy="1425';
  svgData[455] = '<circle r="25" cx="375" cy="1425';
  svgData[456] = '<circle r="25" cx="425" cy="1425';
  svgData[457] = '<circle r="25" cx="475" cy="1425';
  svgData[458] = '<circle r="25" cx="525" cy="1425';
  svgData[459] = '<circle r="25" cx="575" cy="1425';
  svgData[460] = '<circle r="25" cx="625" cy="1425';
  svgData[461] = '<circle r="25" cx="675" cy="1425';
  svgData[462] = '<circle r="25" cx="725" cy="1425';
  svgData[463] = '<circle r="25" cx="775" cy="1425';
  svgData[464] = '<circle r="25" cx="25" cy="1475';
  svgData[465] = '<circle r="25" cx="75" cy="1475';
  svgData[466] = '<circle r="25" cx="125" cy="1475';
  svgData[467] = '<circle r="25" cx="175" cy="1475';
  svgData[468] = '<circle r="25" cx="225" cy="1475';
  svgData[469] = '<circle r="25" cx="275" cy="1475';
  svgData[470] = '<circle r="25" cx="325" cy="1475';
  svgData[471] = '<circle r="25" cx="375" cy="1475';
  svgData[472] = '<circle r="25" cx="425" cy="1475';
  svgData[473] = '<circle r="25" cx="475" cy="1475';
  svgData[474] = '<circle r="25" cx="525" cy="1475';
  svgData[475] = '<circle r="25" cx="575" cy="1475';
  svgData[476] = '<circle r="25" cx="625" cy="1475';
  svgData[477] = '<circle r="25" cx="675" cy="1475';
  svgData[478] = '<circle r="25" cx="725" cy="1475';
  svgData[479] = '<circle r="25" cx="775" cy="1475';
  svgData[480] = '<circle r="25" cx="25" cy="1525';
  svgData[481] = '<circle r="25" cx="75" cy="1525';
  svgData[482] = '<circle r="25" cx="125" cy="1525';
  svgData[483] = '<circle r="25" cx="175" cy="1525';
  svgData[484] = '<circle r="25" cx="225" cy="1525';
  svgData[485] = '<circle r="25" cx="275" cy="1525';
  svgData[486] = '<circle r="25" cx="325" cy="1525';
  svgData[487] = '<circle r="25" cx="375" cy="1525';
  svgData[488] = '<circle r="25" cx="425" cy="1525';
  svgData[489] = '<circle r="25" cx="475" cy="1525';
  svgData[490] = '<circle r="25" cx="525" cy="1525';
  svgData[491] = '<circle r="25" cx="575" cy="1525';
  svgData[492] = '<circle r="25" cx="625" cy="1525';
  svgData[493] = '<circle r="25" cx="675" cy="1525';
  svgData[494] = '<circle r="25" cx="725" cy="1525';
  svgData[495] = '<circle r="25" cx="775" cy="1525';
  svgData[496] = '<circle r="25" cx="25" cy="1575';
  svgData[497] = '<circle r="25" cx="75" cy="1575';
  svgData[498] = '<circle r="25" cx="125" cy="1575';
  svgData[499] = '<circle r="25" cx="175" cy="1575';
  svgData[500] = '<circle r="25" cx="225" cy="1575';
  svgData[501] = '<circle r="25" cx="275" cy="1575';
  svgData[502] = '<circle r="25" cx="325" cy="1575';
  svgData[503] = '<circle r="25" cx="375" cy="1575';
  svgData[504] = '<circle r="25" cx="425" cy="1575';
  svgData[505] = '<circle r="25" cx="475" cy="1575';
  svgData[506] = '<circle r="25" cx="525" cy="1575';
  svgData[507] = '<circle r="25" cx="575" cy="1575';
  svgData[508] = '<circle r="25" cx="625" cy="1575';
  svgData[509] = '<circle r="25" cx="675" cy="1575';
  svgData[510] = '<circle r="25" cx="725" cy="1575';
  svgData[511] = '<circle r="25" cx="775" cy="1575';






  var svgBackgroundColor = {};
  svgBackgroundColor[0] = '#800000"/>';
  svgBackgroundColor[1] = '#8B0000"/>';
  svgBackgroundColor[2] = '#A52A2A"/>';
  svgBackgroundColor[3] = '#B22222"/>';
  svgBackgroundColor[4] = '#DC143C"/>';
  svgBackgroundColor[5] = '#FF0000"/>';
  svgBackgroundColor[6] = '#FF6347"/>';
  svgBackgroundColor[7] = '#FF7F50"/>';
  svgBackgroundColor[8] = '#CD5C5C"/>';
  svgBackgroundColor[9] = '#F08080"/>';
  svgBackgroundColor[10] = '#E9967A"/>';
  svgBackgroundColor[11] = '#FA8072"/>';
  svgBackgroundColor[12] = '#FFA07A"/>';
  svgBackgroundColor[13] = '#FF4500"/>';
  svgBackgroundColor[14] = '#FF8C00"/>';
  svgBackgroundColor[15] = '#FFA500"/>';
  svgBackgroundColor[16] = '#FFD700"/>';
  svgBackgroundColor[17] = '#B8860B"/>';
  svgBackgroundColor[18] = '#DAA520"/>';
  svgBackgroundColor[19] = '#EEE8AA"/>';
  svgBackgroundColor[20] = '#BDB76B"/>';
  svgBackgroundColor[21] = '#F0E68C"/>';
  svgBackgroundColor[22] = '#808000"/>';
  svgBackgroundColor[23] = '#FFFF00"/>';
  svgBackgroundColor[24] = '#9ACD32"/>';
  svgBackgroundColor[25] = '#556B2F"/>';
  svgBackgroundColor[26] = '#6B8E23"/>';
  svgBackgroundColor[27] = '#7CFC00"/>';
  svgBackgroundColor[28] = '#7FFF00"/>';
  svgBackgroundColor[29] = '#ADFF2F"/>';
  svgBackgroundColor[30] = '#006400"/>';
  svgBackgroundColor[31] = '#008000"/>';
  svgBackgroundColor[32] = '#228B22"/>';
  svgBackgroundColor[33] = '#00FF00"/>';
  svgBackgroundColor[34] = '#32CD32"/>';
  svgBackgroundColor[35] = '#90EE90"/>';
  svgBackgroundColor[36] = '#98FB98"/>';
  svgBackgroundColor[37] = '#8FBC8F"/>';
  svgBackgroundColor[38] = '#00FA9A"/>';
  svgBackgroundColor[39] = '#00FF7F"/>';
  svgBackgroundColor[40] = '#2E8B57"/>';
  svgBackgroundColor[41] = '#66CDAA"/>';
  svgBackgroundColor[42] = '#3CB371"/>';
  svgBackgroundColor[43] = '#20B2AA"/>';
  svgBackgroundColor[44] = '#2F4F4F"/>';
  svgBackgroundColor[45] = '#008080"/>';
  svgBackgroundColor[46] = '#008B8B"/>';
  svgBackgroundColor[47] = '#00FFFF"/>';
  svgBackgroundColor[48] = '#00FFFF"/>';
  svgBackgroundColor[49] = '#E0FFFF"/>';
  svgBackgroundColor[50] = '#00CED1"/>';
  svgBackgroundColor[51] = '#40E0D0"/>';
  svgBackgroundColor[52] = '#48D1CC"/>';
  svgBackgroundColor[53] = '#AFEEEE"/>';
  svgBackgroundColor[54] = '#7FFFD4"/>';
  svgBackgroundColor[55] = '#B0E0E6"/>';
  svgBackgroundColor[56] = '#5F9EA0"/>';
  svgBackgroundColor[57] = '#4682B4"/>';
  svgBackgroundColor[58] = '#6495ED"/>';
  svgBackgroundColor[59] = '#00BFFF"/>';
  svgBackgroundColor[60] = '#1E90FF"/>';
  svgBackgroundColor[61] = '#ADD8E6"/>';
  svgBackgroundColor[62] = '#87CEEB"/>';
  svgBackgroundColor[63] = '#87CEFA"/>';
  svgBackgroundColor[64] = '#191970"/>';
  svgBackgroundColor[65] = '#000080"/>';
  svgBackgroundColor[66] = '#00008B"/>';
  svgBackgroundColor[67] = '#0000CD"/>';
  svgBackgroundColor[68] = '#0000FF"/>';
  svgBackgroundColor[69] = '#4169E1"/>';
  svgBackgroundColor[70] = '#8A2BE2"/>';
  svgBackgroundColor[71] = '#4B0082"/>';
  svgBackgroundColor[72] = '#483D8B"/>';
  svgBackgroundColor[73] = '#6A5ACD"/>';
  svgBackgroundColor[74] = '#7B68EE"/>';
  svgBackgroundColor[75] = '#9370DB"/>';
  svgBackgroundColor[76] = '#8B008B"/>';
  svgBackgroundColor[77] = '#9400D3"/>';
  svgBackgroundColor[78] = '#9932CC"/>';
  svgBackgroundColor[79] = '#BA55D3"/>';
  svgBackgroundColor[80] = '#800080"/>';
  svgBackgroundColor[81] = '#D8BFD8"/>';
  svgBackgroundColor[82] = '#DDA0DD"/>';
  svgBackgroundColor[83] = '#EE82EE"/>';
  svgBackgroundColor[84] = '#FF00FF"/>';
  svgBackgroundColor[85] = '#DA70D6"/>';
  svgBackgroundColor[86] = '#C71585"/>';
  svgBackgroundColor[87] = '#DB7093"/>';
  svgBackgroundColor[88] = '#FF1493"/>';
  svgBackgroundColor[89] = '#FF69B4"/>';
  svgBackgroundColor[90] = '#FFB6C1"/>';
  svgBackgroundColor[91] = '#FFC0CB"/>';
  svgBackgroundColor[92] = '#FAEBD7"/>';
  svgBackgroundColor[93] = '#F5F5DC"/>';
  svgBackgroundColor[94] = '#FFE4C4"/>';
  svgBackgroundColor[95] = '#FFEBCD"/>';
  svgBackgroundColor[96] = '#F5DEB3"/>';
  svgBackgroundColor[97] = '#FFF8DC"/>';
  svgBackgroundColor[98] = '#FFFACD"/>';
  svgBackgroundColor[99] = '#FAFAD2"/>';
  svgBackgroundColor[100] = '#FFFFE0"/>';
  svgBackgroundColor[101] = '#8B4513"/>';
  svgBackgroundColor[102] = '#A0522D"/>';
  svgBackgroundColor[103] = '#D2691E"/>';
  svgBackgroundColor[104] = '#CD853F"/>';
  svgBackgroundColor[105] = '#F4A460"/>';
  svgBackgroundColor[106] = '#DEB887"/>';
  svgBackgroundColor[107] = '#D2B48C"/>';
  svgBackgroundColor[108] = '#BC8F8F"/>';
  svgBackgroundColor[109] = '#FFE4B5"/>';
  svgBackgroundColor[110] = '#FFDEAD"/>';
  svgBackgroundColor[111] = '#FFDAB9"/>';
  svgBackgroundColor[112] = '#FFE4E1"/>';
  svgBackgroundColor[113] = '#FFF0F5"/>';
  svgBackgroundColor[114] = '#FAF0E6"/>';
  svgBackgroundColor[115] = '#FDF5E6"/>';
  svgBackgroundColor[116] = '#FFEFD5"/>';
  svgBackgroundColor[117] = '#FFF5EE"/>';
  svgBackgroundColor[118] = '#F5FFFA"/>';
  svgBackgroundColor[119] = '#708090"/>';
  svgBackgroundColor[120] = '#778899"/>';
  svgBackgroundColor[121] = '#B0C4DE"/>';
  svgBackgroundColor[122] = '#E6E6FA"/>';
  svgBackgroundColor[123] = '#FFFAF0"/>';
  svgBackgroundColor[124] = '#F0F8FF"/>';
  svgBackgroundColor[125] = '#F8F8FF"/>';
  svgBackgroundColor[126] = '#F0FFF0"/>';
  svgBackgroundColor[127] = '#FFFFF0"/>';
  svgBackgroundColor[128] = '#F0FFFF"/>';
  svgBackgroundColor[129] = '#FFFAFA"/>';
  svgBackgroundColor[130] = '#000000"/>';
  svgBackgroundColor[131] = '#696969"/>';
  svgBackgroundColor[132] = '#808080"/>';
  svgBackgroundColor[133] = '#A9A9A9"/>';
  svgBackgroundColor[134] = '#C0C0C0"/>';
  svgBackgroundColor[135] = '#D3D3D3"/>';
  svgBackgroundColor[136] = '#DCDCDC"/>';
  svgBackgroundColor[137] = '#FFFFFF"/>';


  let submitCustom = async (_tokenID) => {
    /* global BigInt */


    let tempPeices1 = peices.slice(0,256)
    let tempPeices2 = peices.slice(256, 512)

    let data1 = BigInt('0b'+tempPeices1.join("").toString())
    let data2 = BigInt('0b'+tempPeices2.join("").toString())

    let tempColorOne1 = color1.slice(0,256)
    let tempColorOne2 = color1.slice(256, 512)
    let colorOne1 = BigInt('0b'+tempColorOne1.join("").toString())
    let colorOne2 = BigInt('0b'+tempColorOne2.join("").toString())

    let tempColorTwo1 = color2.slice(0,256)
    let tempColorTwo2 = color2.slice(256, 512)

    let colorTwo1 = BigInt('0b'+tempColorTwo1.join("").toString())
    let colorTwo2 = BigInt('0b'+tempColorTwo2.join("").toString())

    // await metakays.methods.kustomize()
        // await metakays.methods.kustomize(data1, data2, [colorOne1, colorOne2, colorTwo1, colorTwo2], four_colors, tokenID).send({
        //   from: userAccount,

        //   })
        setMsg1("Head to https://polygonscan.com/address/0x151c384c9d8aece2dfb50e61890217128f90c321#writeContract and connect your POLYGON wallet. Scroll down to the *kustomize* function and input these values:")
        setMsg2("_data1: "+String(data1))
        setMsg3("_data2: "+String(data2))
        setMsg4("_colors: "+String(colorOne1)+','+String(colorOne2)+','+String(colorTwo1)+','+String(colorTwo2))
        setMsg5("_colorSelectors: "+String(four_colors[0])+','+String(four_colors[1])+','+String(four_colors[2])+','+String(four_colors[3]))
        setMsg6("_itemID: YOUR_TOKEN_ID")
        }


  const handleShow = async (_value) => {
    setCustomChooser(_value)
    if (showKustomModal) {
      setKustomModal(false)
    } else {
      setKustomModal(true)
    }
  };

  return (
        <div className="Custom">
        {/* backgroundColor */}
        {showKustomModal ?
          <div className="CustomSVGRightSide">
            <div className='over'><CustomIndividualPOLYGON/></div>
            <div className="CustomHeader">MENU</div>
            <Link to='/kustompolygonmenu' className="itemDropKustomETH">BACK</Link>
            <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, KUSTOMIZE ON POLYGON BY HEADING TO https://polygonscan.com/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER POLYGONSCAN AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: KUSTOMIZE ON HERE, THEN COPY AND PASTE THE DATA BELOW INTO THE "KUSTOMIZE" FUNCTION ON POLYGONSCAN AND SUBMIT.</div>
        <div className="instructions">YOU MAY CHOOSE 4 COLORS (DEFAULT COLOR, COLOR #2, COLOR #3, COLOR #4). </div>
        {/* <div className="itemDropKustomETH" onClick={() => handleShow(24)}>LOAD</div> */}
            <div className="itemDropKustomETH" onClick={() => handleShow(29)}>RESET (BLANK)</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT DEFAULT COLOR</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR #4</div>


            <div className="itemDropKustomETH" onClick={() => handleShow(2)}>DRAW WITH COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(1)}>DRAW WITH COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(0)}>DRAW WITH COLOR #4</div>

            <div className="itemDropKustomETH" onClick={() => submitCustom()}>SUBMIT</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>
            <div className="customMessage">{msg3}</div>
            <div className="customMessage">{msg4}</div>
            <div className="customMessage">{msg5}</div>
            <div className="customMessage">{msg6}</div>


          </div>
        :
        <div className="CustomSVGRightSide">
          <div className="CustomHeader">MENU</div>
          <Link to='/kustompolygonmenu' className="itemDropKustomETH">BACK</Link>
          <div className="instructions">BETTER UI/UX IS ON THE WAY. UNTIL THEN, KUSTOMIZE ON POLYGON BY HEADING TO https://polygonscan.com/address/0x151c384c9d8aece2dfb50e61890217128f90c321 AND INTERACT DIRECTLY WITH THE CONTRACT.</div>
          <div className="instructions">WE HAVE NO CONTROL OVER POLYGONSCAN AND ARE NOT LIABLE FOR ANY FAILED/INCORRECT TRANSACTIONS.</div>
        <div className="instructions">WE CREATED THIS FRONTEND TO MAKE IT EASIER: KUSTOMIZE ON HERE, THEN COPY AND PASTE THE DATA BELOW INTO THE "KUSTOMIZE" FUNCTION ON POLYGONSCAN AND SUBMIT.</div>
        <div className="instructions">YOU MAY CHOOSE 4 COLORS (DEFAULT COLOR, COLOR #2, COLOR #3, COLOR #4). </div>
        {/* <div className="itemDropKustomETH" onClick={() => handleShow(24)}>LOAD</div> */}
            <div className="itemDropKustomETH" onClick={() => handleShow(29)}>RESET (BLANK)</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(28)}>SELECT DEFAULT COLOR</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(27)}>SELECT COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(26)}>SELECT COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(25)}>SELECT COLOR #4</div>


            <div className="itemDropKustomETH" onClick={() => handleShow(2)}>DRAW WITH COLOR #2</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(1)}>DRAW WITH COLOR #3</div>
            <div className="itemDropKustomETH" onClick={() => handleShow(0)}>DRAW WITH COLOR #4</div>

            <div className="itemDropKustomETH" onClick={() => submitCustom()}>SUBMIT</div>
            <div className="customMessage">{msg1}</div>
            <div className="customMessage">{msg2}</div>
            <div className="customMessage">{msg3}</div>
            <div className="customMessage">{msg4}</div>
            <div className="customMessage">{msg5}</div>
            <div className="customMessage">{msg6}</div>



        </div>}
        <div className="CustomSVGRightSide">
            {/* background  */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="{w}pt" height="{h}pt" viewBox="0 0 800.000000 1600.000000" preserveAspectRatio="xMidYMid meet" xmlnsXlink="http://www.w3.org/1999/xlink">
            {parse('<g transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">')}
            {parse('<path d="M0 8000 l0 -8000 4000 0 4000 0 0 8000 0 8000 -4000 0 -4000 0 0 -8000z" fill="' + String(svgBackgroundColor[bgColor]) + '</g>')}



            {/* {parse('<use xlinkHref="#cube" x="487" y="540" fill="#000000"/>')} */}

            {/* {peices.split("").forEach(character => {if (character == "0") {return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')}})} */}

            {peices.map((value, index) => {

              let color;

              if (parseInt(value) === 1) {
                color = svgBackgroundColor[four_colors[0]]
              } else {
                if (parseInt(color1[index]) === 1){
                  color = svgBackgroundColor[four_colors[1]]
                } else {
                  if (parseInt(color2[index]) === 1){
                    color = svgBackgroundColor[four_colors[2]]
                  } else {
                    color = svgBackgroundColor[four_colors[3]]
                  }
                }
              }
                // let color = color1[index] == 1 ? svgBackgroundColor[three_colors[0]] : color2[index] == 1 ? svgBackgroundColor[three_colors[1]] : svgBackgroundColor[three_colors[2]]
                let temp = svgData[index] + '" fill="' + color;
                return parse(temp)
                // return parse('<use xlinkHref="#cube" x="480" y="240" fill="#000000"/>')

         })}

            {parse('<g transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)" fill="#F5F5F5" stroke="none"> <path d="M6720 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z M7440 890 l0 -110 -110 0 -110 0 0 -110 0 -110 110 0 110 0 0 -220 0 -220 110 0 110 0 0 220 0 220 110 0 110 0 0 110 0 110 -110 0 -110 0 0 110 0 110 -110 0 -110 0 0 -110z"/> </g></svg>')}
            </svg>
          </div>
      </div>
  );
}






